(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('ActionController', ActionController)

    ActionController.$inject = ['$scope', '$filter', 'Principal', 'LoginService', 'AlertService', 'Action', 'Form', '$state', 'ModalHelper','$q', '$timeout', '$http', '$localStorage', '$sessionStorage', 'forms']

    function ActionController ($scope, $filter, Principal, LoginService, AlertService, Action, Form, $state, ModalHelper, $q, $timeout, $http, $localStorage, $sessionStorage, forms) {
        var vm = this;
        vm.show = false;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.forms = forms;
        vm.form;
        vm.actionId;
        vm.actionName;
        vm.actionDescription;
        vm.filters = {};
        vm.executionTypes = [{label: "Manual", value: "manual"},{label: "Automático", value: "automatic"}];
        vm.types = [{label: "Crear", value: "create"},{label: "Actualizar", value: "update"},{label: "Eliminar", value: "delete"}];
        vm.allTriggers = [{label: "Al actualizar", value: "onUpdate", executionType: "automatic"},{label: "Al crear", value: "onCreate", executionType: "automatic"}, {label: "Al eliminar", value: "onDelete", executionType: "automatic"},{label: "A demanda", value: "onDemand", executionType: "manual"}];

        $scope.$on('authenticationSuccess', function() {
            getAccount();
            getForms();
        });

        vm.setFilters = function(){
          if(vm.form != undefined){
            vm.filters.formOriginId = vm.form.id;
          }else{
            vm.findError = true;
            return;
          }
          if(vm.actionId != null && vm.actionId != undefined){
            vm.filters.actionId = vm.actionId;
          }else{
            delete vm.filters.actionId;
          }
          if(vm.actionName != null && vm.actionName != undefined){
            vm.filters.name = vm.actionName;
          }else{
            delete vm.filters.name;
          }
          if(vm.actionDescription != null && vm.actionDescription != undefined){
            vm.filters.description = vm.actionDescription;
          }else{
            delete vm.filters.description;
          }
          if(vm.actionType != null & vm.actionType != undefined && vm.actionType.length > 0){
            vm.filters.type = vm.actionType.join(",");
          }else{
            delete vm.filters.type;
          }
          if(vm.actionExecutionType != null & vm.actionExecutionType != undefined && vm.actionExecutionType.length > 0){
            vm.filters.executionType = vm.actionExecutionType.join(",");
          }else{
            delete vm.filters.executionType;
          }
        }

        var isEmpty = function(obj) {
          for(var key in obj) {
              if(obj.hasOwnProperty(key))
                  return false;
          }
          return true;
        }
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register () {
            $state.go('register');
        }

        vm.getActions = function (){
          vm.actions = []
          vm.setFilters();
          var deferred = $q.defer();
          Action.query(vm.filters, function(response){
            vm.actions = response;
            deferred.resolve();
          })
          return deferred.promise
        }

        vm.getExecutionTypeName = function(executionType){
          switch(executionType){
            case "manual":
              return "Manual";
            case "automatic":
              return "Automático";
          }
        }

        vm.getTypeName = function(type){
          return $filter("filter")(vm.types, {value: type})[0].label;
        }

        vm.newAction = function(){
          $state.go('actions.new', {formOriginId: vm.form.id});
        }

        vm.editAction = function(action){
          $state.go('actions.edit', {formOriginId: vm.form.id, actionId: action.id});
        }

        vm.cloneAction = function(action){
          $state.go('actions.copy', {formOriginId: vm.form.id, actionId: action.id});
        }

        vm.deleteAction = function(action){
           var modalInstance = ModalHelper.confirmation("¿Está seguro de eliminar la acción?");

          modalInstance.result.then(function (confirmation) {
            if(confirmation){
              Action.delete({id: action.id}, {},function(response){
                vm.getActions();
              });
            }
          }, function () {
            
          });
        }

        vm.setEnabled = function(action, value) {
          Action.setEnabled({ id: action.id }, { enabled: value }, function(response) {
            action.enabled = value;
          });
        }
      
        getAccount();
        $('select').select2({
          width: "100%"
        });
    }
})();