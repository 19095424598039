(function() {
    'use strict';

    angular
        .module('pickerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dashboards', {
            parent: 'entity',
            url: '/dashboards',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/dashboard/dashboards.html',
                    controller: 'DashboardsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                dashboards: ['$q','Principal', 'Dashboard', '$rootScope',function($q, Principal, Dashboard, $rootScope){
                    var deferred = $q.defer();
                    $rootScope.showLoading("Buscando dashboards...")
                    Principal.identity().then(function(account) {
                        Dashboard.query({ organizationId: account.organizationId }, function(response) {
                            $rootScope.hideLoading();
                            deferred.resolve(response);
                        })
                    })
                    return deferred.promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    //$translatePartialLoader.addPart('picks');
                    return $translate.refresh();
                }]
            }
        }).state('dashboards.new', {
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']  
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/dashboard/dashboard-form.html',
                    controller: 'DashboardFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                dashboard: function(){
                    return undefined;
                }
            }
        }).state('dashboards.edit', {
            url: '/{dashboardId}',
            data: {
                authorities: ['ROLE_ADMIN']  
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/dashboard/dashboard-form.html',
                    controller: 'DashboardFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                dashboard: ['$q','Dashboard', '$stateParams', function($q, Dashboard, $stateParams){
                    var deferred = $q.defer();
                    Dashboard.get({id: $stateParams.dashboardId}, function(dashboard){
                        deferred.resolve(dashboard);
                    })
                    return deferred.promise;
                }]
            }
        });
    }

})();


(function() {
    'use strict';

    angular
        .module('pickerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dashboard-view', {
            parent: 'app',
            url: '/dashboard-view/{dashboardId}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/dashboard/dashboard-view.html',
                    controller: 'DashboardViewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                dashboard: ['Dashboard','$q', '$rootScope', '$stateParams', function(Dashboard, $q, $rootScope, $stateParams){
                    var deferred = $q.defer();
                    if($stateParams.dashboardId){
                        Dashboard.get({id: $stateParams.dashboardId},function(dashboard){
                            deferred.resolve(dashboard);
                            $rootScope.subName = dashboard.name;
                        });    
                    }else{
                        deferred.resolve(null)
                    }
                    return deferred.promise
                }],
                charts: ['Chart','$q', '$rootScope', '$stateParams', function(Chart, $q, $rootScope, $stateParams){
                    var deferred = $q.defer();
                    $rootScope.showLoading('Cargando gráficos...');
                    if($stateParams.dashboardId){
                        Chart.query({ dashboardId: $stateParams.dashboardId }, function(charts){
                            deferred.resolve(charts);
                            $rootScope.hideLoading();
                        });    
                    }else{
                        deferred.resolve(null);
                        $rootScope.hideLoading();
                    }
                    return deferred.promise
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    //$translatePartialLoader.addPart('dashboard-view');
                    return $translate.refresh();
                }]
            }
        });
    }

})();

