(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('PublicService', PublicService);

    PublicService.$inject = ['$resource'];

    function PublicService ($resource) {
        var resourceUrl =  'api/public';

        return $resource(resourceUrl, {}, {
            'getForm': { method: 'GET', url: 'api/public/forms/:id' },
            'getOrganization': { method: 'GET', url: 'api/public/organizations/:id' },
            'reCaptchaVerify': { method: 'POST', url: 'api/public/re-captcha/verify' },
            'saveFormRecord': { method: 'POST', url: 'api/public/form-records' }
        });
    }
})();