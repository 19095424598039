(function() {
    'use strict';

    angular
        .module('pickerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('form-record-searcher', {
            parent: 'app',
            url: '/form-record-searcher/{formId}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/form-record-searcher/form-record-searcher.html',
                    controller: 'FormRecordSearcherController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                form: ['Form','$q', '$rootScope', '$stateParams', function(Form, $q, $rootScope, $stateParams){
                    var deferred = $q.defer();
                    if($stateParams.formId){
                        Form.get({id: $stateParams.formId},function(form){
                            deferred.resolve(form);
                            $rootScope.subName = form.name;
                        });    
                    }else{
                        deferred.resolve(null)
                    }
                    return deferred.promise
                }],
                permissions: ['form','authorize','SecurityService', '$rootScope', '$stateParams', function(form, authorize, SecurityService, $rootScope, $stateParams){
                    return SecurityService.getPermissions(form);
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    //$translatePartialLoader.addPart('form-record-searcher');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
