(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('itemOptionForm',
        ['$q', '$filter', function ($q, $filter) {
            return {
                /* Only use as <item-option-form> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/entities/item-option/item-option-form.html',

                    /* Attributes to set */    
                    scope: {
                        "option": "=",
                        "index":"=",
                        "deleteOption": "=",
                        "parentItemId": "=",
                        "itemsSelect": "="
                    },

                    link: function (scope, element, attrs) {
                        scope.option.error = {};
                        element.find("select").select2({width: "100%"});
                        
                        scope.$watch("parentItemId", function(newVal, oldVal){
                            if(newVal!=undefined && newVal == oldVal){
                                scope.itemParent = $filter("filter")(scope.itemsSelect, {name: newVal})[0];
                            }else if(newVal!= undefined){
                                scope.itemParent = $filter("filter")(scope.itemsSelect, {name: newVal})[0];
                                delete scope.option.parentOptionId;
                            }
                        }, true);
                       
                    }
                };
            }
        ]
    )
    }
)();