angular
    .module('pickerApp')
    .controller('ModalDrawing', ['$scope', '$uibModalInstance', 'draw', function ($scope, $uibModalInstance, draw) {

        var canvas;
        var signaturePad;

        if (draw) {
            var image = new Image();
            image.src = draw
            image.onload = function() {
                createCanvasWithImage(image.src);
            };

        } else {
            createCanvasWithoutImage();
        }

        function createCanvasWithImage(image){
            canvas = document.getElementById('signature-pad');
            canvas.style.background = 'url(' + image + ')';
            canvas.style.backgroundSize = 'cover';

            signaturePad = new SignaturePad(canvas, {
                backgroundColor: 'rgba(0, 0, 0, 0)'
            });
        }

        function createCanvasWithoutImage() {
            $uibModalInstance.rendered.then(function () {
                canvas = document.getElementById('signature-pad');
                signaturePad = new SignaturePad(canvas, {
                    backgroundColor: 'rgb(255, 255, 255)'
                });
            });
        }

        $scope.clear = function () {
            signaturePad.clear();
            canvas.style.background = 'rgb(255, 255, 255)';
        };

        $scope.accept = function () {
            if (signaturePad.isEmpty()) {
                return alert("Debes realizar un dibujo antes de guardar.");
            }

            try{
                var combinedCanvas = document.createElement('canvas');
                combinedCanvas.width = canvas.width;
                combinedCanvas.height = canvas.height;
                var combinedCtx = combinedCanvas.getContext('2d');
                combinedCtx.drawImage(image, 0, 0, combinedCanvas.width, combinedCanvas.height);
                combinedCtx.drawImage(canvas, 0, 0, combinedCanvas.width, combinedCanvas.height);
                var dataURL = combinedCanvas.toDataURL('image/jpg');
                $uibModalInstance.close(dataURL);
            }catch(error){
                var dataURL = signaturePad.toDataURL('image/jpg');
                $uibModalInstance.close(dataURL);
            }
        };

        $scope.dismiss = function () {
            $uibModalInstance.dismiss();
        };
    }]);