(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('itemPropertyForm',
        ['$q', '$timeout','PropertyUtil', function ($q, $timeout, PropertyUtil) {
            return {
                /* Only use as <item-form> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/entities/item-property/item-property-form.html',

                    /* Attributes to set */    
                    scope: {
                        "propertyValue": "=",
                        "property": "=",
                        "availableProperties": "=",
                        "allProperties": "=",
                        "itemType": "=",
                        "index":"=",
                        "deleteProperty": "=",
                        "error": "=",
                        "changeSelectedProperty": "="
                    },

                    link: function (scope, element, attrs) {
                        scope.selectedProperty
                        
                        if(!Number.isNaN(parseFloat(scope.propertyValue))){
                            scope.propertyValue = parseFloat(scope.propertyValue);
                        }else if(scope.propertyValue=="true"){
                            scope.propertyValue = true;
                        }else if(scope.propertyValue=="false"){
                            scope.propertyValue = false;
                        }

                        scope.$watch("property", function(newVal){
                            if(newVal != undefined && newVal != ""){
                                scope.content = PropertyUtil.getTemplateByItemAndProperty(newVal, scope.itemType);
                                element.find("select[id*=generated]").select2({width: "100%"});
                            }else{
                                scope.content = undefined;
                            }
                        }, true)

                        scope.changeContent = function(){
                            scope.changeSelectedProperty(scope.property, scope.index);
                        }

                        $timeout(function(){
                            element.find("select").select2({width: "100%"});    
                        },100)
                    }
                };
            }
        ]
    )
    }
)();