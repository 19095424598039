(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('help',
        [function () {
            return {
                /* Only use as <help> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/components/help/help.html',

                    /* Attributes to set */    
                    scope: {
                    },

                    link: function (scope, element, attrs) {
                        scope.text = attrs.text;
                        scope.position = attrs.position;
                    }
                };
            }
        ]
    )
    }
)();