(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('Count', Count);

    Count.$inject = ['$resource'];

    function Count ($resource) {
        var resourceUrl =  'api/counters/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('CountAction', CountAction);

    CountAction.$inject = ['$resource'];

    function CountAction ($resource) {
        var resourceUrl =  'api/counter-actions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('CountCreator', CountCreator);

    CountCreator.$inject = ['$resource'];

    function CountCreator ($resource) {
        var resourceUrl =  'api/counter-creators/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();