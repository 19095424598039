(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('actionExecute',
        [
          '$q',
          '$timeout',
          '$filter',
          '$rootScope',
          'Action',
          'User',
          'FormHelper',
          'FormRecord',
          'ModalHelper',
          function (
            $q,
            $timeout,
            $filter,
            $rootScope,
            Action,
            User,
            FormHelper,
            FormRecord,
            ModalHelper
          ) {
            return {
                /* Only use as <action-execute> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/entities/action/action-execute.html',

                    /* Attributes to set */    
                    scope: {
                        'actions': '=',
                        'form': '=',
                        'selectedFormRecords': '=',
                        'callback': '=',
                        'hidePopover': '=',
                        'popupInMap': '=',
                        'addMapFilter': '=',
                        'permissions': '='
                    },

                    link: function (scope, element, attrs) {
                        var vm = {}
                        
                        scope.vm = vm;

                        vm.disableFilterButton = true;

                        setTimeout(function() {
                          $("select").select2({
                            width: "calc(100% - 20px)"
                          }
                        )}, 200);

                        scope.$watch("permissions", function(newVal){
                          vm.permissions = newVal;
                        }, true)

                        scope.$watch("form", function(newVal){
                          if(newVal != undefined){
                            vm.selectedAction = undefined
                            scope.users = [];
                            angular.forEach(newVal.formUsers, function(formUser){
                              scope.users.push(formUser.user);
                            })
                          }
                        })

                        if(scope.popupInMap){
                          vm.popupInMap = true;
                        }
                        
                        vm.addMapFilter = function(){
                          scope.addMapFilter(vm.selectedFormRecords);
                          $("#filter-button").html("Filtro añadido");
                          vm.disableFilterButton = true;
                        }

                        scope.$watch("vm.selectedAction", function(newVal){
                          if(newVal != undefined){
                            scope.users = []
                            angular.forEach(vm.selectedAction.actionUsers, function(actionUser){
                              scope.users.push(actionUser.user);
                            })
                          }
                        });

                        scope.$watch("selectedFormRecords", function(newVal){
                            vm.selectedFormRecords = newVal;
                            if(newVal && newVal.length > 0){
                              vm.disableFilterButton = false;
                              $("#filter-button").html("Añadir filtros");
                            }else if(newVal && newVal.length == 0){
                              vm.disableFilterButton = true;
                              $("#filter-button").html("Sin respuestas");
                            }else{
                              vm.disableFilterButton = true;
                            }
                        })

                        vm.close = function(){
                          scope.hidePopover();
                        }

                        function getFormDestination(){
                          $rootScope.showLoading('Cargando formulario...')
                          var deferred = $q.defer();
                          FormHelper.getFormWithExternalOptions(vm.selectedAction.formDestinationId).then(function(formDestination) {
                            $rootScope.hideLoading();
                            vm.formDestination = formDestination;
                            deferred.resolve(vm.formDestination);
                          });
                          return deferred.promise;
                        }
                        
                        vm.getTypeName = function(type){
                          switch(type){
                            case "create":
                              return "crear";
                            case "update":
                              return "actualizar (si se encuentran)";
                            case "delete":
                              return "eliminar (si se encuentran)";
                          }
                        }

                        vm.execute = function(){
                          if(scope.hidePopover != undefined){
                            scope.hidePopover();
                          }
                          if(vm.selectedAction){
                            getFormDestination().then(function (formDestination){
                              var modalInstance = ModalHelper.confirmation('Se van a <strong>'+vm.selectedAction.type.map(vm.getTypeName).join(' o ') +'</strong> '+vm.selectedFormRecords.length+' respuestas del formulario <b>'+formDestination.name+'</b> ¿Confirma la acción?');

                              modalInstance.result.then(function (confirmation) {
                                if(confirmation){
                                  prepareOriginFormRecords().then(function(result){
                                    if(result.isContinue){
                                      executeAction(vm.selectedAction).then(function(){
                                        var modalInstance = ModalHelper.alert('¡Se aplicó la acción correctamente!');

                                        modalInstance.result.then(function (confirmation) {
                                          if(scope.callback != undefined){
                                            scope.callback();  
                                          }
                                        }, function () {
                                          
                                        });
                                      });
                                    }
                                  });                
                                }
                              }, function () {
                                
                              });
                            })
                          }else{
                            asignTo(vm.selectedUserAction).then(function(){
                              var modalInstance = ModalHelper.alert("¡Se asignaron las respuestas correctamente!")
                              modalInstance.result.then(function(confirmation){
                                if(scope.callback != undefined){
                                  scope.callback();
                                }
                              })
                            })
                          }
                        }

                        var executeAction = function(action){
                          var deferred = $q.defer();
                          $rootScope.showLoading("Ejecutando acción...");
                          Action.executeManual({}, {actionId: action.id, formRecordsId: vm.selectedFormRecords, createdById: vm.selectedUserAction != undefined ? vm.selectedUserAction.id : null}, function(response){
                            $rootScope.hideLoading();
                            deferred.resolve();
                          })
                          return deferred.promise;
                        }

                        var prepareOriginFormRecords = function(){
                          var deferred = $q.defer();
                          if(vm.selectedFormRecords.length == 0){
                            deferred.resolve({isContinue: false});
                          }else{
                            $rootScope.showLoading("Obteniendo respuestas...");
                            var formRecords = [];
                            var loopPromises = [];
                            angular.forEach(vm.selectedFormRecords, function(formRecordId){
                              var loopDeferred = $q.defer();
                              loopPromises.push(loopDeferred.promise);
                              FormRecord.get({id: formRecordId}, function(formRecord){
                                loopDeferred.resolve();
                                formRecords.push(formRecord);
                              })
                            })
                            $q.all(loopPromises).then(function(){
                              $rootScope.hideLoading();
                              editOriginFormRecords(formRecords, scope.form, vm.selectedAction.id).then(function(result){
                                if(result.isContinue){
                                  deferred.resolve({isContinue: true})
                                }else{
                                  deferred.resolve({isContinue: false})
                                }
                                  
                              });
                            })
                          }
                          return deferred.promise;
                        }

                        var asignTo = function(user){
                          var deferred = $q.defer();
                          $rootScope.showLoading("Asignando respuestas...");
                          var request = {
                            formId: scope.form.id,
                            formRecordIds: vm.selectedFormRecords,
                            userIdToAssign: user.id
                          }
                          FormRecord.assign({}, request, function(response){
                            $rootScope.hideLoading();
                            deferred.resolve();
                          }, function(error) {
                            $rootScope.hideLoading();
                            deferred.resolve();
                          })
                          return deferred.promise;
                        }

                        var unassign = function(){
                         $rootScope.showLoading("Desasignando respuestas...");
                          request = {
                            formId: vm.form.id,
                            formRecordIds: vm.selectedFormRecords
                          }
                          FormRecord.unassign({}, request, function(response){
                            $rootScope.hideLoading();
                          }) 
                        }

                        var editOriginFormRecords = function(formRecords, form, actionId){     
                          var deferred = $q.defer(); 
                          var modalInstance = ModalHelper.open('app/entities/form-record/modal-form-record-masive-update.html',
                            'ModalFormRecordMasiveUpdate', {
                              form: function(){
                                return form;
                              },
                              formRecords: function(){
                                return formRecords;  
                              },
                              actionId: function(){
                                return actionId;
                              },
                              permissions: function(){
                                return vm.permissions;
                              }
                            }
                          );

                          modalInstance.result.then(function (value) {
                            if(value == 'ok'){
                              deferred.resolve({isContinue: true});
                            }else{
                              deferred.resolve({isContinue: false});
                            }
                          }, function (error) {
                          });
                          return deferred.promise;
                        }
                    }
                };
            }
        ]
    )
    }
)();