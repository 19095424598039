(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('ModalFormFrontConf', ModalFormFrontConf);

    ModalFormFrontConf.$inject = ['$uibModalInstance', 'form', 'filter', 'path', 'formFrontConf', 'updateFilters','FormFrontConf'];

    function ModalFormFrontConf ($uibModalInstance, form, filter, path, formFrontConf, updateFilters, FormFrontConf) {
        var vm = this;
        vm.form = form;
        if(updateFilters && formFrontConf){
            vm.formFrontConf = {
                id: formFrontConf.id,
            	name: formFrontConf.name,
            	description: formFrontConf.description,
            	formId: vm.form.id,
            	specific: filter.specific,
            	createdDateFrom: filter.createdDateFrom,
            	createdDateTo: filter.createdDateTo,
                updatedDateFrom: filter.updatedDateFrom,
                updatedDateTo: filter.updatedDateTo,
                assignedDateFrom: filter.assignedDateFrom,
                assignedDateTo: filter.assignedDateTo,
            	sort: filter.sort,
            	pickColor: filter.picksColor,
                pickIcon: filter.picksIcon,
                users: filter.usersId,
                actions: filter.actions,
                path: path
            }
        }else if(!updateFilters && formFrontConf){
            vm.formFrontConf = formFrontConf;
        }else{
            vm.formFrontConf = {
                id: undefined,
                name: undefined,
                description: undefined,
                formId: vm.form.id,
                specific: filter.specific,
                createdDateFrom: filter.createdDateFrom,
                createdDateTo: filter.createdDateTo,
                updatedDateFrom: filter.updatedDateFrom,
                updatedDateTo: filter.updatedDateTo,
                assignedDateFrom: filter.assignedDateFrom,
                assignedDateTo: filter.assignedDateTo,
                sort: filter.sort,
                pickColor: filter.picksColor,
                pickIcon: filter.picksIcon,
                users: filter.usersId,
                actions: filter.actions,
                path: path
            }
        }

 		vm.cancel = function () {
          $uibModalInstance.close('canceled');
        };

        vm.save = function(){
            if(vm.formFrontConf.id){  
                FormFrontConf.update({}, vm.formFrontConf, function(response){
                    $uibModalInstance.close("ok");
                })
            }else{
                FormFrontConf.save({}, vm.formFrontConf, function(response){
                    $uibModalInstance.close("ok");
                })
            }
        	
        }

    }

})();