(function() {
  'use strict';

  angular
      .module('pickerApp')
      .controller('ModalItemOptionEditorCtrl', ModalItemOptionEditorCtrl);

  ModalItemOptionEditorCtrl.$inject = ['$rootScope', '$scope', '$timeout', '$filter','$uibModalInstance', '$q', '$http', '$localStorage', 'item', 'itemsSelect', 'Form', 'ModalHelper'];

  function ModalItemOptionEditorCtrl ($rootScope, $scope, $timeout, $filter, $uibModalInstance, $q, $http, $localStorage, item, itemsSelect, Form, ModalHelper) {
      var vm = this;
      vm.item = angular.copy(item);
      vm.error = {};
      var username;
      if($localStorage.username == undefined){
        username = $sessionStorage.username;
      }else{
        username = $localStorage.username;
      }
      vm.itemsSelect = itemsSelect;

      if(vm.item.externalURL){
        if(vm.item.externalURL.includes("internal-options")){
          Form.get({id: parseFloat(getParameterByName("formId"))}, function(form){
            form.fieldSets[0].items = $filter("filter")(form.fieldSets[0].items, function(value, index, array){
              switch(value.type){
                  case "select":
                      return true;
                  case "autoincremental":
                      return true;
                  case "text":
                      return true;
                  case "address":
                      return true;
                  case "date":
                      return true;
                  case "checkbox":
                      return true;
                  case "map":
                      value.label = "Posición";
                      return true;
                  default:
                      return false;
              }
            })
            vm.anotherForm = form;
            vm.anotherItemValue = $filter("filter")(form.fieldSets[0].items, {id: parseFloat(getParameterByName("itemValueId"))})[0];
            vm.anotherItemLabel = $filter("filter")(form.fieldSets[0].items, {id: parseFloat(getParameterByName("itemLabelId"))})[0];
          })
          vm.fromAnotherForm = true;
          vm.fromExternalURL = false;
        }else{
          vm.fromAnotherForm = false;
          vm.fromExternalURL = true;
        }
      }else{
        vm.fromAnotherForm = false;
        vm.fromExternalURL = false;
      }

      if(vm.item.parentItemId){
        vm.isChild = true;
      }else{
        vm.isChild = false;
      }

      $scope.$watch("vm.anotherForm", function(newVal, oldVal) {
        if((newVal && !oldVal) || newVal.id != oldVal.id){
          Form.get({id: newVal.id}, function(form){
            form.fieldSets[0].items = $filter("filter")(form.fieldSets[0].items, function(value, index, array){
              switch(value.type){
                  case "select":
                      return true;
                  case "autoincremental":
                      return true;
                  case "text":
                      return true;
                  case "address":
                      return true;
                  case "date":
                      return true;
                  case "checkbox":
                      return true;
                  case "map":
                      value.label = "Posición";
                      return true;
                  default:
                      return false;
              }
            })
            vm.anotherForm = form;
            if (newVal.id != oldVal.id) {
              vm.anotherItemValue = undefined;
              vm.anotherItemLabel = undefined;
            }
            updateSelects();
          });
        }
      }, true)

      function getParameterByName(name, url) {
        if(!url){
          url = vm.item.externalURL;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      }

      $scope.$watch("vm.isChild", function(newVal){
        if(!newVal){
          delete vm.item.parentItemId;
        }
      })

      $scope.$watch("vm.fromAnotherForm", function(newVal){
        if(newVal){
           vm.fromExternalURL = false;
           delete vm.externalOptions;
           delete vm.item.values;
        }else if(!vm.fromExternalURL && !newVal){
          delete vm.item.externalURL;
        }
      })

      $scope.$watch("vm.fromExternalURL", function(newVal){
        if(newVal){
           vm.fromAnotherForm = false;
           delete vm.externalOptions;
           delete vm.item.values;
        }else if(!vm.fromAnotherForm && !newVal){
          delete vm.item.externalURL;
        }
      })


      vm.cancel = function(){
        $uibModalInstance.dismiss();
      }

      vm.save = function(){
        isValid().then(function(isValid){
          if(isValid){
            $uibModalInstance.close(vm.item);  
          }
        })
      }

      function isValid(){
        var deferred = $q.defer();
        var isValid = true;
        if(vm.isChild && !vm.item.parentItemId){
          vm.error["parentItemId"] = "Debe seleccionar un campo padre";
          isValid = false;
        }else{
          delete vm.error["parentItemId"];
        }
        if(vm.fromAnotherForm && vm.anotherForm && vm.anotherItemLabel){
          var formId = "formId="+vm.anotherForm.id;
          var itemLabelId = "&itemLabelId="+vm.anotherItemLabel.id;
          var itemValueId = vm.anotherItemValue && vm.anotherItemValue.id ? "&itemValueId="+vm.anotherItemValue.id : '';
          if(vm.isChild){
            if(vm.item.parentItemId && vm.anotherItemParentOption){
              var itemParent = $filter('filter')(vm.itemsSelect, {name: vm.item.parentItemId})[0];
              if (itemParent.externalURL == vm.anotherItemParentOption.externalURL){
                var itemParentFormRecordId = "&itemParentFormRecordId="+vm.anotherItemParentOption.id;
                vm.item.externalURL = window.location.href.replace(window.location.hash, '')+"/api/internal-options?"+formId+itemValueId+itemLabelId+itemParentFormRecordId;
                delete vm.error["fromAnotherForm"];  
              } else {
                vm.error["fromAnotherForm"] = "El campo padre y el campo opción padre no coinciden";
                isValid = false;
              }
            } else {
              vm.error["parentItemId"] = vm.item.parentItemId ? undefined : "Debe seleccionar un campo padre";
              vm.error["fromAnotherForm"] = vm.anotherItemParentOption ? undefined : "Debe seleccionar un campo opción padre";
              isValid = false; 
            }
          }else{
            vm.item.externalURL = window.location.href.replace(window.location.hash, '')+"/api/internal-options?"+formId+itemValueId+itemLabelId;
            delete vm.error["fromAnotherForm"];
          }
        }else if(vm.fromAnotherForm){
          vm.error["fromAnotherForm"] = "Hay errores en la selección de formulario, campo o etiqueta";
          isValid = false;
        }
        if(vm.fromExternalURL && vm.item.externalURL){
          $http.get(vm.item.externalURL, {params: {username: username}}).then(function(result){
            delete vm.error["externalURL"];
          }, function(error){
            vm.error["externalURL"] = "La dirección ingresada no devolvió opciones válidas";
            isValid = false;
          })
        }else if(vm.fromExternalURL){
          vm.error["externalURL"] = "La dirección ingresada no devolvió opciones válidas";
          isValid = false;
        }
        if(!vm.fromAnotherForm && !vm.fromExternalURL){
          if(!vm.item.values){
            vm.error.values = "No ha definido opciones para este campo";
            isValid = false;
          }else if(vm.item.values.length==0){
            vm.error.values = "No ha definido opciones para este campo";
            isValid = false;
          }else{
            delete vm.error.values;
            angular.forEach(vm.item.values, function(value){
              value.error = {}
              if(value.label == undefined || value.label == "" || value.label == undefined){
                isValid = false;
                value.error["label"] = "La etiqueta no puede estar blanco"
              }else{
                delete value.error["label"];
              }
              if(value.value == undefined || value.value == "" || value.value == undefined){
                isValid = false;
                value.error["value"] = "El valor no puede estar blanco"
              }else{
                delete value.error["value"];
              }
              if($filter("filter")(vm.item.values, function(val, index, array){return val.value === value.value;}).length > 1){
                isValid = false;
                value.error["duplicate"] = "Hay valores duplicados"
              }else{
                delete value.error["duplicate"];
              }
              if(vm.isChild && (value.parentOptionId == undefined || value.parentOptionId == null || value.parentOptionId == "")){      
                isValid = false;
                value.error["parentOptionId"] = "La opción no tiene una opción padre";
              }else{
                delete value.error["parentOptionId"];
              }
              if(Object.keys(value.error).length == 0){
                delete value.error;
              }
            })  
          }
          
        }
        $timeout(function(){
          deferred.resolve(isValid);
        },500);
        return deferred.promise;
      }

      vm.deleteOption = function(index){
        vm.item.values.splice(index, 1);
      }

      vm.addOption = function(){
        if(!vm.item.values){
          vm.item.values = [];
        }
        vm.item.values.push({label: "", value: ""});
      }

      vm.getExternalOptions = function(){
        
        if(vm.fromExternalURL && vm.item.externalURL){
          $http.get(vm.item.externalURL, {params: {username: username}}).then(function(result){
            delete vm.error["externalURL"];
            vm.externalOptions = result.data;
          }, function(error){
            vm.error["externalURL"] = "La dirección ingresada no devolvió opciones válidas";
          })  
        }else if(vm.fromAnotherForm){
          if(vm.anotherForm && vm.anotherItemLabel){
            delete vm.error["fromAnotherForm"];
            var params = {
              params: {
                username: username,
                formId: vm.anotherForm.id,
                itemLabelId: vm.anotherItemLabel.id,
                itemValueId: vm.anotherItemValue && vm.anotherItemValue.id ? vm.anotherItemValue.id : undefined
              }
            };
            if(vm.anotherItemValue){
              delete params.itemValueId;
            }
            if(vm.isChild){
              if(vm.item.parentItemId && vm.anotherItemParentOption){
                var itemParent = $filter('filter')(vm.itemsSelect, {name: vm.item.parentItemId})[0];
                if(itemParent.externalURL == vm.anotherItemParentOption.externalURL) {
                  params.params.itemParentFormRecordId = vm.anotherItemParentOption.id;
                } else {
                  vm.error["fromAnotherForm"] = "El campo padre y el campo opción padre no coinciden";
                  return;
                }
              }else{
                vm.error["parentItemId"] = vm.item.parentItemId ? undefined : "Debe seleccionar un campo padre";
                vm.error["fromAnotherForm"] = vm.anotherItemParentOption ? undefined : "Debe seleccionar un campo opción padre";
                return;
              }
            }else{
              delete params.params.itemParentFormRecordId;
            }
            $http.get(window.location.href.replace(window.location.hash, '')+"/api/internal-options", params).then(function(result){
              vm.externalOptions = result.data;  
            },function(error){
              vm.error["fromAnotherForm"] = "Hay errores en la selección de formulario, campo o etiqueta";
            }) 
          }else{
            vm.error["fromAnotherForm"] = "Hay errores en la selección de formulario, campo o etiqueta";
          }
        }
      }

      function updateSelects() {
        $timeout(function() {
          $("select").select2({width: "100%"});
          $scope.$digest();
        }, 500);
      }

      updateSelects();
  }

})();