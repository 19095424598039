(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('FormFormController', FormFormController)

    FormFormController.$inject = ['$rootScope', '$scope', '$filter', 'User', 'Permission', 'ModalHelper', 'Principal', 'LoginService', 'Form', 'FormProperty', 'ItemType', 'Property', '$state','$q', '$timeout', '$http', '$localStorage', '$sessionStorage', '$compile', 'form', 'properties']

    function FormFormController ($rootScope, $scope, $filter, User, Permission, ModalHelper, Principal, LoginService, Form, FormProperty, ItemType, Property,$state, $q, $timeout, $http, $localStorage, $sessionStorage, $compile, form, properties) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.form = angular.copy(form) != null ? angular.copy(form) : {fieldSets: [{id: null, items: []}], id: null};
        vm.error = {};
        vm.properties = properties;
        vm.prefix = window.location.href.replace(window.location.hash, '#/')+'public/forms/';

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.form.organizationId = vm.account.organizationId;
            });
        }

        Permission.query({},function(response){
            vm.permissions = response;
        })
          
        function getFormProperties(){
            FormProperty.query({type: 'form'}, function(response){
                vm.formProperties = response;
                if(vm.form.properties){
                    angular.forEach(Object.keys(vm.form.properties), function(key){
                        angular.forEach(vm.formProperties, function(formProperty){
                            if(formProperty.name == key && vm.form.properties[key] != "false" && vm.form.properties[key]){
                                formProperty.active = true;
                            }
                        })
                    })
                }
            })
        }

        function getItemTypes(){
            ItemType.query({}, function(response){
                vm.itemTypes = response;
            })
        }    
        
        vm.save = function(){   
            if(isValid()){
                $rootScope.showLoading("Guardando formularios...")
                vm.form.fieldSets[0].name = vm.form.name;
                vm.form.fieldSets[0].label = vm.form.name;
                vm.form.fieldSets[0].description = vm.form.description;
                if(form!=null){
                    Form.update({},vm.form, function(response){
                        $rootScope.hideLoading();
                        vm.back(true);
                    },function(error){
                        $rootScope.hideLoading();
                        console.log(error);
                    })
                }else{
                    Form.save({},vm.form, function(response){
                        $rootScope.hideLoading();
                        vm.back(true);
                    },function(error){
                        $rootScope.hideLoading();
                        console.log(error);
                    })
                }
            }else{
                ModalHelper.error("Hay errores en el formulario, revise, resuelva y vuelva a guardar");
            }
        }

        vm.back = function(saved){
            if(saved){
                $state.go("forms", {}, {reload: true});
            }else{
                $state.go("forms");
            }
            
        }


        vm.setFormProperty = function(name, active){
            if(active){
                vm.form.properties = vm.form.properties ? vm.form.properties : {}; 
                switch(name){
                    case "editable":
                        vm.form.properties[name] = true;
                        break;    
                    case "updatable":
                        vm.form.properties[name] = "all";
                        break;    
                    case "mapVisible":
                        vm.form.properties[name] = true;
                        break;    
                    case "valuesFromLastFormRecord":                    
                        vm.form.properties[name] = true;
                        break;    
                    case "publicLink":
                        Form.getHash({}, function(hash) {
                            var str = '';
                            angular.forEach(Object.keys(hash), function(key) {
                                if (!Number.isNaN(parseInt(key))) {
                                    str = str + hash[key];
                                }
                            })
                            vm.form.properties[name] = str;
                        })       
                        break;
                }
            }else{
                delete vm.form.properties[name]
            }
        }

        function isValid(){
            var isValid = true;
            if(vm.form.name == null || vm.form.name == undefined || vm.form.name == ""){
                vm.error.name = "El nombre de la formulario no puede estar en blanco";
                isValid = false;
            }else{
                delete vm.error.name;
            }
            if(!vm.form.fieldSets || vm.form.fieldSets.length == 0 || !vm.form.fieldSets[0] || !vm.form.fieldSets[0].items || vm.form.fieldSets[0].items.length == 0 ){
                vm.error.fieldSets = "El fomulario no tiene campos";
                isValid = false;
            }else{
                delete vm.error.fieldSets;
                var itemsValid = true
                angular.forEach(vm.form.fieldSets[0].items, function(item){
                    itemsValid = item.isValid() && itemsValid;
                })
                if(!itemsValid){
                    vm.error.fieldSets = "Uno o más campo contiene errores";
                    isValid = false;
                }
            }
            angular.forEach(vm.formProperties, function(property){
                property.error = {}
                if(property.active && (vm.form.properties[property.name] == undefined || vm.form.properties[property.name] == null || vm.form.properties[property.name] == "")){
                    property.error[property.name] = "El valor de la propiedad no puede estar vacío";
                    isValid = false;
                }
            })
            return isValid;
        }


        vm.addItem = function(){
            if(vm.form.id){
                var name = "name_"+vm.form.id +"_"+vm.form.fieldSets[0].id+"_"+vm.form.fieldSets[0].items.length; 
            }else{
                var name = "name_"+vm.form.fieldSets[0].items.length;
            }
            vm.form.fieldSets[0].items.push({name: name});
        }

        vm.deleteItem = function(index){
            vm.form.fieldSets[0].items.splice(index, 1);
        }

        vm.duplicateItem = function(index, item){
            var newItem = angular.copy(item);
            delete newItem.id;
            angular.forEach(newItem.values, function(value){
                delete value.id;
            })
            newItem.name = "name_"+(index+1);
            vm.form.fieldSets[0].items.splice(index+1, 0, newItem);
        }

        vm.copyToClipboard = function(text) {
            navigator.clipboard.writeText(text);
        };
        getAccount();
        getFormProperties();
        getItemTypes();
    }
})();