(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('ActionFormController', ActionFormController)

    ActionFormController.$inject = ['$rootScope', '$scope', '$filter', 'Form', 'Principal', 'LoginService', 'Action', 'TypeDetailAction', '$state','$q', '$timeout', '$http', '$localStorage', '$sessionStorage', '$compile', 'action', 'formOrigin', 'forms', 'ModalHelper', 'FormHelper']

    function ActionFormController ($rootScope, $scope, $filter, Form, Principal, LoginService, Action, TypeDetailAction, $state, $q, $timeout, $http, $localStorage, $sessionStorage, $compile, action, formOrigin, forms, ModalHelper, FormHelper) {
        var vm = this;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.formsOrigin = formOrigin != null ? [formOrigin] : [];
        vm.formOrigin = formOrigin;
        vm.formsDestination = [];
        vm.typeDetailActions = [];
        vm.actionExecuteConditionOperators = {};
        vm.actionExecuteConditionFields = {};
        vm.executionTypes = [{label: "Manual", value: "manual"},{label: "Automático", value: "automatic"}];
        vm.types = [{label: "Crear", value: "create"},{label: "Actualizar", value: "update"},{label: "Eliminar", value: "delete"}];
        vm.allTriggers = [{label: "Al actualizar", value: "onUpdate", executionType: "automatic"},{label: "Al crear", value: "onCreate", executionType: "automatic"}, {label: "Al eliminar", value: "onDelete", executionType: "automatic"},{label: "A demanda", value: "onDemand", executionType: "manual"}];
        vm.formDestination;
        vm.itemsDestination;
        vm.itemsOrigin = $filter('filter')(vm.formOrigin.fieldSets[0].items, function(value, index, array){
            switch(value.type){
                case "select":
                case "autoincremental":
                case "text":
                case "address":
                case "textarea":
                case "date":
                case "checkbox":
                case "image":
                case "drawing":
                case "file":
                    return true;
                case "map":
                    value.label = "Posición";
                    return true;
                default:
                    return false;
            }
        })
        vm.itemsOriginConditionable = $filter('filter')(vm.formOrigin.fieldSets[0].items, function(value, index, array){
            switch(value.type){
                case "select":
                case "autoincremental":
                case "text":
                case "address":
                case "date":
                    return true;
                default:
                    return false;
            }
        });
        vm.detailActions = action != null ? action.detailActions :[];
        vm.action = action != null ? angular.copy(action) : {formOriginId: vm.formOrigin.id, actionUsers: [], actionUpdateMapFinds: [], actionExecuteConditions: [], executionType: [], actionPermitExecutions: []};
        vm.commingFromTypeDelete = vm.action.type && vm.action.type.includes("delete");
        vm.action.actionPermitExecutions = [];
        
        vm.onChangeExecutionType = function(executionTypes){
            vm.triggers = [];
            if(executionTypes.includes("manual")){
                vm.hideActionPermitExecution = false;
            }else{
                vm.hideActionPermitExecution = true;
            }
            angular.forEach(executionTypes, function(executionType){
                vm.triggers = vm.triggers.concat($filter("filter")(vm.allTriggers, {executionType: executionType}, true));
            })
            vm.updateSelects();  
                   
        }

        vm.updateSelects = function(){
            $timeout(function(){
                $("select").select2({
                    width: "100%"
                });
                $('select').on('select2:open', function (e) {
                    var evt = "scroll.select2";
                    $(e.target).parents().off(evt);
                    $(window).off(evt);
                });
            }, 200);
        }
        vm.addActionExecuteCondition = function(conjuntionType){
            vm.action.actionExecuteConditions.push({itemId: null, operator: null, value: null, conjuntionType: conjuntionType});
            vm.updateSelects();
        }

        vm.removeActionExecuteCondition = function(index){
            vm.action.actionExecuteConditions.splice(index,1);
        }

        vm.onChangeActionExecuteConditionItem = function(actionExecuteCondition){
            if(actionExecuteCondition.itemId){
                var item = $filter("filter")(vm.itemsOriginConditionable, {id: actionExecuteCondition.itemId}, true)[0];
                item.added = true;
                switch(item.subtype ? item.subtype : item.type){
                    case "text":
                        vm.actionExecuteConditionOperators[actionExecuteCondition.itemId] = [{label: "exactamente", value: "equal"}, {label: "contiena a", value: "like"}];
                        vm.actionExecuteConditionFields[actionExecuteCondition.itemId] = "<div><input class='form-control' type='text' ng-model='actionExecuteCondition.value'></div>";
                        break;
                    case "address":
                        vm.actionExecuteConditionOperators[actionExecuteCondition.itemId] = [{label: "exactamente", value: "equal"}, {label: "contiena a", value: "like"}];
                        vm.actionExecuteConditionFields[actionExecuteCondition.itemId] = "<div><input class='form-control' type='text' ng-model='actionExecuteCondition.value'></div>";
                        break;
                    case "number":
                        actionExecuteCondition.value = parseFloat(actionExecuteCondition.value);
                        vm.actionExecuteConditionOperators[actionExecuteCondition.itemId] = [{label: "=", value: "equal"}, {label: ">", value: "greatherThan"}, {label: ">=", value: "greaterOrEqualThan"}, {label: "<", value: "lessThan"}, {label: "<=", value: "lessOrEqualThan"}, {label: "!=", value: "notEqual"}];
                        vm.actionExecuteConditionFields[actionExecuteCondition.itemId] = "<div><input class='form-control' type='number' ng-model='actionExecuteCondition.value'></div>";
                        break;
                    case "autoincremental":
                        actionExecuteCondition.value = parseFloat(actionExecuteCondition.value);
                        vm.actionExecuteConditionOperators[actionExecuteCondition.itemId] = [{label: "=", value: "equal"}, {label: ">", value: "greatherThan"}, {label: ">=", value: "greaterOrEqualThan"}, {label: "<", value: "lessThan"}, {label: "<=", value: "lessOrEqualThan"}, {label: "!=", value: "notEqual"}];
                        vm.actionExecuteConditionFields[actionExecuteCondition.itemId] = "<div><input class='form-control' type='number' ng-model='actionExecuteCondition.value'></div>";
                        break;
                    case "date":
                        vm.actionExecuteConditionOperators[actionExecuteCondition.itemId] = [{label: "=", value: "equal"}, {label: ">", value: "greatherThan"}, {label: ">=", value: "greaterOrEqualThan"}, {label: "<", value: "lessThan"}, {label: "<=", value: "lessOrEqualThan"}, {label: "!=", value: "notEqual"}];
                        vm.actionExecuteConditionFields[actionExecuteCondition.itemId] = "<div><input class='form-control' type='date' ng-model='actionExecuteCondition.value'></div>";
                        break;
                    case "select":
                        vm.actionExecuteConditionOperators[actionExecuteCondition.itemId] = [{label: "=", value: "equal"}, {label: "!=", value: "notEqual"}];
                        vm.actionExecuteConditionFields[actionExecuteCondition.itemId] = "<div><select-picker options='"+JSON.stringify(item.values).replace(/[\/\(\)\']/g, "&apos;")+"' value='actionExecuteCondition.value'></select-picker></div>";
                        vm.updateSelects();
                        break;
                    case "map":
                        vm.actionExecuteConditionOperators[actionExecuteCondition.itemId] = [];
                        break;
                }    
            }else{
                vm.actionExecuteConditionOperators[actionExecuteCondition.itemId] = [];
            }
        }

        $scope.changeSelectedFormDestination = function(formDestination){
            vm.detailActions = [];
            fillForm().then(function() {
                for (var i = 0; i < vm.itemsDestination.length; i++) {
                    vm.detailActions.push({ itemDestinationId: vm.itemsDestination[i].id });
                }
                vm.action.formDestinationId = vm.formDestination.id;
                vm.updateSelects()
            });
        }

        $scope.changeAsignableUsers = function(formUsers){
            vm.action.actionUsers = [];
            angular.forEach(formUsers, function(formUser){
                vm.action.actionUsers.push({user: formUser.user});
            })
        }

        vm.setAllAsignableUsers = function() {
            angular.forEach(vm.formDestination.formUsers, function(formUser){
                vm.action.actionUsers.push(formUser.user);
            });
            vm.updateSelects();
        };

        vm.removeAsignableUsers = function() {
            vm.action.actionUsers = [];
            vm.updateSelects();
        };

        $scope.changePermitedExecutionUsers = function(formUsers){
            vm.action.actionPermitExecutions = [];
            angular.forEach(formUsers, function(formUser){
                vm.action.actionPermitExecutions.push({user: formUser.user});
            })
        }
        
        $scope.getDetailAction = function(item){
            var detailAction = $filter('filter')(vm.detailActions, function(value, index, array){
                return value.itemDestinationId == item.id;
            })[0]
            if(detailAction == undefined){
                detailAction = {itemDestinationId: item.id};
                vm.detailActions.push(detailAction);
            }
            return detailAction
        }

        function fillForm(skipLoad){
            var deferred = $q.defer();
            $rootScope.showLoading('Cargando formulario...');
            vm.onChangeExecutionType(vm.action.executionType);
            angular.forEach(vm.action.actionExecuteConditions, function(val){
                vm.onChangeActionExecuteConditionItem(val);
            });
            vm.action.actionUpdateMapFinds[0] = vm.action.actionUpdateMapFinds.length == 0 ? {operator: "equal", itemFormDestinationId: null, itemFormOriginId: null} : vm.action.actionUpdateMapFinds[0]
            if (skipLoad) {
                vm.populateItemDestinations();
                $rootScope.hideLoading();
                deferred.resolve();
            } else {
                FormHelper.getFormWithExternalOptions(vm.formDestination.id).then(function(form) {
                    vm.formDestination = form;
                    vm.populateItemDestinations();
                    $rootScope.hideLoading();
                    deferred.resolve();
                })
            }
            return deferred.promise;
        } 

        vm.populateItemDestinations = function(){
            vm.itemsDestination = [];
            for(var i = 0; i < vm.formDestination.fieldSets[0].items.length; i++){
                var value = vm.formDestination.fieldSets[0].items[i];
                fillTypeDetailActionByItem(value);
            }
        }

        vm.onChangeActionType = function() {
            if (vm.action.type && vm.action.type.includes("delete")) {
                if (vm.commingFromTypeDelete) {
                    vm.commingFromTypeDelete = false;
                    vm.action.type = $filter("filter")(vm.action.type, function(t) { return t != "delete"});
                } else {
                    vm.commingFromTypeDelete = true;
                    vm.action.type = ["delete"];   
                }
            } else {
                vm.commingFromTypeDelete = false;
            }
            vm.populateItemDestinations();
        }

        function fillTypeDetailActionByItem(item){
            switch(item.type){
                case "select":
                    vm.itemsDestination.push(item);
                    vm.itemsDestination[vm.itemsDestination.length-1].typeDetailActions = $filter('filter')(vm.typeDetailActions, function(value, index, array){
                        switch(value.code){
                            case "link":
                            case "item_option":
                                return true;
                            case "input":
                                return false;
                            case "empty":
                                return vm.itemsDestination[vm.itemsDestination.length-1].required ? false : true;
                            case "hold":
                                return vm.action && vm.action.type && vm.action.type.includes("update");
                            case "default":
                                return false;
                        }
                    })
                    break;
                case "checkbox":
                    vm.itemsDestination.push(item);
                    vm.itemsDestination[vm.itemsDestination.length-1].typeDetailActions = $filter('filter')(vm.typeDetailActions, function(value, index, array){
                        switch(value.code){
                            case "link":
                            case "item_option":
                                return false;
                            case "input":
                                return true;
                            case "empty":
                                return vm.itemsDestination[vm.itemsDestination.length-1].required ? false : true;
                            case "hold":
                                return vm.action && vm.action.type && vm.action.type.includes("update");
                            case "default":
                                return false;
                        }
                    })
                    break;
                case "text":
                case "address":
                case "date":
                case "textarea":
                    vm.itemsDestination.push(item);
                    vm.itemsDestination[vm.itemsDestination.length-1].typeDetailActions = $filter('filter')(vm.typeDetailActions, function(value, index, array){
                        switch(value.code){
                            case "item_option":
                                return false;
                            case "link":
                            case "input":
                                return true;
                            case "empty":
                                return vm.itemsDestination[vm.itemsDestination.length-1].required ? false : true;
                            case "hold":
                                return vm.action && vm.action.type && vm.action.type.includes("update");
                            case "default":
                                return false;
                        }
                    })
                    break;
                case "image":
                case "map":
                case "drawing":
                case "file":
                    vm.itemsDestination.push(item);
                    vm.itemsDestination[vm.itemsDestination.length-1].typeDetailActions = $filter('filter')(vm.typeDetailActions, function(value, index, array){
                        switch(value.code){
                            case "item_option":
                            case "input":
                                return false;
                            case "link":
                                return true;
                            case "empty":
                                return vm.itemsDestination[vm.itemsDestination.length-1].required ? false : true;
                            case "hold":
                                return vm.action && vm.action.type && vm.action.type.includes("update");
                            case "default":
                                return false;
                        }
                    })
                    break;
                case "autoincremental":
                    vm.itemsDestination.push(item);
                    vm.itemsDestination[vm.itemsDestination.length-1].typeDetailActions = $filter('filter')(vm.typeDetailActions, function(value, index, array){
                        switch(value.code){
                            case "item_option":
                            case "input":
                            case "empty":
                            case "link":
                                return false;
                            case "hold":
                                return vm.action && vm.action.type && vm.action.type.includes("update");
                            case "default":
                                return true;
                        }
                    })
                    break;
                default:
                    break;
            }
        }
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function getTypeDetailActions() {
            TypeDetailAction.query({},function(response) {
                angular.forEach(response, function(typeDetailAction){
                    vm.typeDetailActions.push(typeDetailAction.toJSON());
                })
            });
        }

        function getForms(){
            $timeout(function() {;
            vm.formsDestination = $filter('filter')(forms, function(value, index, array){
                return value.id !== vm.formOrigin.id;
            });
            if(action != null){
                for(var i = 0; i< vm.formsDestination.length; i++){
                    if(action.formDestinationId == vm.formsDestination[i].id){
                        FormHelper.getFormWithExternalOptions(action.formDestinationId).then(function(form) {
                            vm.formDestination = form;
                            vm.action.actionUsers = [];
                            for(var j = 0; j < action.actionUsers.length; j++){
                                var actionUser = action.actionUsers[j];
                                for(var k = 0; k < vm.formDestination.formUsers.length; k++){
                                    var formUser = vm.formDestination.formUsers[k];
                                    if(actionUser.user.id == formUser.user.id){
                                        vm.action.actionUsers.push(formUser.user);
                                    }
                                }
                            }
                            for(var j = 0; j < action.actionPermitExecutions.length; j++){
                                var actionPermitExecution = action.actionPermitExecutions[j];
                                for(var k = 0; k < vm.formOrigin.formUsers.length; k++){
                                    var formUser = vm.formOrigin.formUsers[k];
                                    if(actionPermitExecution.user.id == formUser.user.id){
                                        vm.action.actionPermitExecutions.push(formUser.user);
                                    }
                                }
                            }
                            fillForm(true).then(function(){
                                vm.updateSelects();
                            });
                        })
                    }
                }
            }else{
                vm.updateSelects();   
            } 
                      
          }, 400)

        }
      
        vm.save = function(){
            if(validateAction()){
                if (!vm.action.type.includes("delete")) {
                    vm.action.detailActions = vm.detailActions;
                }
                vm.action.executionType = vm.action.executionType.join(",");
                vm.action.type = vm.action.type.join(",");
                vm.action.actionUsers = vm.action.actionUsers.map(function(user){return {user: user}});
                vm.action.actionPermitExecutions = vm.action.actionPermitExecutions.map(function(user){return {user: user}});
                if(vm.action.type == "create") delete vm.action.actionUpdateMapFinds;
                vm.action.trigger = vm.action.trigger.join(",");      
                if(action!=null){
                    Action.update({},vm.action, function(response){
                        vm.back();
                    },function(error){
                        console.log(error);
                    })
                }else{
                    Action.save({},vm.action, function(response){
                        vm.back();
                    },function(error){
                        console.log(error);
                    })
                }
            }
        }

        vm.back = function(){
            $state.go("actions", {form: vm.formOrigin});
        }

        function validateAction(){
            if(vm.action.name == null || vm.action.name == undefined || vm.action.name == ""){
                vm.error = {message: "El nombre de la acción no puede estar en blanco"}
                return false;
            }
            if(vm.action.description == null || vm.action.description == undefined || vm.action.description == ""){
                vm.error = {message: "La descripción de la acción no puede estar en blanco"}
                return false;
            }
            if(vm.action.executionType == null || vm.action.executionType == undefined || vm.action.executionType.length == 0){
                vm.error = {message: "Debe seleccionar al menos un tipo de ejecución"}
                return false;
            }
            if(vm.formDestination == null || vm.formDestination == undefined){
                vm.error = {message: "Debe seleccionar un formulario de destino"};
                return false;
            }
            if (!vm.action.type.includes("delete")) {
                for(var i = 0; i<vm.detailActions.length; i++){
                    if(vm.detailActions[i].typeDetailAction == undefined){
                        vm.error = {message: "Uno o más items de la acción no se han configurado correctamente"}
                        return false;
                    }
                }
            }
            for (var i = 0; i < vm.action.actionExecuteConditions.length; i++) {
                if (!vm.action.actionExecuteConditions[i].itemId ||
                    !vm.action.actionExecuteConditions[i].operator || 
                    !vm.action.actionExecuteConditions[i].value) {
                    vm.error = {message: "Una o más condiciones de ejecución no se han configurado correctamente"}
                    return false;
                }
            }
            vm.error = undefined;
            return true;
        }

        vm.openActionExecutionConditionEditor = function(){
            var modalInstance = ModalHelper.open('app/entities/action/action-assign-condition-editor.html',
                'ModalActionAssignConditionEditorCtrl', {
                    actionUsers: function(){
                        return vm.action.actionUsers;
                    },
                    items: function(){
                        return $filter('filter')(vm.itemsOriginConditionable, {type: "select"});
                    },
                    actionAssignConditions: function(){
                        return vm.action.actionAssignConditions;
                    }
                }
            );
            modalInstance.result.then(function (actionAssignConditions) {
                if(actionAssignConditions){
                    vm.action.actionAssignConditions = actionAssignConditions;
                }
                
            });  
        }

        getAccount(); 
        getForms();
        getTypeDetailActions();
        
    }
})();