(function() {
    'use strict';

    angular
        .module('pickerApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', '$rootScope', 'Account', 'Organization'];

    function Principal ($q, $rootScope, Account, Organization) {
        var _identity,
            _authenticated = false;

        function authenticate (identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority (authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority (authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function(){
                return false;
            });
        }

        function identity (force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                $rootScope.user = _identity;
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen (account) {
                _identity = account.data;
                if(_identity.organizationId){
                    Organization.get({id: _identity.organizationId}).$promise.then(function(organization){
                        $rootScope.organization = organization;
                        $("script[src*='maps.googleapis.com']").remove();
                        $rootScope.googleUrl = 'https://maps.googleapis.com/maps/api/js?key=' + organization.gmapsApiKey + '&libraries=drawing,places,geometry';
                        var script = document.createElement("script");
                        script.setAttribute("src",$rootScope.googleUrl);
                        document.getElementsByTagName("head")[0].appendChild(script);
                        $rootScope.user = _identity;
                        _authenticated = true;
                        deferred.resolve(_identity);
                    })
                }else{
                    $rootScope.user = _identity;
                    _authenticated = true;
                    deferred.resolve(_identity);
                }
            }

            function getAccountCatch () {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated () {
            return _authenticated;
        }

        function isIdentityResolved () {
            return angular.isDefined(_identity);
        }

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved
        };

        return service;
    }
})();
