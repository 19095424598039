(function() {
  'use strict';

  angular
      .module('pickerApp')
      .controller('ModalChartFormCtrl', ModalChartFormCtrl);

  ModalChartFormCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$timeout',
    '$filter',
    '$uibModalInstance',
    '$q',
    '$stateParams',
    'chart',
    'Chart',
    'ChartHelper',
    'ActionHelper',
    'FormHelper'
  ];

  function ModalChartFormCtrl(
    $rootScope,
    $scope,
    $timeout,
    $filter,
    $uibModalInstance,
    $q,
    $stateParams,
    chart,
    Chart,
    ChartHelper,
    ActionHelper,
    FormHelper
  ) {
    var vm = this;
    vm.chart = {};
    vm.filters = [];
    vm.disabledFrequency = true;
    vm.disabledFieldSelector = false;
    vm.disabledChartCalcType = false;
    vm.widths = [
      {
        value: 1,
        label: '8,33%'
      },
      {
        value: 2,
        label: '16,67%'
      },
      {
        value: 3,
        label: '25%'
      },
      {
        value: 4,
        label: '33,33%'
      },
      {
        value: 5,
        label: '41,67%'
      },
      {
        value: 6,
        label: '50%'
      },
      {
        value: 7,
        label: '58,33%'
      },
      {
        value: 8,
        label: '66,67%'
      },
      {
        value: 9,
        label: '75%'
      },
      {
        value: 10,
        label: '83,33%'
      },
      {
        value: 11,
        label: '91,67%'
      },
      {
        value: 12,
        label: '100%'
      }
    ]


    vm.heights = [
      {
        value: 100,
        label: '100px'
      },
      {
        value: 150,
        label: '150px'
      },
      {
        value: 200,
        label: '200px'
      },
      {
        value: 250,
        label: '250px'
      },
      {
        value: 300,
        label: '300px'
      },
    ]
    vm.forms = $rootScope.forms;
    vm.formsSelectorDisabled = false;

    ChartHelper.getChartFrequencies().then(function(frequencies) {
      vm.allFrequencies = frequencies;      
    })

    ChartHelper.getChartTypes().then(function(types) {
      vm.chartTypes = types;
    })

    ChartHelper.getChartCalcTypes().then(function(calcTypes) {
      vm.calcTypes = [calcTypes[0], calcTypes[1]];
    })

    vm.onChangeChartType = function(onLoad){
      if(!onLoad){
        vm.chart.chartFrequencyId = (vm.chart.chartTypeId == 1 || vm.chart.chartTypeId == 3) ? null : 6;  
      }
      vm.disabledFrequency = (vm.chart.chartTypeId == 2 || vm.chart.chartTypeId == 4 || vm.chart.chartTypeId == 5) || !vm.chart.chartTypeId;
      vm.disabledFieldSelector = (vm.chart.chartTypeId == 5);
      vm.disabledChartCalcType = (vm.chart.chartTypeId == 5);
      vm.chart.field = vm.chart.chartTypeId == 5 ? 'form' : 'item';
      if (vm.disabledFieldSelector) {
        vm.chart.fieldId = undefined;
      }
      if (vm.disabledChartCalcType) {
        vm.chart.chartCalcTypeId = 1;
      }
      vm.frequencies = $filter('filter')(vm.allFrequencies, function(val){
        switch(val.code){
        case "daily":
          return vm.chart.chartTypeId == 1 || vm.chart.chartTypeId == 3;
        case "weekly":
          return vm.chart.chartTypeId == 1 || vm.chart.chartTypeId == 3;
        case "biweekly":
          return vm.chart.chartTypeId == 1 || vm.chart.chartTypeId == 3;
        case "monthly":
          return vm.chart.chartTypeId == 1 || vm.chart.chartTypeId == 3;
        case "yearly":
          return vm.chart.chartTypeId == 1 || vm.chart.chartTypeId == 3;
        case "total":
          return vm.chart.chartTypeId == 1 
          || vm.chart.chartTypeId == 2 
          || vm.chart.chartTypeId == 4
          || vm.chart.chartTypeId == 5;
        }
      });
    }

    vm.onFormChange = function() {
      vm.filters = [];
      getForm(vm.chart.formId);
    }

    function fillForm() {
      var chartTemp = chart ? angular.copy(chart) : {hidden: false, field: "item", formFrontConf: {}}; 
      vm.chart = Object.assign({}, chartTemp, vm.chart);
      if (vm.chart.formFrontConf.specific) {
        var itemsStr = vm.chart.formFrontConf.specific.split(';');
        for(var i = 0; i < itemsStr.length; i++) {
          var item;
          var value;
          var paramsStr = itemsStr[i].split('&');
          for(var j = 0; j < paramsStr.length; j++){
            var keyValue = paramsStr[j].split(':');
            switch (keyValue[0]){
              case 'itemId':
                item = $filter('filter')(vm.itemsFilterable, function(val){
                  return val.id == keyValue[1];
                })[0];
                break;
              case 'value':
                value = keyValue[1];
                break;
              case 'type':
                if(keyValue[1] == 'select'){
                  vm.filters.push({item: item, value: value.split(',') });  
                }else if(keyValue[1] == 'text'){
                  vm.filters.push({item: item, value: value });
                }else if(keyValue[1] == 'date'){
                  vm.filters.push({item: item, value: new Date(value) });
                }else if(keyValue[1] == 'autoincremental'){
                  vm.filters.push({item: item, value: parseFloat(value) });
                }else if(keyValue[1] == 'number'){
                  vm.filters.push({item: item, value: parseFloat(value) });
                }
                break;
            }
          }
        }
      }
      if (vm.chart.formFrontConf.users) {
        vm.filters.push({
          item: $filter('filter')(vm.itemsFilterable, {type: 'users'})[0]
        })
        vm.chart.formFrontConf.users = vm.chart.formFrontConf.users.split(',').map(function(val){ return parseFloat(val); });
      }
      if (vm.chart.formFrontConf.actions) {
        vm.filters.push({
          item: $filter('filter')(vm.itemsFilterable, {type: 'actions'})[0],
          value: vm.chart.formFrontConf.actions.split(',').map(function(val){ return val == 'null' ? null : parseFloat(val); })
        });
      }
      vm.onChangeChartType(true);
    }

    vm.addFilter = function(){
      vm.filters.push({});
      updateSelects();
    }

    vm.onFilterItemChange = function(item){
      updateSelects();
    }

    vm.cancel = function(){
      $uibModalInstance.dismiss();
    }

    vm.save = function(){
      if(isValid()){
        if(vm.filters.length > 0){
          vm.chart.formFrontConf.specific = '';
          for(var i = 0; i < vm.filters.length; i++){
            if(vm.filters[i].item && vm.filters[i].value && vm.filters[i].item.type != 'users' && vm.filters[i].item.type != 'actions'){
              vm.chart.formFrontConf.specific = vm.chart.formFrontConf.specific+"itemId:"+vm.filters[i].item.id+"&value:"+vm.filters[i].value+"&type:"+(vm.filters[i].item.subtype ? vm.filters[i].item.subtype : vm.filters[i].item.type)+";";
            }else if (vm.filters[i].item && vm.chart.formFrontConf.users && vm.filters[i].item.type == 'users' && vm.filters[i].item.type != 'actions'){
              vm.chart.formFrontConf.users = vm.chart.formFrontConf.users.join(',');
            }else if(vm.filters[i].item && vm.filters[i].value && vm.filters[i].item.type != 'users' && vm.filters[i].item.type == 'actions'){
              var actions = "";
              for(var j = 0; j < vm.filters[i].value.length; j++){
                actions = actions + vm.filters[i].value[j] + ",";
              }
              vm.chart.formFrontConf.actions = actions.slice(0,-1);
            }
          }
          if(vm.chart.formFrontConf.specific != ''){
            vm.chart.formFrontConf.specific = vm.chart.formFrontConf.specific.slice(0,-1);
          } else {
            delete vm.chart.formFrontConf.specific;
          }
          if(vm.chart.formFrontConf.actions && vm.chart.formFrontConf.actions != []){
          } else {
            delete vm.chart.formFrontConf.actions;
          }
        }
        if (vm.chart.formFrontConf && vm.chart.formFrontConf.updatedDateFrom) {
          vm.chart.formFrontConf.updatedDateFrom = null;
        }

        if (vm.chart.formFrontConf && vm.chart.formFrontConf.updatedDateTo) {
          vm.chart.formFrontConf.updatedDateTo = null;
        }
        if(chart){           
          $rootScope.showLoading("Actualizando gráfico...");
          Chart.update({}, vm.chart, function(response){
            vm.chart.id = response.id;
            vm.chart.formFrontConf.id = response.formFrontConf.id;
            vm.chart.chartSnapshot = response.chartSnapshot;
            $rootScope.hideLoading();
            $uibModalInstance.close(vm.chart);
          }, function(error) {
            $rootScope.hideLoading();
          })
        } else {
          $rootScope.showLoading("Guardando gráfico...");
          Chart.save({}, vm.chart, function(response){
            vm.chart.id = response.id;
            vm.chart.formFrontConf.id = response.formFrontConf.id;
            vm.chart.chartSnapshot = response.chartSnapshot;
            $rootScope.hideLoading();
            $uibModalInstance.close(vm.chart);
          }, function(error) {
            $rootScope.hideLoading();
          })
        }
      }
    }

    vm.removeFilter = function(index){
      vm.filters.splice(index, 1);
      updateSelects();
    }

    var isValid = function(){
      return true;
    }

    function updateSelects(){
      $timeout(function(){
        $("select").select2({width: "100%"});
      }, 100);
    }

    function getForm(id) {
      var deferred = $q.defer();
      $rootScope.showLoading("Cargando formulario...");
      FormHelper.getFormWithExternalOptions(id).then(function(form) {
        vm.chart.formId = form.id;
        FormHelper.getItemsThatCanBeFilter(form).then(function(items) {
          vm.itemsFilterable = items;
          vm.itemsFilterable.push({label: "Usuario asignado", type: "users" });
          vm.itemsFilterable.push({label: "Última acción", type: "actions" });
          updateSelects();

        })
        FormHelper.getItemsTypeSelect(form).then(function(items) {
          vm.itemsSelect = items;
          updateSelects();
        })
        FormHelper.getUsers(form).then(function(users) {
          vm.users = angular.copy(users);
        });
        ActionHelper.getActionsOfFormWithNullAction(id).then(function(response) {
          vm.actions = angular.copy(response);
          $rootScope.hideLoading();
          deferred.resolve();
        });

      })
      return deferred.promise;
    }

    if ($stateParams.dashboardId) {
      vm.chart.dashboardId = $stateParams.dashboardId;
    }

    if ($stateParams.formId) {
      vm.formsSelectorDisabled = true;
      getForm($stateParams.formId).then(function() {
        updateSelects();
        fillForm();
      });
    } else if (chart) {
      getForm(chart.formId).then(function() {
        updateSelects();
        fillForm();
      });
    }



    $timeout(function(){
      $scope.$digest();
      $("select").select2({width: "100%"});
    })

  }

})();