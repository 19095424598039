(function(){
	'user strict';
	angular.module('pickerApp')
	.controller("GoogleMapsRectanglePickerController",
  ['$q', "$rootScope",'$scope', '$timeout', 'NgMap',
    function ($q, $rootScope, $scope, $timeout, NgMap) {       

        var getFilterBounds = function(text){
          var points = text.split("|");
          var s, n, e, w;
          n = parseFloat(points[1].split(",")[0]);
          s = parseFloat(points[0].split(",")[0]);
          e = parseFloat(points[0].split(",")[1]);
          w = parseFloat(points[1].split(",")[1]);
          if(Number.isNaN(n)|| Number.isNaN(s)|| Number.isNaN(w)|| Number.isNaN(e)){
            throw "Parse exception, wrong rectangle value";
          }else{
            return bound = new google.maps.LatLngBounds({lat: s, lng: w},{lat: n, lng: e});  
          }
        }
        
        $scope.search = function(){
          var request = {
            query: $("#queryNewRectangle").val(),
            fields: ['name', 'geometry'],
          };
          
          var service = new google.maps.places.PlacesService($scope.map);
          service.findPlaceFromQuery(request, function(results, status){
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              $scope.map.setCenter(results[0].geometry.location);
            }
          })
        }

        NgMap.getMap({id: "rectangle"}).then(function(map){
          $scope.map;
         
          var autocomplete = new google.maps.places.Autocomplete($("#queryNewRectangle")[0]);

          autocomplete.addListener("place_changed", function(){
            if(autocomplete.getPlace().geometry){
              $scope.map.setCenter(autocomplete.getPlace().geometry.location);  
            }          
          })

          $scope.drawingManager = new google.maps.drawing.DrawingManager({
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT,
              drawingModes: ['rectangle'],
              polygonOptions: {editable: true},
            }
          });

          $scope.drawingManager.setMap(map);

          addButtonDropShapeMap();

          google.maps.event.addListener($scope.drawingManager, 'overlaycomplete', function(event) {
            $('[title="Dibujar rectángulo"]').attr("disabled", true);
            $scope.drawingManager.setDrawingMode(null);
            $scope.overlay = event.overlay;
            var bounds = $scope.overlay.getBounds();
            var se = bounds.getSouthWest().lat()+","+bounds.getNorthEast().lng();
            var nw = bounds.getNorthEast().lat()+","+bounds.getSouthWest().lng();
            $scope.rectangle = se+"|"+nw;
            $timeout(function(){$scope.$apply()});
          })
          
          $(document).on('keypress', "#queryNewRectangle", function(e){
            if(e.key === 'Enter'){
              $scope.search();
            }
          });

          google.maps.event.trigger(map, "resize");

          $scope.$watch('rectangle', function(newVal, oldVal){
            if(newVal != undefined){
              var rectangle = new google.maps.Rectangle({
                bounds: getFilterBounds(newVal),
                strokeColor: "#000000",
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: "#000000",
                fillOpacity: 0.35,
              })
              rectangle.setMap($scope.map);
              $scope.map.fitBounds(rectangle.getBounds());
              $('[title="Dibujar rectángulo"]').attr("disabled", true);
              $scope.drawingManager.setDrawingMode(null);
              if($scope.overlay){
                $scope.overlay.setMap(null);
              }
              $scope.overlay = rectangle;
            }else{
              if($scope.drawingManager)
              $scope.drawingManager.setDrawingMode(null);
              if($scope.overlay){
                $scope.overlay.setMap(null);
                $('[title="Dibujar rectángulo"]').attr("disabled", false);
              }
            }
          })

          if(!$scope.rectangle){
            getInitialPosition().then(function(position){
              if(position){
                $scope.map.setCenter(position);
              }else{
                var lat = -32.90255;
                var lng = -68.84533;
                $scope.map.setCenter({
                  lat: lat,
                  lng: lng
                });
              }
            })
          }


        });
      

      var getInitialPosition = function(){
        var deferred = $q.defer();
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position){
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            deferred.resolve({lat: lat, lng: lng});
          }, function(error){
            deferred.resolve();
          });
        }else{
          deferred.resolve();
        }
        return deferred.promise;
      }

      function addButtonDropShapeMap(){
        var drop_button = document.createElement("span");
        drop_button.setAttribute("class", "glyphicon glyphicon-trash drop-shape");
        drop_button.setAttribute("title", "Eliminar forma");
        drop_button.addEventListener("click",function(ev){
          $scope.drawingManager.setDrawingMode(null);
          if($scope.overlay){
            $scope.overlay.setMap(null);
            $scope.rectangle = undefined;
            $timeout(function(){$scope.$apply()});
            $('[title="Dibujar rectángulo"]').attr("disabled", false);
          }
        })
        $scope.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(drop_button);
        $timeout(function(){
          $('[title="Dejar de dibujar"]').attr('style', 'background: none padding-box rgb(255, 255, 255); display: block; border: 0px; margin: 0px; padding: 4px; text-transform: none; appearance: none; position: relative; cursor: pointer; user-select: none; direction: ltr; overflow: hidden; text-align: left; color: rgb(0, 0, 0); font-family: Roboto, Arial, sans-serif; font-size: 11px; border-bottom-left-radius: 0px; border-top-left-radius: 0px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; font-weight: 500;');
        },1000);
      }


      /* End LeafletMarkerPickerController*/
    }])
})();