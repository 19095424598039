(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('positionRectanglePicker',
  [
    '$q','$timeout',
    function ($q, $timeout) {
      return {
        /* Only use as <select-picker> tag */
        restrict: 'E',

        /* Our template */
        templateUrl: 'app/components/item-template/position-picker/position-rectangle-picker.html',
        controller: 'GoogleMapsRectanglePickerController',
        /* Attributes to set */
        scope: {
          'rectangle': '=',
        },

        link: function (scope, element, attrs) {
           
        }
      };
    }
  ]
);
})();