(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('HomeController', HomeController)

    HomeController.$inject = ['$scope', '$timeout', '$filter', 'Principal', 'LoginService', '$state'];

    function HomeController ($scope, $timeout, $filter, Principal, LoginService, $state) {
        var vm = this;
        
        vm.account = null;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.login = LoginService.open;
        
        
        function register () {
            $state.go('register');
        }
        
        vm.isAuthenticated = function(){
        	return Principal.isAuthenticated;
        }
        
        vm.login = function(){
        	LoginService.open;
        }
        
        $scope.$on('$viewContentLoaded', function(){        	
            if(vm.isAuthenticated()){
                Principal.hasAuthority("ROLE_SUPER_ADMIN").then(function(isSuperAdmin) {
                    if (isSuperAdmin) {
                        $state.go("organizations");      
                    } else {
                        $state.go('form-record-searcher');   
                    }
                })
            }else{
            	$state.go('sign_in');
            }
          });
    }
       
})();
