(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('Form', Form);

    Form.$inject = ['$resource'];

    function Form ($resource) {
        var resourceUrl =  'api/forms/:id';

        return $resource(resourceUrl, {}, {
            'query': { url: 'api/forms-without-relations', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        var properties = {}
                        angular.forEach(data.properties, function(property){
                            angular.forEach(Object.keys(property), function(key){
                                if(key != "id"){
                                    properties[key] = property[key];
                                }
                                if(key == "defaultMapCenter"){
                                    properties[key] = properties[key].replace(",","|");
                                }
                                if(key == "formRecordLimit"){
                                    properties[key] = parseInt(properties[key]);
                                }
                            })
                        })
                        if(data.fieldSets[0]){
                            angular.forEach(data.fieldSets[0].items, function(item){
                                if(item.type=="image" && item.value){
                                    item.value = parseFloat(item.value);
                                }
                            })   
                        }
                        data.properties = properties;
                    }
                    return data;
                }
            },
            'update': { method:'PUT' , transformRequest: function(data){
                var form = angular.copy(data);
                if(form.properties){
                    var properties = [];
                    angular.forEach(Object.keys(form.properties), function(key){
                        var property = {}
                        property[key] = form.properties[key];

                        if(key == "defaultMapCenter"){
                            property[key] = property[key].replace("|",",");
                        }
                        if(key == "formRecordLimit"){
                            properties[key] = parseInt(properties[key]);
                        }
                        properties.push(property);
                    })
                    form.properties = properties;    
                }
                return angular.toJson(form);
            }},
            'save': { method: 'POST', id: '@id', forms: '@params', transformRequest: function(data){
                var form = angular.copy(data);
                if(form.properties){
                    var properties = [];
                    angular.forEach(Object.keys(form.properties), function(key){
                        var property = {}
                        property[key] = form.properties[key];

                        if(key == "defaultMapCenter"){
                            property[key] = property[key].replace("|",",");
                        }
                        if(key == "formRecordLimit"){
                            properties[key] = parseInt(properties[key]);
                        }
                        properties.push(property);
                    })
                    form.properties = properties;    
                }
                return angular.toJson(form);
            }},
            'getHash': { 
                method: 'GET',
                url: 'api/forms/hash'
            }
        });
    }
})();


(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('FormHelper', FormHelper);

    FormHelper.$inject = [
        '$sessionStorage',
        '$localStorage',
        '$q',
        '$filter',
        '$http',
        'Form'
    ];

    function FormHelper (
        $sessionStorage,
        $localStorage,
        $q,
        $filter,
        $http,
        Form
    ) {
        return {
            getFormWithExternalOptions: function(id) {
                var deferred = $q.defer();
                Form.get({id: id}, function(form) {
                    var itemsSelectWithExternalOptions = $filter('filter')(form.fieldSets[0].items, function(item) {
                        return (item.type == 'select' && item.externalURL != undefined && !(item.visible && (item.visible == "app" || item.visible == "none")));
                    });
                    var loopPromises = [];
                    angular.forEach(itemsSelectWithExternalOptions, function(item) {
                        var loopDeferred = $q.defer();
                        loopPromises.push(loopDeferred.promise);
                        var username;
                        if($localStorage.username == undefined){
                          username = $sessionStorage.username;
                        }else{
                          username = $localStorage.username;
                        }
                        $http.get(item.externalURL, {params: {username: username}}).then(function(result){
                          item.values = result.data;
                          loopDeferred.resolve();
                        }, function(error){
                          loopDeferred.resolve();
                        });
                    })
                    $q.all(loopPromises).then(function() {
                        deferred.resolve(form);
                    })
                });
                return deferred.promise;
            },
            getItemsThatCanBeFilter: function(form) {
                var deferred = $q.defer();
                deferred.resolve(
                    $filter('filter')(form.fieldSets[0].items, function(item) {
                        if (item.visible && (item.visible == "app" || item.visible == "none")) {
                            return false;
                        }
                        switch (item.type) {
                            case 'select':
                            case 'textarea':
                            case 'text':
                            case 'address':
                            case 'autoincremental':
                            case 'date':
                            case 'checkbox':
                                return true;
                            default:
                                return false;
                        }
                    })
                );
                return deferred.promise;
            },
            getItemsTypeSelect: function(form) {
                var deferred = $q.defer();
                deferred.resolve(
                    $filter('filter')(form.fieldSets[0].items, function(item) {
                        return (item.type == 'select' && !(item.visible && (item.visible == "app" || item.visible == "none")));
                    })
                );
                return deferred.promise;
            },
            getUsers: function(form) {
                var deferred = $q.defer();
                deferred.resolve(form.formUsers.map(function(formUser) { return formUser.user}));
                return deferred.promise;
            },
            getFormUserRelationOfUser: function(form, userId) {
                var deferred = $q.defer();
                angular.forEach(form.formUsers, function(formUser){
                    if (formUser.user.id == userId) {
                        deferred.resolve(formUser);
                    }
                });
                return deferred.promise;
            } 
        }
    }
})();

