(function() {
    'use strict';
    angular
        .module('pickerApp')
        .controller('OrganizationsController', OrganizationsController);

    OrganizationsController.$inject = ['$q', '$rootScope', '$scope', 'Organization', 'Principal', 'ModalHelper', 'organizations'];

    function OrganizationsController($q, $rootScope, $scope, Organization, Principal, ModalHelper, organizations) {

        var vm = this;
        vm.organizations = organizations;
        vm.themes = {default: "Por defecto", bright: "Brillante",dark: "Oscuro", green: "Verde", hilfiger: "Tommy Hilfiger"};
        vm.show = false;
        vm.isAuthenticated = null;
        vm.organizationId;
        vm.organizationName;
        vm.organizationBrand;
        vm.filters = {};

        $scope.$on('authenticationSuccess', function() {
            getAccount();
            getOrganizations();
        });

        vm.setFilters = function(){
          if(vm.organizationId != null && vm.organizationId != undefined && vm.organizationId != ''){
            vm.filters.id = vm.organizationId;
          }else{
            delete vm.filters.id;
          }
          if(vm.organizationName != null && vm.organizationName != undefined && vm.organizationName != ''){
            vm.filters.name = vm.organizationName;
          }else{
            delete vm.filters.name;
          }
          if(vm.organizationBrand != null && vm.organizationBrand != undefined && vm.organizationBrand != ''){
            vm.filters.brand = vm.organizationBrand;
          }else{
            delete vm.filters.brand;
          }
        }
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        vm.getOrganizations = function (){
          var deferred = $q.defer();
          $rootScope.showLoading("Buscando organiaciones...");
          vm.organizations = []
          vm.setFilters();
          Organization.query(vm.filters, function(response){
            vm.organizations = response;
            deferred.resolve();
            $rootScope.hideLoading();
          })
          return deferred.promise
        }

        vm.newOrganization = function(){
          var modalInstance = ModalHelper.open(
            'app/entities/organization/modal-organization-form.html',
            'ModalOrganizationForm',
            {
              organization: function () {
                return undefined;
              },
            });

          modalInstance.result.then(function (result) {
            if(result.status == "saved"){
              vm.getOrganizations();
            }
          }, function () {
            
          });  
        }
        
        vm.editOrganization = function(organization){
          var modalInstance = ModalHelper.open(
            'app/entities/organization/modal-organization-form.html',
            'ModalOrganizationForm',
            {
              organization: function () {
                return organization;
              },
            });

          modalInstance.result.then(function (result) {
            if(result.status == "saved"){
              vm.getOrganizations();
            }
          }, function () {
            
          });          
        }

        vm.deleteOrganization = function(form){
           var modalInstance = ModalHelper.confirmation("¿Está seguro de eliminar la organización?");

          modalInstance.result.then(function (confirmation) {
            if(confirmation){
              $rootScope.showLoading("Eliminando organización y sus dependencias...");
              Organization.delete({id: form.id}, {},function(response){
                $rootScope.hideLoading();
                vm.getOrganizations();
              });
            }
          }, function () {
            
          });
        }
      
        getAccount();
        $('select').select2({
          width: "100%"
        });
    }
})();
