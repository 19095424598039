(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('ChartSnapshot', ChartSnapshot);

    ChartSnapshot.$inject = ['$resource'];

    function ChartSnapshot ($resource) {
        var resourceUrl =  'api/chart-snapshots/:id';

        return $resource(resourceUrl, {}, {
            'save': { method: 'POST'}
        });
    }
})();
