(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('address',
        ['$q','NgMap', 'ModalHelper',function ($q, NgMap, ModalHelper) {
            return {
                /* Only use as <select-picker> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/components/item-template/address/address.html',

                    /* Attributes to set */    
                    scope: {
                        'value': '=', /* Selected value binding is mandatory */
                        'mapValue': '='
                    },

                    link: function (scope, element, attrs) {
                        scope.labelText = attrs.labelText;
                        scope.disabled = attrs.disabled;
                        if(attrs.notMap  == 'true' || scope.disabled){
                            scope.disabledSetAddressFromMarker = true;
                        }
 

                        scope.setAddressFromMarker = function(){
                            var latlng = new google.maps.LatLng({lat: parseFloat(scope.mapValue.split("|")[0]), lng: parseFloat(scope.mapValue.split("|")[1])});
                            var geocoder = new google.maps.Geocoder();
                            if(geocoder){
                                geocoder.geocode({ location: latlng }, function(results, status){
                                    if (status === "OK") {
                                        if (results[0]) {
                                            scope.value = results[0].formatted_address;
                                            scope.$apply();
                                        } else {
                                            ModalHelper.alert("No se pudo obtener la dirección");
                                        }
                                    } else {
                                        ModalHelper.alert("No se pudo obtener la dirección");
                                    }
                                });
                            }else{
                                ModalHelper.alert("No se pudo obtener la dirección");
                            }
                        }
                    }
                };
            }
        ]
    )
    }
)();