(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('chart',
        ['$rootScope', '$q', '$filter', 'PropertyUtil', 'ModalHelper', 'ChartSnapshot','ChartHelper', 
            function ($rootScope, $q, $filter, PropertyUtil, ModalHelper, ChartSnapshot, ChartHelper) {
            return {
                /* Only use as <chart> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/entities/chart/chart.html',

                    /* Attributes to set */    
                    scope: {
                        "chart": "=",
                        "index": "=",
                        "deleteChart": "=",
                        "duplicateChart": "=",
                        "editChart": "=",
                        "hideChart": "=",
                        "permissions": "="
                    },

                    link: function (scope, element, attrs) {
                        var calcTotalSize = function() {
                            $('.chart-container').each(function(index, el) {
                                var h1 = $(el).find('h1');
                                h1.css('font-size', el.offsetHeight+'px');
                                if ($(el).find('h1').width() > el.offsetWidth) {
                                    var resizeFactor = h1.width()/el.offsetWidth;                          
                                    h1.css('font-size', (el.offsetHeight/resizeFactor)+'px');
                                }
                            });
                        }

                        setTimeout(function(){
                            element.find("select").select2({width: "100%"});
                            calcTotalSize();
                        }, 500);


                        window.addEventListener('resize', function() {
                            calcTotalSize();
                        })


                        scope.chartOptions = {
                            tooltips: {
                                mode: 'point',
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                                labels: {
                                    fontColor: '#000',
                                    fontSize: 11
                                }
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            },
                            maintainAspectRatio: false,
                            responsive: true
                        }

                        ChartHelper.getNameOfFrequency(scope.chart.chartFrequencyId).then(function(name) {
                            scope.translatedFrequency = name;
                        });

                        ChartHelper.getCodeOfType(scope.chart.chartTypeId).then(function(code) {
                            scope.type = code;
                        })


                        scope.error = "";


                        function refreshChart() {
                            if (scope.chart.chartSnapshot && (scope.chart.chartTypeId == 1 || scope.chart.chartTypeId == 3)){
                                var labels = [];
                                var series = [];
                                var data = [];
                                var chartSnapshotValues = scope.chart.chartSnapshot.chartSnapshotValues;
                                for(var i = 0; i < chartSnapshotValues.length; i++) {
                                    var indexSerie = series.indexOf(chartSnapshotValues[i].serie)
                                    if (indexSerie == -1) {
                                        indexSerie = series.length;
                                        series.push(chartSnapshotValues[i].serie ? chartSnapshotValues[i].serie : 'Sin Valor');
                                    }
                                    var indexLabel = labels.indexOf(chartSnapshotValues[i].label);
                                    if (indexLabel == -1) {
                                        indexLabel = labels.length;
                                        labels.push(chartSnapshotValues[i].label);
                                    }
                                    if (!data[indexSerie]) {
                                        data[indexSerie] = [];
                                        data[indexSerie][indexLabel] = chartSnapshotValues[i].value
                                    } else {
                                        data[indexSerie][indexLabel] = chartSnapshotValues[i].value
                                    }
                                }
                                for (var i = 0; i < series.length; i++) {
                                   for(var j = 0; j < labels.length; j++) {
                                       if (!data[i][j]) {
                                           data[i][j] = 0;
                                       }  
                                   }
                                }
                                scope.chartSnapshot = {
                                   labels: labels,
                                   data: data,
                                   series: series
                                }
                                scope.chartOptions.scales = {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                }
                            } else if(scope.chart.chartSnapshot && (scope.chart.chartTypeId == 2 || scope.chart.chartTypeId == 4)) {
                                var labels = [];
                                var data = [];
                                var chartSnapshotValues = scope.chart.chartSnapshot.chartSnapshotValues;
                                for(var i = 0; i < chartSnapshotValues.length; i++) {
                                    var indexLabel = labels.indexOf(chartSnapshotValues[i].label);
                                    if (indexLabel == -1) {
                                        indexLabel = labels.length;
                                        labels.push(chartSnapshotValues[i].label);
                                    }
                                    data[indexLabel] = chartSnapshotValues[i].value
                                }
                                for(var j = 0; j < labels.length; j++) {
                                    if (!data[j]) {
                                       data[j] = 0;
                                    }  
                                }
                                scope.chartSnapshot = {
                                   labels: labels,
                                   data: data
                                }
                                delete scope.chartOptions.scales;
                            }
                            calcTotalSize();
                        }
                        
                        refreshChart();

                        
                       

                        scope.updateChartSnapshot = function(){
                            $rootScope.showLoading('Actualizando gráfico '+scope.chart.name);
                            ChartSnapshot.save({},{chartId: scope.chart.id}, function(response){
                                scope.chart.chartSnapshot = response;
                                refreshChart();
                                $rootScope.hideLoading();
                            }, function(error){
                                console.log('ERROR UPDATING CHART SNAPSHOT', error);
                                $rootScope.hideLoading();
                            })
                        }
                    }
                };
            }
        ]
    )
    }
)();