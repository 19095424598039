(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('DashboardsController', DashboardsController)

    DashboardsController.$inject = ['$rootScope', '$scope', '$filter', 'Principal', 'LoginService', 'Dashboard', 'Organization', '$state', 'ModalHelper','$q', '$timeout', '$http', '$localStorage', '$sessionStorage', 'dashboards']

    function DashboardsController ($rootScope, $scope, $filter, Principal, LoginService, Dashboard, Organization, $state, ModalHelper, $q, $timeout, $http, $localStorage, $sessionStorage, dashboards) {
        var vm = this;
        vm.show = false;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.dashboards = dashboards;
        vm.dashboard;
        vm.dashboardName;
        vm.dashboardDescription;
        vm.filters = {};

        $scope.$on('authenticationSuccess', function() {
            getAccount();
            getDashboards();
        });

        vm.setFilters = function(){
          if(vm.dashboardName != null && vm.dashboardName != undefined){
            vm.filters.name = vm.dashboardName;
          }else{
            delete vm.filters.name;
          }
          if(vm.dashboardDescription != null && vm.dashboardDescription != undefined){
            vm.filters.description = vm.dashboardDescription;
          }else{
            delete vm.filters.description;
          }
        }
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.filters.organizationId = account.organizationId;
            });
        }

        vm.getDashboards = function (){
          $rootScope.showLoading("Buscando dashboards...");
          vm.dashboards = []
          vm.setFilters();
          var deferred = $q.defer();
          Dashboard.query(vm.filters, function(response){
            vm.dashboards = response;
            deferred.resolve();
            $rootScope.hideLoading();
          })
          return deferred.promise
        }

        vm.newDashboard = function(){
          $state.go('dashboards.new');
        }

        vm.editDashboard = function(dashboard){
          $state.go('dashboards.edit', {dashboardId: dashboard.id});
        }

        vm.openDashboardView = function(dashboard) {
          $state.go('dashboard-view', { dashboardId: dashboard.id});
        }

        vm.deleteDashboard = function(dashboard){
           var modalInstance = ModalHelper.confirmation("¿Está seguro de eliminar el dashboard?");

          modalInstance.result.then(function (confirmation) {
            if(confirmation){
              $rootScope.showLoading("Eliminando dashboard y sus dependencias...");
              Dashboard.delete({id: dashboard.id}, {},function(response) {
                $state.go("dashboards", {}, {reload: true});
                $rootScope.hideLoading();
              });
            }
          }, function () {
            
          });
        }
      
        getAccount();
        $('select').select2({
          width: "100%"
        });
    }
})();