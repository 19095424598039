(function(){
    'use strict';

    angular
        .module('pickerApp')
        .factory('SecurityService', SecurityService);
    
    SecurityService.$inject = ['$q', '$rootScope','$filter', 'FormUser'];

    function SecurityService ($q,$rootScope, $filter, FormUser) {

        var service = {
            getPermissions : getPermissions
        };

        return service;

        function getPermissions(form) {
            var deferred = $q.defer();
            if($rootScope.user && form){
                FormUser.getByFormAndUser({ formId: form.id, userId: $rootScope.user.id }, function(formUser) {
                    var permissions = {}
                    angular.forEach(formUser.formUserPermissions, function(formUserPermission){
                        permissions[formUserPermission.permission.code] = formUserPermission.value;
                    });
                    deferred.resolve(permissions);
                })
            }else{
                deferred.resolve(null);
            }
            return deferred.promise;
        }
    }
})();
