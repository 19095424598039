(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('Chart', Chart);

    Chart.$inject = ['$resource', 'DateUtils'];

    function Chart ($resource, DateUtils) {
        var resourceUrl =  'api/charts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    for(var i = 0; i < data.length; i++){
                        data[i].hidden = false;
                        data[i] = DateUtils.recurseObject(data[i]);
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': { method: 'POST', id: '@id', form_records: '@params' },
            'update': { method:'PUT' },
            'delete': { method: 'DELETE', id: '@id'}
        });
    }
})();
