(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('filePicker',
        ['$q', 'Upload', 'FileHelper', function ($q, Upload, FileHelper) {
            return {
                /* Only use as <select-picker> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/components/item-template/file-picker/file-picker.html',

                    /* Attributes to set */    
                    scope: {
                        'value': '=', /* Selected value binding is mandatory */
                        'files': '='
                    },

                    link: function (scope, element, attrs) {
                        scope.labelText = attrs.labelText;
                        scope.disabled = attrs.disabled;
                        scope.itemId = attrs.itemId;
                        scope.fileType = attrs.fileType;
                        scope.min = parseInt(attrs.min);
                        scope.max = parseInt(attrs.max);
                        scope.amountOfFiles = Array.from(Array(parseInt(attrs.fileQuantity)).keys());

                        scope.setFile = function(rawFile, itemId, index, invalidFiles) {
                            try {
                                FileHelper.setFile(rawFile, itemId, index, undefined, scope.files, scope.fileType, invalidFiles).then(function(value) {
                                    scope.value = value;
                                });
                            } catch (err) {
                                scope.deleteFile(itemId, index);
                            }
                        }

                        scope.deleteFile = function(itemId, index) {
                            scope.value = FileHelper.deleteFile(itemId, index, scope.files);
                        }
              
                        scope.openFileSelector = function(id) {
                            FileHelper.openFileSelector(id);
                        }

                        scope.downloadFile = function(itemId, i) {
                            if (scope.files[itemId][i].file) {
                                var a = document.createElement("a"); //Create <a>
                                var prefix = "data:" + scope.files[itemId][i].fileContentType + ";base64,";
                                a.href = prefix + scope.files[itemId][i].file; //Image Base64 Goes here
                                a.download = scope.files[itemId][i].fileName; //File name Here
                                a.click(); //Downloaded file
                            }
                        }
                    }
                };
            }
        ]
    )
    }
)();