(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('SignInController', SignInController);

    SignInController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'VERSION'];

    function SignInController ($rootScope, $state, $timeout, Auth, VERSION) {
        var vm = this;

        vm.authenticationError = false;
        vm.credentials = {};
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;
        vm.version = VERSION.replace('-SNAPSHOT', '');

        $timeout(function (){angular.element('#username').focus();});

        vm.login = function() {
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;              
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset' || $state.current.name === 'sign_in') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

    }
})();
