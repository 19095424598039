(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = [
        '$scope',
        '$timeout',
        '$rootScope','$stateParams',
        '$uibModalInstance',
        '$filter',
        'entity',
        'User',
        'JhiLanguageService',
        'Principal',
        'PermissionHelper',
        'Organization',
        'ModalHelper'
    ];

    function UserManagementDialogController (
        $scope,
        $timeout,
        $rootScope,
        $stateParams,
        $uibModalInstance,
        $filter,
        entity,
        User,
        JhiLanguageService,
        Principal,
        PermissionHelper,
        Organization,
        ModalHelper
    ) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.forms = {};
        vm.organizationId;
        if(entity.$promise != undefined){
            entity.$promise.then(function(user){
                vm.user = user;
                angular.forEach(vm.user.userForms, function(userForm, i){
                    var permissions = [];
                    angular.forEach(userForm.formUserPermissions, function(formUserPermission){
                        if(formUserPermission.value){
                            permissions.push(formUserPermission.permission.id);
                        }
                        userForm.permissions = angular.copy(permissions);
                    })
                    delete userForm.formUserPermissions;   
                })
                setActualOrganizationOrGetAll();
            })
        }else{
            vm.user = entity;
            setActualOrganizationOrGetAll();
        }

        function setActualOrganizationOrGetAll() {
            Principal.identity().then(function(user){
                if ($filter('filter')(user.authorities, 'ROLE_SUPER_ADMIN').length > 0) {
                    Organization.query({}, function(response) {
                        vm.organizationId = vm.user.organizationId;
                        vm.organizations = response;
                        if (vm.user.organizationId) {
                            Organization.getForms({id: vm.user.organizationId}, function(forms) {
                                vm.allFormsAvailable = forms;
                            })   
                        }
                    }, function(error) {
                        console.log(error);
                    })
                } else {
                    vm.organizationId = user.organizationId;
                    Organization.getForms({id: user.organizationId}, function(forms) {
                        vm.allFormsAvailable = forms;
                    })
                }
            })
        }

        PermissionHelper.getFormPermissions().then(
            function(response){
                vm.permissions = response;
            }
        );


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.addFormUser = function(){
            if(vm.user.userForms){
                vm.user.userForms.push({user: vm.user, id: null, formId: null, permit: null, permissions: vm.permissions.map(function(permission){return permission.id;})})
            }else{
                vm.user.userForms = [];
                vm.user.userForms.push({user: vm.user, id: null, formId: null, permit: null, permissions: vm.permissions.map(function(permission){return permission.id;})})
            }
            updateListForms();
            if(vm.user.userForms.length == vm.allFormsAvailable.length){
                vm.hideAddFormUser = true;
            }
            $timeout(function(){
                $("select").select2({
                    width: "100%"
                });
            },100) 
        }

        vm.changeSelectForm = function(){
            updateListForms();
        }

        vm.removeUserForm = function(index){
            vm.user.userForms.splice(index, 1);
            updateListForms();
            vm.hideAddFormUser = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.password !== vm.user.confirmPassword && vm.user.password && vm.user.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
                vm.isSaving = false;
            }else{
                angular.forEach(vm.user.userForms, function(userForm){
                    userForm.formUserPermissions = [];
                    angular.forEach(vm.permissions, function(permission){
                        var formUserPermission = {};
                        formUserPermission.permission = permission;
                        formUserPermission.value = userForm.permissions.includes(permission.id);
                        userForm.formUserPermissions.push(formUserPermission);
                    })
                    userForm.userId = userForm.user.id
                    delete userForm.user; 
                    delete userForm.permissions; 
                })
                vm.user.organizationId = vm.organizationId;
                if (vm.user.id !== null) {
                    User.update(vm.user, onSaveSuccess, onSaveError);
                } else {
                    User.save(vm.user, onSaveSuccess, onSaveError);
                }
            }
        }

        $scope.$watch('$viewContentLoaded', function(){
            $timeout(function() {
                if(vm.allFormsAvailable.length == vm.user.userForms.length){
                    vm.hideAddFormUser = true;
                }       
                updateListForms();
            }, 3000);
        });

        function updateListForms(){
            var formIds = []
            angular.forEach(vm.user.userForms, function(userForm){
                formIds.push(userForm.formId);
            })
            angular.forEach(vm.user.userForms, function(userForm, i){
                vm.forms[i] = $filter('filter')(vm.allFormsAvailable, function(value, index, array){
                    return !formIds.includes(value.id) || userForm.formId == value.id;
                });
                var permissions = [];
                angular.forEach(userForm.formUserPermissions, function(formUserPermission){
                    if(formUserPermission.value){
                        permissions.push(formUserPermission.permission.id);
                    }
                    userForm.permissions = angular.copy(permissions);
                })
                delete userForm.formUserPermissions;   
            })
        }

        $scope.onOrganizationChange = function() {
            var modalInstance = ModalHelper.confirmation("Modificar la organización, borrará todas los formularios asignados, ¿Desea continuar?");

            modalInstance.result.then(function (confirmation) {
                if(confirmation){
                    vm.user.organizationId = vm.organizationId;
                    Organization.getForms({id: vm.user.organizationId}, function(forms) {
                        vm.allFormsAvailable = forms;
                        vm.user.userForms = [];
                        updateListForms();
                    })                    
                } else {                      
                    vm.organizationId = undefined;
                    $timeout(function() {
                        vm.organizationId = vm.user.organizationId;    
                    }, 100);
                }
            }, function () {
                
            });
        }

        vm.setAllPermissions = function(userForm) {
            userForm.permissions = vm.permissions.map(function(p) { return p.id});
            vm.updateSelects();
        };
        vm.removeAllPermissions = function(userForm) {
            userForm.permissions = [];
            vm.updateSelects();
        };

        vm.updateSelects = function(){
            $timeout(function(){
                $("select").select2({
                    width: "100%"
                });    
            }, 100);
        }

        vm.generateApiKey = function() {
            User.setApiKey({id: vm.user.id }, function(response) {
                vm.user.apiKey = response.apiKey
            });
        }

        vm.deleteApiKey = function() {
            User.deleteApiKey({ id: vm.user.id }, function(response) {
                vm.user.apiKey = undefined;
            })
        }

        vm.copyApiKey = function() {
           navigator.clipboard.writeText(vm.user.apiKey);
           vm.showCopiedApiKey = true;
           $timeout(function() {
               vm.showCopiedApiKey = false;
           }, 3000)
        }

        $scope.$watch("vm.forms", function(newVal){
            $timeout(function(){
                $("select").select2({
                    width: "100%"
                });
            },500) 
        }, true)
    }
})();
