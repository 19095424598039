(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('FormProperty', FormProperty);

    FormProperty.$inject = ['$resource'];

    function FormProperty ($resource) {
        var resourceUrl =  'api/form-properties/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {method: 'GET'}
        });
    }
})();
