(function() {
  'use strict';

  angular
      .module('pickerApp')
      .controller('DashboardFormController', DashboardFormController);

  DashboardFormController.$inject = [
    '$rootScope',
    '$state',
    '$scope',
    '$timeout',
    '$filter',
    '$q',
    'dashboard' ,
    'Dashboard',
    'PermissionHelper',
    'User',
    'Principal',
    'ModalHelper'
  ];

  function DashboardFormController(
    $rootScope,
    $state,
    $scope,
    $timeout,
    $filter,
    $q,
    dashboard,
    Dashboard,
    PermissionHelper,
    User,
    Principal,
    ModalHelper
  ) {
      var vm = this;
      var promisePermissionHelper = PermissionHelper.getDashboardPermissions();

      User.getAll({}, function(response) {
        vm.users = response;
      });

      function fillForm() {
        Principal.identity().then(function(account) {
          vm.dashboard = dashboard ? angular.copy(dashboard) : { organizationId: account.organizationId };
          parseDashboardFromBackend();
        })
      }

      var parseDashboardFromBackend = function() {
        promisePermissionHelper.then(function(response) {
          vm.permissions = response.map(function(permission) {
            return { value: { permissionId: permission.id }, name: permission.name }
          });
          if (dashboard) {
            vm.dashboard.dashboardUsers = dashboard.dashboardUsers.map(function(du) {
              var dup = du.dashboardUserPermissions.map(function(dup_) {
                var permission = $filter('filter')(vm.permissions, function(p) {
                  return p.value.permissionId === dup_.permissionId;
                })[0];
                return permission.value;
              });
              return {userId: du.user.id, dashboardUserPermissions: dup};
            });
          }
        });
      }

      vm.save = function(){
        if (isValid()) {
          if (dashboard) {
            $rootScope.showLoading("Actualizando dashboard...");
            Dashboard.update({}, vm.dashboard, function(response) {
              $rootScope.hideLoading();
              vm.back(true);
            }, function(error) {
              $rootScope.hideLoading();
            })
          } else {
            $rootScope.showLoading("Guardando dashboard...");
            Dashboard.save({}, vm.dashboard, function(response) {
              $rootScope.hideLoading();
              vm.back(true);
            }, function(error) {
              $rootScope.hideLoading();
            })
          }
        } else {
          ModalHelper.error('Uno más campo posee errores, revise e intente nuevamente');
        }
      }

      fillForm();

      vm.back = function(saved){
        if(saved){
          $state.go("dashboards", {}, {reload: true});
        }else{
          $state.go("dashboards");
        }
      }

      vm.removeDashboardUser = function(index) {
        vm.dashboard.dashboardUsers.splice(index, 1);
      }

      vm.addDashboardUser = function() {
        if (!vm.dashboard.dashboardUsers) {
          vm.dashboard.dashboardUsers = [];
        }
        vm.dashboard.dashboardUsers.push({});
        updateSelects();
      }

      vm.onUserChange = function() {
        var usersIds = vm.dashboard.dashboardUsers.map(function(du) { return du.userId });
        vm.users.forEach(function(u) {
          u.disabled = usersIds.includes(u.id);
        });
      }

      vm.setAllPermissions = function(dashboardUser) {
        var permissions = vm.permissions.map(function(permission) {
          return permission.value;
        });
        dashboardUser.dashboardUserPermissions = permissions;
        updateSelects();
      }

      vm.removeAllPermissions = function(dashboardUser) {
        dashboardUser.dashboardUserPermissions = [];
        updateSelects();
      }

      function isValid() {
        var valid = true;
        valid = vm.dashboard.name && valid;
        if (vm.dashboard.dashboardUsers && vm.dashboard.dashboardUsers.length > 0) {
          angular.forEach(vm.dashboard.dashboardUsers, function(du) {
            valid = du.userId && valid;
            valid = du.dashboardUserPermissions && valid;
          })
        }
        return valid;
      }

      function updateSelects() {
        $timeout(function() {
          $("select").select2({width: "100%"});
        }, 200);
      }


      updateSelects();

  }

})();