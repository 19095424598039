(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('FormRecord', FormRecord);

    FormRecord.$inject = ['$resource', 'DateUtils'];

    function FormRecord ($resource, DateUtils) {
        var resourceUrl =  'api/form-records/:id';


        return $resource(resourceUrl, {}, {
            'query': { 
                url: 'api/form-records2', 
                method: 'GET', 
                isArray: true, 
                transformResponse: function(data){
                    data = angular.fromJson(data);
                    for(var i = 0; i< data.length; i++){
                        data[i] = DateUtils.recurseObject(data[i]);
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data = DateUtils.recurseObject(data);
                    }
                    return data;
                }
            },
            'save': { method: 'POST', id: '@id', form_records: '@params' },
            'update': { method:'PUT' },
            'delete': { method: 'DELETE', id: '@id'},
            'deleteByIds': { url: 'api/form-records/delete-selected',  method: 'POST'},
            'getLast': {url: 'api/form-records/last',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data = DateUtils.recurseObject(data);
                    }
                    return data;
                }
            },
            'assign': { url: 'api/form-records/assign', method: 'POST', timeout: 60000},
            'unassign': { url: 'api/form-records/unassign', method: 'POST'}
        });
    }
})();