(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('tabMap',
        ['$q',function ($q) {
            return {
                /* Only use as <tab-map> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/entities/form-record-searcher/tab-map/tab-map.html',

                controller: 'TabMapController',
                    /* Attributes to set */    
                scope: {
                    'picks': '=',
                    'forms': '=',
                    'form': '=',
                    'actions': '=',
                    'users': '=',
                    'onUpdate': '=',
                    'addMapFilter': '=',
                    'polygonPath' : '=',
                    'permissions': '='
                }
            };
        }
        ]
    )
    }
)();