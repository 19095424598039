(function() {
    'use strict';

    angular
        .module('pickerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('actions', {
            parent: 'entity',
            url: '/actions',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/action/actions.html',
                    controller: 'ActionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                forms: ['$rootScope',function($rootScope){
                    return $rootScope.forms
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('action');
                    return $translate.refresh();
                }]
            }
        }).state('actions.new', {
            url: '/{formOriginId}/new',
            data: {
                authorities: ['ROLE_ADMIN']  
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/action/action-form.html',
                    controller: 'ActionFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                formOrigin: ['$q', 'FormHelper', '$stateParams', function($q, FormHelper, $stateParams){
                    var deferred = $q.defer();
                    FormHelper.getFormWithExternalOptions($stateParams.formOriginId).then(function(response){
                        deferred.resolve(response);
                    });
                    return deferred.promise;
                }],
                action: function(){
                    return undefined;
                }
            }
        }).state('actions.edit', {
            url: '/{formOriginId}/{actionId}',
            data: {
                authorities: ['ROLE_ADMIN']  
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/action/action-form.html',
                    controller: 'ActionFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                formOrigin: ['$q','FormHelper', '$stateParams', function($q, FormHelper, $stateParams){
                    var deferred = $q.defer();
                    FormHelper.getFormWithExternalOptions($stateParams.formOriginId).then(function(response){
                        deferred.resolve(response);
                    });
                    return deferred.promise;
                }],
                action: ['$q','Action', '$stateParams', function($q, Action, $stateParams){
                    var deferred = $q.defer();
                    Action.get({id: $stateParams.actionId}, function(action){
                        deferred.resolve(action);
                    })
                    return deferred.promise;
                }]
            }
        }).state('actions.copy', {
            url: '/{formOriginId}/{actionId}/copy',
            data: {
                authorities: ['ROLE_ADMIN']  
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/action/action-form.html',
                    controller: 'ActionFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                formOrigin: ['$q','FormHelper', '$stateParams', function($q, FormHelper, $stateParams){
                    var deferred = $q.defer();
                    FormHelper.getFormWithExternalOptions($stateParams.formOriginId).then(function(response){
                        deferred.resolve(response);
                    });
                    return deferred.promise;
                }],
                action: ['$q','Action', '$stateParams', function($q, Action, $stateParams){
                    var deferred = $q.defer();
                    Action.get({id: $stateParams.actionId}, function(action){
                        delete action.id;
                        delete action.name;
                        delete action.description;
                        if (action.detailActions) {
                            angular.forEach(action.detailActions, function(e) {
                                delete e.id;
                            });

                        }
                        if (action.actionUsers) {
                            angular.forEach(action.actionUsers, function(e) {
                                delete e.id;
                            });
                        }
                        if (action.actionExecuteConditions) {
                            angular.forEach(action.actionExecuteConditions, function(e) {
                                delete e.id;
                            });
                        }

                        if (action.actionUpdateMapFinds) {
                            angular.forEach(action.actionUpdateMapFinds, function(e) {
                                delete e.id;
                            });
                        }
                        if (action.actionAssignConditions) {
                            angular.forEach(action.actionAssignConditions, function(e) {
                                delete e.id;
                                delete e.actionId;
                            });
                        }
                        if (action.actionPermitExecutions) {
                            angular.forEach(action.actionPermitExecutions, function(e) {
                                delete e.id;
                            });
                        }
                        deferred.resolve(action);
                    })
                    return deferred.promise;
                }]
            }
        });
    }

})();
