(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('ItemRecord', ItemRecord);

    ItemRecord.$inject = ['$resource'];

    function ItemRecord ($resource) {
        var resourceUrl =  'api/item-records/:id';

        return $resource(resourceUrl, {}, {
            'getDocumentRecords': {
                method: 'GET',
                url: resourceUrl + "/documents",
                isArray: true
            },
            'deleteDocumentRecord': {
                method: 'DELETE',
                url: resourceUrl + "/documents/:idDocument"
            }
        });
    }
})();