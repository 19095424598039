(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('FormController', FormController)

    FormController.$inject = ['$rootScope', '$scope', '$filter', 'Principal', 'LoginService', 'Form', 'Organization', '$state', 'ModalHelper','$q', '$timeout', '$http', '$localStorage', '$sessionStorage', 'forms']

    function FormController ($rootScope, $scope, $filter, Principal, LoginService, Form, Organization, $state, ModalHelper, $q, $timeout, $http, $localStorage, $sessionStorage, forms) {
        var vm = this;
        vm.show = false;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.forms = forms;
        vm.form;
        vm.formId;
        vm.formName;
        vm.formDescription;
        vm.filters = {};

        $scope.$on('authenticationSuccess', function() {
            getAccount();
            getForms();
        });

        vm.setFilters = function(){
          if(vm.formId != null && vm.formId != undefined){
            vm.filters.formId = vm.formId;
          }else{
            delete vm.filters.formId;
          }
          if(vm.formName != null && vm.formName != undefined){
            vm.filters.name = vm.formName;
          }else{
            delete vm.filters.name;
          }
          if(vm.formDescription != null && vm.formDescription != undefined){
            vm.filters.description = vm.formDescription;
          }else{
            delete vm.filters.description;
          }
        }
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        vm.getForms = function (){
          $rootScope.showLoading("Buscando formularios...");
          vm.forms = []
          vm.setFilters();
          var deferred = $q.defer();
          vm.filters.id = vm.account.organizationId;
          Organization.getForms(vm.filters, function(response){
            vm.forms = response;
            deferred.resolve();
            $rootScope.hideLoading();
          })
          return deferred.promise
        }

        vm.newForm = function(){
          $state.go('forms.new');
        }

        vm.copyForm = function(form){
          $state.go('forms.copy', {formId: form.id});
        }

        vm.editForm = function(form){
          $state.go('forms.edit', {formId: form.id});
        }

        vm.deleteForm = function(form){
           var modalInstance = ModalHelper.confirmation("¿Está seguro de eliminar el formulario?");

          modalInstance.result.then(function (confirmation) {
            if(confirmation){
              $rootScope.showLoading("Eliminando formulario y sus dependencias...");
              Form.delete({id: form.id}, {},function(response){
                $rootScope.hideLoading();
                vm.getForms();
              });
            }
          }, function () {
            
          });
        }
      
        getAccount();
        $('select').select2({
          width: "100%"
        });
    }
})();