(function() {
    'use strict';

    angular
        .module('pickerApp')
        .factory('ItemTemplateService', ItemTemplateService);

    ItemTemplateService.$inject = [];

    function ItemTemplateService() {

        var service = {
            getTemplate: getTemplate
        };

        return service;

        function getTemplate(item, form, action, formRecords){
          var field = {};
          field.id = item.id;
          field.content = "<div>";
          field.type = item.subtype || item.type;
          field.label = item.required ? item.label + " *": item.label;
          field.disabled = item.disabled == "web" || item.disabled == "both" ? " disabled=true": "";
          field.visible = item.visible;
          field.model = "ng-model=vm.itemRecords["+field.id+"]";
          if(item.parentItemId != undefined){
            angular.forEach(form.fieldSets[0].items, function(item_){
              if(item_.name == item.parentItemId){
                field.parentItemId = item_.id;
                return;
              }
            });
          }
          if(item.type == "address"){
            angular.forEach(form.fieldSets[0].items, function(item_){
              if(item_.type == "map"){
                field.mapValue = "map-value='vm.itemRecords["+item_.id+"]'";
                return;
              }
            });
          }
          switch(field.type){
            case "h1":
              var marginTop = item.marginTop ? item.marginTop : '10';
              var marginBottom = item.marginBottom ? item.marginBottom : '10';
              field.content += "<h2 class='field' style='margin-top: "+marginTop+"px; margin-bottom: "+marginBottom+"px;'>"+field.label+"</h2>";
              break;
            case "h2":
              var marginTop = item.marginTop ? item.marginTop : '10';
              var marginBottom = item.marginBottom ? item.marginBottom : '10';
              field.content += "<h3 class='field' style='margin-top: "+marginTop+"px; margin-bottom: "+marginBottom+"px;'>"+field.label+"</h3>";
              break;
            case "h3":
              var marginTop = item.marginTop ? item.marginTop : '10';
              var marginBottom = item.marginBottom ? item.marginBottom : '10';
              field.content += "<h4 class='field' style='margin-top: "+marginTop+"px; margin-bottom: "+marginBottom+"px;'>"+field.label+"</h4>";
              break;
            case "text":
              field.content += "<label class='control-label'>"+field.label+"</label>";
              field.content += "<input "+field.model+" type='text' maxlength='255' class='form-control' "+field.disabled+">";
              break;
            case "textarea":
              field.content += "<label class='control-label'>"+field.label+"</label>";
              field.content += "<textarea "+field.model+".extraItemRecord.longValue type='text' class='form-control' "+field.disabled+"></textarea>";
              break;
            case "autoincremental":
              field.content += "<label class='control-label'>"+field.label+"</label>";
              field.content += "<input "+field.model+" type='number' class='form-control' "+field.disabled+">";
              break;
            case "image":
              field.content += '<image-picker image-quantity="'+item.value+'" label-text="'+field.label+'" disabled="'+field.disabled+'" value="vm.itemRecords['+field.id+']" item-id="'+item.id+'" files="vm.files"></image-picker>';
              break;
            case "file":
              field.content += '<file-picker min='+item.min+' max='+item.max+' file-type="'+item.fileType+'" file-quantity="'+item.value+'" label-text="'+field.label+'" disabled="'+field.disabled+'" value="vm.itemRecords['+field.id+']" item-id="'+item.id+'" files="vm.files"></file-picker>';
              break;
            case "drawing":
              field.content += '<drawing-picker label-text="'+field.label+'" item-id='+item.id+' disabled'+field.disabled+' value="vm.itemRecords['+field.id+']" files="vm.files"></drawing-picker>';
              break;
            case "number":
              field.content += "<label class='control-label'>"+field.label+"</label>";
              field.content += "<input "+field.model+" type='number' class='form-control' "+field.disabled+">";
              break;
            case "date":
              field.content += "<label class='control-label'>"+field.label+"</label>";
              field.content += "<input "+field.model+" type='date' class='form-control' "+field.disabled+">";
              break;
            case "select":
                var options = item.values.length>0 ? JSON.stringify(item.values).replace(/[\/\(\)\']/g, "&apos;") : undefined;
                field.content += "<select-picker label-text='"+field.label+"' value='vm.itemRecords["+field.id+"]' item-parent='"+field.parentItemId+"' options='"+options+"' parent-value='vm.itemRecords["+field.parentItemId+"]' "+field.disabled+"></select-picker>";
              break;
            case "map":
              if(action != undefined){
                field.content += "<position-picker-action item-id='" + item.id + "' form-records='"+JSON.stringify(formRecords)+"' "+field.disabled+"></position-picker-action>";
                break;
              }else{
                field.content += "<position-picker name-attr='" + item.name + "' model='vm.itemRecords["+field.id+"]' read-only='" + item.readOnly + "' "+field.disabled+"></position-picker>";
                break;  
              }
            case "address":
              field.mapValue = field.mapValue ? field.mapValue : "not-map='true'";
              field.content += "<address label-text='" + field.label + "' value='vm.itemRecords["+field.id+"]' "+field.mapValue+" "+field.disabled+"></address>";
                break;
            case "checkbox":
              field.content += "<input type='checkbox' class='custom-control-input' style='margin-right: 4px'"+field.model+" ng-model-options='{ allowInvalid: true }'>"+field.label.replace(" *", "");
              break;
            case "button":
              field.content +='<button '+field.model+' type="button" class="btn btn-success" '+field.disabled+'>'+field.label+'</button>';
              break;
          }
          field.content = field.content + "<div ng-bind-html='vm.errors["+field.id+"]'></div></div>";
          return field;
        }
    }

})();
