(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('positionPickerAction',
  [
    '$q','$timeout', '$filter', 'NgMap',
    function ($q, $timeout, $filter, NgMap) {
      return {
        /* Only use as <select-picker> tag */
        restrict: 'E',

        /* Our template */
        templateUrl: 'app/components/item-template/position-picker/position-picker-masive-edit.html',
        /* Attributes to set */
        scope: {
          'itemId': '=',
          'disabled': '='
        },

        link: function (scope, element, attrs) {
          
          scope.formRecords = JSON.parse(attrs.formRecords);
          ///$timeout(function() {
            NgMap.getMap({id: "form"}).then(function(map){     
              var markers = [];
              var loopPromises = []
              angular.forEach(scope.formRecords, function(formRecord){
                var loopDeferred = $q.defer();
                loopPromises.push(loopDeferred.promise);
                var position = $filter('filter')(formRecord.fieldSetRecords[0].itemRecords, {itemId: scope.itemId})[0].value.split('|');
                position[0] = parseFloat(position[0])
                position[1] = parseFloat(position[1])
                var marker = new google.maps.Marker({
                  position: {lat: position[0], lng: position[1]},
                  draggable: false,
                  map: map,
                  icon: "content/images/blue-marker.png"
                });
                markers.push(marker);
                loopDeferred.resolve();
              })
              $q.all(loopPromises).then(function(){
                var bounds = new google.maps.LatLngBounds();
                for (var i = 0; i < markers.length; i++) {
                  bounds.extend(markers[i].position);
                }
                map.fitBounds(bounds);
              })
            });
          //}, 2000);
        
      
        }
      };
    }
  ]
);
})();