(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('ChartType', ChartType);

    ChartType.$inject = ['$resource'];

    function ChartType ($resource) {
        var resourceUrl =  'api/chart-types/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
