(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('ModalOrganizationForm', ModalOrganizationForm);

    ModalOrganizationForm.$inject = ['$rootScope', '$uibModalInstance','organization', 'Upload', 'Organization'];

    function ModalOrganizationForm ($rootScope, $uibModalInstace, organization, Upload, Organization) {
        var vm = this;
	    vm.logo = {ngFile: undefined};
	    vm.organization = organization === undefined ? { apiKeyEnabled: false } : angular.copy(organization);
        vm.errors = {}

        vm.themes = [{label: "Por defecto", value: "default"}, {label: "Brillante", value: "bright"},  {label: "Oscuro", value: "dark"},  {label: "Verde", value: "green"} ,{label: "Tommy Hilfiger", value: "hilfiger"}];
        vm.apiKeyStatus = [{ label: "Activado", value: true}, { label: "Desactivado", value: false }];

        vm.setImage = function(file) {
          vm.logo.ngFile = file;
          Upload.base64DataUrl(file).then(function(url){
            vm.organization.logo = url.split(',')[1];
          })
        }

        function isValid(){
            if(!vm.organization.name){
                vm.errors.name = true;
            }else{
                delete vm.errors.name;
            }
            if (!vm.organization.gmapsApiKey) {
                vm.errors.gmapsApiKey = true;
            } else {
                delete vm.errors.gmapsApiKey;
            }
            if (vm.organization.apiKeyEnabled == undefined) {
                vm.errors.apiKeyEnabled = true;
            } else {
                delete vm.errors.apiKeyEnabled;
            }
            if(Object.keys(vm.errors).length > 0) {
                return false;
            } else {
                return true;
            }
        }

        vm.zoomInOut = function(event){
          if(angular.element(event.currentTarget).hasClass("img-large")){
            angular.element(event.currentTarget).removeClass("img-large");
          }else{
            angular.element(event.currentTarget).addClass("img-large");
          }
        }

        vm.submit = function(){
            if(isValid()){
                $rootScope.showLoading('Guardando...');
                if (organization && organization.id) {
                    Organization.update({}, vm.organization, function(response){
                      $rootScope.hideLoading();
                      $uibModalInstace.close({status: "saved", organization: response});
                    }, function(error){
                        console.log(error);
                        $rootScope.hideLoading();
                    });     
                } else {
                    Organization.save({}, vm.organization, function(response){
                        $rootScope.hideLoading();
                        $uibModalInstace.close({status: "saved", organization: response})
                    }, function(error){
                        console.log(error);
                        $rootScope.hideLoading();
                    });
                }
            }
        }

        vm.cancel = function(){
            $uibModalInstace.close("cancel");
        }


        function dataURLtoFile(file) {
            var bstr = atob(file), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            
            return new File([u8arr], "temp", {type:'image/jpeg'});
        }

        if (organization) {
            vm.setImage(dataURLtoFile(vm.organization.logo));    
        }
	}
})();