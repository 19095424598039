(function() {
  'use strict';

  angular
      .module('pickerApp')
      .controller('ModalActionAssignConditionEditorCtrl', ModalActionAssignConditionEditorCtrl);

  ModalActionAssignConditionEditorCtrl.$inject = [
    '$timeout',
    '$http',
    '$localStorage',
    '$filter',
    '$uibModalInstance',
    '$q',
    '$rootScope',
    '$scope',
    'items',
    'actionUsers',
    'actionAssignConditions',
    'FormHelper'
  ];

  function ModalActionAssignConditionEditorCtrl (
    $timeout,
    $http,
    $localStorage,
    $filter,
    $uibModalInstance,
    $q,
    $rootScope,
    $scope,
    items,
    actionUsers,
    actionAssignConditions,
    FormHelper
  ) {
      var vm = this;
      vm.items = items;
      vm.users = actionUsers;
      if (actionAssignConditions) {
        vm.asSubForm = $filter('filter')(actionAssignConditions, function(actionAssignCondition) {
            return actionAssignCondition.subitemId != undefined && actionAssignCondition.subitemId != null 
          }).length > 0;
        vm.actionAssignConditions = angular.copy(actionAssignConditions);
      }
      
      if(vm.actionAssignConditions == undefined || vm.actionAssignConditions == null || vm.actionAssignConditions.length == 0){
        vm.actionAssignConditions = [];
      }else{
        initialize();
      }

      function internalOptionsLoader() {
        var username = $localStorage.username;
        $rootScope.showLoading('Cargando opciones del campo...');
        $http.get(vm.item.externalURL, {params: {username: username}}).then(function(result){
          vm.item.values = result.data;
          $rootScope.hideLoading();
          createSelect2();
        }, function(error){
          item.failed = true;
        });
      }

      function initialize(){
        var subFormId;
        angular.forEach(vm.actionAssignConditions, function(actionAssignCondition){
          vm.item = $filter('filter')(vm.items, {id: actionAssignCondition.itemId})[0];
          if(vm.item.externalURL && vm.item.externalURL.includes('internal-options')){
            var query = vm.item.externalURL.split('\?')[1];
            var params = query.split('\&');
            angular.forEach(params, function(param){
              var key = param.split("=")[0];
              var value = param.split("=")[1]
              if(key == "formId" ){
                subFormId = value;
              }
            })
          }
        })

        if (subFormId && vm.asSubForm) {
          FormHelper.getFormWithExternalOptions(subFormId).then(function(form) {
            vm.formInternalOptions = form;
            FormHelper.getItemsTypeSelect(vm.formInternalOptions).then(function(items) {
              vm.formInternalOptions.fieldSets[0].items = angular.copy(items);
              angular.forEach(vm.actionAssignConditions, function(actionAssignCondition) {
                vm.subitem = $filter('filter')(vm.formInternalOptions.fieldSets[0].items, { id: actionAssignCondition.subitemId })[0];
              });
              createSelect2();
            });
          });
        } else if (subFormId && !vm.asSubForm) {
          internalOptionsLoader();
        }
      }

      vm.addActionAssignCondition = function(){
        vm.actionAssignConditions.push({
          itemId: vm.item.id, 
          subitemId: vm.subitem ? vm.subitem.id : undefined,
          userToAssignId: undefined,
          value: undefined,
          operator: "equals"
        });
        createSelect2();
      }

      vm.removeActionAssignCondition = function(index) {
        vm.actionAssignConditions.splice(index,1);
      }

      vm.onChangeItem = function(item){
        if(item){
          if(item.externalURL && item.externalURL.includes('internal-options')){
            var query = item.externalURL.split('\?')[1];
            var params = query.split('\&');
            var formId;
            angular.forEach(params, function(param){
              var key = param.split("=")[0];
              var value = param.split("=")[1]
              if(key == "formId" ){
                formId = value;
              }
            })
            FormHelper.getFormWithExternalOptions(formId).then(function(form) {
              vm.formInternalOptions = form;
              FormHelper.getItemsTypeSelect(vm.formInternalOptions).then(function(items) {
                vm.formInternalOptions.fieldSets[0].items = angular.copy(items);
              });
            })
            vm.asSubForm = true;
            vm.actionAssignConditions = [];
          }else{
            vm.actionAssignConditions = [];
            vm.actionAssignConditions.push({
              itemId: vm.item.id, 
              subitemId: undefined,
              userToAssignId: undefined,
              value: undefined,
              operator: "equals"
            })
            vm.asSubForm = false;
          }
          createSelect2();
        }
      }

      vm.onChangeSubitem = function(subitem){
        if(subitem){
          vm.actionAssignConditions = [];
          vm.actionAssignConditions.push({
            itemId: vm.item.id, 
            subitemId: vm.subitem.id,
            userToAssignId: undefined,
            value: undefined,
            operator: "equals"
          });
          createSelect2();
        }
      }

      vm.onChangeAsSubForm = function() {
        vm.actionAssignConditions = [];
        if (!vm.asSubForm && vm.item.values.length == 0) {
          internalOptionsLoader();
        }
      }


      vm.cancel = function(){
        $uibModalInstance.dismiss();
      }

      vm.save = function(){
        $uibModalInstance.close(vm.actionAssignConditions);
      }

      var createSelect2 = function() {
        $timeout(function(){
            $("select").select2({
                width: "100%"
            });
        }, 200); 
      }
  }

})();