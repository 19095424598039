(function () {
    'use strict';

    angular
        .module('pickerApp')
        .factory('ChartHelper', ChartHelper);

    ChartHelper.$inject = ['$q', '$filter', 'Chart', 'ChartCalcType', 'ChartFrequency', 'ChartSnapshot', 'ChartType'];

    function ChartHelper ($q, $filter, Chart, ChartCalcType, ChartFrequency, ChartSnapshot, ChartType) {
        var state = {
            frequencies: {
                requested: false,
                val: undefined
            },
            calcTypes: {
                requested: false,
                val: undefined
            },
            types: {
                requested: false,
                val: undefined
            },
        }

        var service = {
            getChartFrequencies: function() {
                var deferred = $q.defer();
                if (state.frequencies.val) {
                    deferred.resolve(state.frequencies.val);
                } else {
                    if (!state.frequencies.requested) {
                        state.frequencies.requested = true;
                        ChartFrequency.query({}, function(response) {
                            state.frequencies.val = response;
                            deferred.resolve(state.frequencies.val);
                        });
                    } else {
                        setTimeout(function() {
                            service.getChartFrequencies().then(function(frequencies) {
                                deferred.resolve(frequencies);
                            });
                        }, 100)
                    }
                }
                return deferred.promise;
            },
            getChartCalcTypes: function() {
                var deferred = $q.defer();
                if (state.calcTypes.val) {
                    deferred.resolve(state.calcTypes.val);
                } else {
                    if (!state.calcTypes.requested) {
                        state.calcTypes.requested = true;
                        ChartCalcType.query({}, function(response) {
                            state.calcTypes.val = response;
                            deferred.resolve(state.calcTypes.val);
                        });
                    } else {
                        setTimeout(function() {
                            service.getChartCalcTypes().then(function(calcTypes) {
                                deferred.resolve(calcTypes);
                            });
                        }, 100)
                    }
                }
                return deferred.promise;
                var deferred = $q.defer();
            },
            getChartTypes: function() {
                var deferred = $q.defer();
                if (state.types.val) {
                    deferred.resolve(state.types.val);
                } else {
                    if (!state.types.requested) {
                        state.types.requested = true;
                        ChartType.query({}, function(response) {
                            state.types.val = response;
                            deferred.resolve(state.types.val);
                        });
                    } else {
                        setTimeout(function() {
                            service.getChartTypes().then(function(types) {
                                deferred.resolve(types);
                            });
                        }, 100)
                    }
                }
                return deferred.promise;
                var deferred = $q.defer();
            },
            getNameOfFrequency: function(id) {
                var deferred = $q.defer();
                service.getChartFrequencies().then(function(frequencies) {
                    deferred.resolve($filter('filter')(frequencies, {id: id})[0].name);
                });
                return deferred.promise;
            },
            getCodeOfType: function(id) {
                var deferred = $q.defer();
                service.getChartTypes().then(function(types) {
                    deferred.resolve($filter('filter')(types, {id: id})[0].code);
                });
                return deferred.promise;
            }
        }

        return service;
    }
})();
