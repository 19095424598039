(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('Permission', Permission);

    Permission.$inject = ['$resource'];

    function Permission ($resource) {
        var resourceUrl =  'api/permissions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('PermissionHelper', PermissionHelper);

    PermissionHelper.$inject = ['$q', 'Permission'];

    function PermissionHelper ($q, Permission) {

        var state = {
            formPermissions: {
                requested: false,
                val: undefined
            },
            dashboardPermissions: {
                requested: false,
                val: undefined
            }
        }

        var service = {
            getFormPermissions: function() {
                var deferred = $q.defer();
                if (state.formPermissions.val) {
                    deferred.resolve(state.formPermissions.val);
                } else {
                    if (!state.formPermissions.requested) {
                        state.formPermissions.requested = true;
                        Permission.query({type: 'form'}, function(response) {
                            state.formPermissions.val = response;
                            deferred.resolve(state.formPermissions.val);
                        });
                    } else {
                        setTimeout(function() {
                            service.getFormPermissions().then(function(formPermissions) {
                                deferred.resolve(formPermissions);
                            });
                        }, 100)
                    }
                }
                return deferred.promise;
            },
            getDashboardPermissions: function() {
                var deferred = $q.defer();
                if (state.dashboardPermissions.val) {
                    deferred.resolve(state.dashboardPermissions.val);
                } else {
                    if (!state.dashboardPermissions.requested) {
                        state.dashboardPermissions.requested = true;
                        Permission.query({type: 'dashboard'}, function(response) {
                            state.dashboardPermissions.val = response;
                            deferred.resolve(state.dashboardPermissions.val);
                        });
                    } else {
                        setTimeout(function() {
                            service.getDashboardPermissions().then(function(dashboardPermissions) {
                                deferred.resolve(dashboardPermissions);
                            });
                        }, 100)
                    }
                }
                return deferred.promise;
            },
        }
        return service;
    }
})();