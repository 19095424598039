(function(){
	'user strict';
	angular.module('pickerApp')
	.controller("TabMapController",
  ['$q', '$compile', '$rootScope','$scope', '$timeout','Pick', 'Position','NgMap', 'FormRecord', 'User', 'ItemRecord', 'DocumentRecord','$filter', 'ModalHelper', 'MapMarker' , 'AlertService' ,
    function ($q, $compile, $rootScope, $scope, $timeout, Pick, Position, NgMap, FormRecord, User, ItemRecord, DocumentRecord, $filter, ModalHelper, MapMarker, AlertService) {      
      $scope.googleUrl = $rootScope.googleUrl

      var vm = this;
      vm.actions;
      vm.markerCluster;
      vm.overlay;
      vm.queryPlace;
      vm.markerUser = {};
      vm.track = {};
      vm.markerSearched = undefined
      vm.showMarkers = false;
      vm.permissions = $scope.permissions;

      angular.extend($scope, {vm: vm});

      vm.search = function(){
        var request = {
          query: $("#query").val(),
          fields: ['name', 'geometry'],
        };
        if(vm.form.properties.zoneRectangle){
          request.locationBias = getFilterBounds(vm.form.properties.zoneRectangle);
        }
        var service = new google.maps.places.PlacesService(vm.map);
        service.findPlaceFromQuery(request, function(results, status){
           if (status === google.maps.places.PlacesServiceStatus.OK) {
              if(vm.markerSearched){
                vm.markerCluster.removeMarker(vm.markerSearched);
                vm.markerSearched.setMap(null);
                vm.markerSearched = undefined;
              }
              vm.markerSearched = new google.maps.Marker({
                          position: results[0].geometry.location,
                          draggable: false,
                          map: vm.map
                        });
              vm.markerCluster.addMarker(vm.markerSearched);
              vm.map.setCenter(results[0].geometry.location);
            }
        })
      }

      $scope.callbackActions = function(){
        vm.infowindow.close();
        vm.overlay.setMap(null);
        vm.drawingManager.setDrawingMode(null);
        $scope.onUpdate()
      }

      $scope.hidePopover = function(){
        vm.infowindow.close();
      }

      $scope.$watch('picks', function(newVal){
        if(newVal != undefined && vm.map != undefined){
          setMarkers(newVal);
        }
      },true)

      $scope.$watch('vm.map', function(newVal){
        if(newVal != undefined && $scope.picks != undefined){
          setMarkers($scope.picks);
        }
      })


      $scope.$watch('polygonPath', function(newVal){
        if(newVal != undefined) {
          var path = newVal.points.map(function(point){return {lat: point.latitude, lng: point.longitude};}); 
          var polygon = new google.maps.Polygon({
            path: path,
            strokeColor: "#000000",
            strokeOpacity: 1,
            strokeWeight: 3,
            fillColor: "#000000",
            fillOpacity: 0.35,
          })
          polygon.setMap(vm.map);
          $('[title="Dibujar forma"]').attr("disabled", true);
          if(vm.drawingManager){
            vm.drawingManager.setDrawingMode(null);
          }
          if(vm.overlay){
            vm.infowindow.close();
            vm.overlay.setMap(null);
          }
          vm.overlay = polygon;
          vm.selectedFormRecords = [];
          angular.forEach(vm.markers, function(marker){
            if(google.maps.geometry.poly.containsLocation(marker.getPosition(), vm.overlay)){
              vm.selectedFormRecords.push(marker.get("formRecordId"));
            }
          })
          vm.addMapFilter(vm.selectedFormRecords);
          google.maps.event.addListener(vm.overlay, 'contextmenu', function(ev){
            vm.infowindow.close();
            var content = getActionExecuteTemplate(false);
            var compiledContent = $compile(content)($scope)[0];
            vm.infowindow = new google.maps.InfoWindow({
              content: compiledContent
            });
            vm.infowindow.setPosition(ev.latLng);
            vm.infowindow.open(map);
          })
        }else if (newVal == undefined) {
          if(vm.drawingManager)
          vm.drawingManager.setDrawingMode(null);
          if(vm.overlay){
            $scope.addMapFilter();
            $scope.onUpdate();
            vm.infowindow.close();
            vm.overlay.setMap(null);
            $('[title="Dibujar forma"]').attr("disabled", false);
          }
        }
      }, true);
      
      $scope.$watch('users', function(newVal){
        angular.forEach(newVal, function(user){
          if(user.showInMap){
            var filters = {user: user.id};
            if(user.showTrackInMapFromDate){
              filters.fromDate = user.showTrackInMapFromDate;
              filters.toDate = user.showTrackInMapToDate;
            }else{
              filters.top = true;
            }
            Position.query(filters, function(positions){
              if(positions.length > 0){
                  if(vm.markerUser[user.id]){
                  for(var i = 0; i < vm.markerUser[user.id].length; i++){
                    var mark = vm.markerUser[user.id][i];
                    mark.setMap(null);
                  }

                  if(vm.track[user.id]){
                    vm.track[user.id].setMap(null);
                  }  
                }
                
                vm.markerUser[user.id] = [];
                var first = positions[0];
                vm.markerUser[user.id].push(new google.maps.Marker({
                        position: {lat: parseFloat(first.latitude) , lng: parseFloat(first.longitude)},
                        draggable: false,
                        icon: 'content/images/user-map.png',
                        map: vm.map,
                        zIndex: 15000
                      }))
                vm.markerUser[user.id][0].set("infowindow", new google.maps.InfoWindow({content: "<div><h2>Usuario: "+user.completeName+"</h2>"+$filter("date")(first.date, "HH:mm dd/MM/yyyy")+"</div>"}));
                vm.markerUser[user.id][0].get("infowindow").open(vm.map, vm.markerUser[user.id][0]);
                vm.markerUser[user.id][0].addListener('click',function(){
                  vm.markerUser[user.id][0].get("infowindow").open(vm.map, vm.markerUser[user.id][0])
                })
                angular.forEach(positions, function(position, index){
                  if(index != 0){
                    var marker = new google.maps.Marker({
                          position: {lat: parseFloat(position.latitude) , lng: parseFloat(position.longitude)},
                          draggable: false,
                          icon: 'content/images/dot.png',
                          title: position.date.toISOString(),
                          map: vm.map
                        });
                    marker.set("infowindow", new google.maps.InfoWindow({content: "<div>"+$filter("date")(position.date, "HH:mm dd/MM/yyyy")+"</div>"}));
                    marker.addListener('click', function(){
                      marker.get("infowindow").open(vm.map, marker);
                    })
                      vm.markerUser[user.id].push(marker);    
                  }
                })

                var path = [];
                angular.forEach(vm.markerUser[user.id], function(marker){
                  path.push({lat: marker.position.lat(), lng: marker.position.lng()});
                })
                vm.track[user.id] = new google.maps.Polyline({
                  path: path,
                  geodesic: true,
                strokeColor: '#ffb72c',
                strokeOpacity: 1.0,
                strokeWeight: 1.5
                })

                vm.track[user.id].setMap(vm.map);
              }
            })
          }else{
            if(vm.markerUser[user.id]){
              for(var i = 0; i < vm.markerUser[user.id].length; i++){
                var mark = vm.markerUser[user.id][i];
                mark.setMap(null);
              }
              if(vm.track[user.id]){
                vm.track[user.id].setMap(null);
              }  
            }
          }
        })
      }, true)

      $scope.$watch('form', function(newVal, oldVal){
        if(newVal != undefined){
          vm.form = newVal;
          if(vm.form.properties.defaultMapCenter){
            vm.defaultMapCenter = vm.form.properties.defaultMapCenter.replace("|",",");
          }
          if(oldVal != undefined && vm.form.id != oldVal.id){
            update(); 
          }
        }
      },true)

      function update(){
        vm.markers = [];
        if(vm.markerCluster){
          vm.markerCluster.clearMarkers();
        }
        if(vm.overlay){
          vm.infowindow.close();
          vm.overlay.setMap(null);
        }  
      }

      function setMarkers(formRecords){
        var deferred = $q.defer();
        var loopPromises = []
        vm.markers = [];
        angular.forEach(formRecords, function(formRecord, index){
          var loopDeferred = $q.defer();
          loopPromises.push(loopDeferred.promise);
          var splitValues = formRecord.position ? formRecord.position.split('|'): [0,0];
          var icon = MapMarker.getMarkerIconAndColor(formRecord.icon, formRecord.color);
          var marker = new google.maps.Marker({
                position: {lat: parseFloat(splitValues[0]) , lng: parseFloat(splitValues[1])},
                draggable: false,
                icon: { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(icon), scaledSize: new google.maps.Size(20, 20) }
              })
          marker.set("formRecordId", formRecord.id);
          marker.addListener("click", function(){
            var isUpdatable = true;
            angular.forEach(vm.form.properties, function(property){
              if(vm.form.properties.updatable && vm.form.properties.updatable == "none"){
                  isUpdatable = false;
              }
            })
            if(isUpdatable && vm.permissions.editFormRecord){
              vm.editFormRecord(marker.get("formRecordId"), undefined, vm.form);
            }else{
              vm.showFormRecord(marker.get("formRecordId"), vm.form);
            }
          });  
          vm.markers.push(marker);
          loopDeferred.resolve();
        })
        $q.all(loopPromises).then(function(){
          if(vm.markerCluster != undefined){
            vm.markerCluster.clearMarkers();
          }
          vm.markerCluster = 
          vm.markerCluster = new markerClusterer.MarkerClusterer({
              map: vm.map,
              markers: vm.markers,
              algorithmOptions: {
                maxZoom: vm.showMarkers ? 1 : 20
              }
            });

          /*new markerClusterer.MarkerClusterer(vm.map, vm.markers,
            {imagePath: 'content/images/m', maxZoom: vm.showMarkers ? 1 : 20});
          */
          deferred.resolve(true);

        })
        return deferred.promise;
      }

      function addButtonDropShapeMap(){
        var drop_button = document.createElement("span");
        drop_button.setAttribute("class", "glyphicon glyphicon-trash drop-shape");
        drop_button.setAttribute("title", "Eliminar forma");
        drop_button.addEventListener("click",function(ev){
          vm.drawingManager.setDrawingMode(null);
          if(vm.overlay){
            $scope.addMapFilter();
            $scope.onUpdate();
            vm.infowindow.close();
            vm.overlay.setMap(null);
            $('[title="Dibujar forma"]').attr("disabled", false);
          }
        })
        vm.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(drop_button);
        $timeout(function(){
          $('[title="Dejar de dibujar"]').attr('style', 'background: none padding-box rgb(255, 255, 255); display: block; border: 0px; margin: 0px; padding: 4px; text-transform: none; appearance: none; position: relative; cursor: pointer; user-select: none; direction: ltr; overflow: hidden; text-align: left; color: rgb(0, 0, 0); font-family: Roboto, Arial, sans-serif; font-size: 11px; border-bottom-left-radius: 0px; border-top-left-radius: 0px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; font-weight: 500;');
        },1000);
      }

      function addButtonDisalbleClustering(){
        var clustering_button = document.createElement("span");
        clustering_button.setAttribute("class", "disable-markers");
        clustering_button.setAttribute("title", "Desagrupar marcadores");
        clustering_button.addEventListener("click",function(ev){
          $(this).toggleClass("disable-clustering");
          clustering_button.setAttribute("title", "Agrupar marcadores");
          vm.showMarkers = !vm.showMarkers;
          filterMarkers();
        })
        vm.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(clustering_button);
      }

      function addEnableEditPosition(){
        var edit_button = document.createElement("span");
        edit_button.setAttribute("class", "glyphicon glyphicon-lock edit-position-button");
        edit_button.setAttribute("title", "Editar posiciones");
        edit_button.addEventListener("click",function(ev){
          $(this).toggleClass("glyphicon-lock")
          $(this).toggleClass("glyphicon-floppy-disk");
          vm.enableEditMarker = !vm.enableEditMarker;
          angular.forEach(vm.markers, function(marker){
            if(vm.enableEditMarker){
              marker.draggable = true;
              marker.addListener("dragend", function(ev){
                if(vm.markersChanged && Array.isArray(vm.markersChanged)){
                  vm.markersChanged.push(marker);
                }else{
                  vm.markersChanged = [];
                  vm.markersChanged.push(marker);
                }
              }) 
            }else{
              marker.draggable = false;
              google.maps.event.clearListeners(marker, "dragend"); 
            }
          })
          if(vm.enableEditMarker){
            edit_button.setAttribute("title", "Guardar posiciones");
            ModalHelper.alert("Ahora puede editar posiciones arrastrando marcadores en el mapa, al finalizar presione el botón de <strong>Guardar posiciones</strong> y sus modificaciones serán guardadas");
          }else{
            edit_button.setAttribute("title", "Editar posiciones");
            if(vm.markersChanged && vm.markersChanged.length > 0){
              var modalInstance = ModalHelper.confirmation("Usted a modificado "+vm.markersChanged.length+" respuestas ¿Desea guardar los cambios?");
              modalInstance.result.then(function (confirmation) {
                if(confirmation){
                  $rootScope.showLoading("Guardando modificaciones...");
                  var loopPromises = [];
                  angular.forEach(vm.markersChanged, function(markerChanged){
                    var loopDeferred = $q.defer();
                    loopPromises.push(loopDeferred.promise);
                    updatePosition(markerChanged).then(function(){
                      loopDeferred.resolve();
                    },function(error){
                      loopDeferred.reject();
                    })
                  })
                  $q.all(loopPromises).then(function(){
                    delete vm.markersChanged;
                    $rootScope.hideLoading();
                    ModalHelper.alert("Cambios guardados con éxito");
                  }, function(error){
                    $rootScope.hideLoading();
                    ModalHelper.alert("Algunas de las respuestas modificadas no pudo guardar sus cambios");
                  })
                }
              })
            }
          }
          vm.markerCluster.clearMarkers();
          vm.markerCluster.addMarkers(vm.markers);
          vm.markerCluster.resetViewport();
          vm.markerCluster.redraw();
        })
        vm.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(edit_button);
      }

      function updatePosition(marker){
        var deferred = $q.defer();
        FormRecord.get({id: marker.get("formRecordId")}, function(formRecord){
          delete formRecord.createdAtOnServer;
          delete formRecord.position;
          delete formRecord.updatedAt;
          delete formRecord.fieldSetRecords[0].items;
          delete formRecord.userName;
          var item = $filter("filter")(vm.form.fieldSets[0].items, {type: "map"})[0];
          var itemRecord = $filter("filter")(formRecord.fieldSetRecords[0].itemRecords, {itemId: item.id})[0];
          itemRecord.value = marker.getPosition().lat() +"|"+marker.getPosition().lng();
          FormRecord.update({avoidActions: true, onlyPosition: true}, formRecord, function(response){
            deferred.resolve();
          },function(error){
            deferred.reject();
          })
        })
        return deferred.promise;
      }
      
      function filterMarkers(){
        if(vm.showMarkers){
          var markers = [];
          angular.forEach(vm.markers, function(marker, index){          
              switch(vm.map.zoom){
                case 6:
                  if(index % 1000 == 0 || vm.markers.length < 3000) {
                      markers.push(marker); 
                  }
                  break;
                case 7:
                  if(index % 700 == 0 || vm.markers.length < 3100) {
                      markers.push(marker); 
                  }
                  break;
                case 8:
                  if(index % 400 == 0 || vm.markers.length < 3200) {
                      markers.push(marker); 
                  }
                  break;
                case 9:
                  if(index % 250 == 0 || vm.markers.length < 3500) {
                      markers.push(marker); 
                  }
                  break;
                case 10:
                  if(index % 150 == 0 || vm.markers.length < 3900) {
                      markers.push(marker); 
                  }
                  break;
                case 11:
                  if(index % 100 == 0 ||  vm.markers.length < 4500) {
                      markers.push(marker); 
                  }
                  break;
                case 12:
                  if(index % 70 == 0 || vm.markers.length < 5000) {
                      markers.push(marker); 
                  }
                  break;
                case 13:
                  if(index % 50 == 0 || vm.markers.length < 5500 ) {
                      markers.push(marker); 
                  }
                  break;
                case 14:
                  if(index % 35 == 0 || vm.markers.length < 5800) {
                      markers.push(marker); 
                  }
                  break;
                case 15:
                  if(index % 20 == 0 || vm.markers.length < 6000) {
                      markers.push(marker); 
                  }
                  break;
                case 16:
                  if(index % 10 == 0 || vm.markers.length < 7000) {
                      markers.push(marker); 
                  }
                  break;
                case 17:
                  if(index % 5 == 0 || vm.markers.length < 8000) {
                      markers.push(marker); 
                  }
                  break;
                case 18:
                  if(index % 3 == 0 || vm.markers.length < 9000) {
                      markers.push(marker); 
                  }
                  break;
                case 19:
                  if(index % 2 == 0 || vm.markers.length < 10000) {
                      markers.push(marker); 
                  }
                  break;
                case 20:
                case 21:                
                case 22:
                  markers.push(marker); 
                  break;
              }
              
          })

          if(vm.markerCluster != undefined){
            vm.markerCluster.clearMarkers();
          }
          vm.markerCluster = new markerClusterer.MarkerClusterer({
              map: vm.map,
              markers: vm.markers,
              algorithmOptions: {
                maxZoom: 1
              }
            });
        }else{
          if(vm.markerCluster != undefined){
            vm.markerCluster.clearMarkers();
          }
          vm.markerCluster = new markerClusterer.MarkerClusterer({
              map: vm.map,
              markers: vm.markers,
              algorithmOptions: {
                maxZoom: 20
              }
            });
        }
        
      }

      var getFilterBounds = function(text){
        var points = text.split("|");
        var s, n, e, w;
        n = parseFloat(points[1].split(",")[0]);
        s = parseFloat(points[0].split(",")[0]);
        e = parseFloat(points[0].split(",")[1]);
        w = parseFloat(points[1].split(",")[1]);
        if(Number.isNaN(n)|| Number.isNaN(s)|| Number.isNaN(w)|| Number.isNaN(e)){
          throw "Parse exception, wrong rectangle value";
        }else{
          return bound = new google.maps.LatLngBounds({lat: s, lng: w},{lat: n, lng: e});  
        }
      }

      NgMap.getMap({id: "main"}).then(function(map){

        vm.map = map;

        if($rootScope.theme.bodyBackground == "#423d5f"){
            vm.map.setOptions({styles: [
              { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
              { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
              { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
              {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#263c3f" }],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#6b9a76" }],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#38414e" }],
              },
              {
                featureType: "road",
                elementType: "geometry.stroke",
                stylers: [{ color: "#212a37" }],
              },
              {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9ca5b3" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#746855" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [{ color: "#1f2835" }],
              },
              {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#f3d19c" }],
              },
              {
                featureType: "transit",
                elementType: "geometry",
                stylers: [{ color: "#2f3948" }],
              },
              {
                featureType: "transit.station",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#17263c" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#515c6d" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#17263c" }],
              },
            ]})
        }
        const streetView = new google.maps.StreetViewPanorama(vm.map.getDiv(), {
            visible: false,
            enableCloseButton: true,
            fullscreenControl: false,
            addressControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_CENTER
            }
        });

        vm.map.setStreetView(streetView);

        var options = {};
        if(vm.form.properties.zoneRectangle){
          options.bounds = getFilterBounds(vm.form.properties.zoneRectangle);
          options.strictBounds = true;
        }
        var autocomplete = new google.maps.places.Autocomplete($("#query")[0], options);

        autocomplete.addListener("place_changed", function(e){
          if(autocomplete.getPlace().geometry){
            if(vm.markerSearched){
              vm.markerCluster.removeMarker(vm.markerSearched);
              vm.markerSearched.setMap(null);
              vm.markerSearched = undefined;
            }
            vm.markerSearched = new google.maps.Marker({
                        position: autocomplete.getPlace().geometry.location,
                        draggable: false,
                        map: vm.map
                      });
            vm.markerCluster.addMarker(vm.markerSearched);
            vm.map.setCenter(autocomplete.getPlace().geometry.location);  
          }
        })

        var content = getActionExecuteTemplate(true);
        var compiledContent = $compile(content)($scope)[0];
        vm.infowindow = new google.maps.InfoWindow({
          content: compiledContent
        });

        vm.drawingManager = new google.maps.drawing.DrawingManager({
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
            drawingModes: ['polygon'],
            polygonOptions: {editable: true},
          }
        });

        vm.map.addListener("zoom_changed", function(){
          if(vm.showMarkers){
            filterMarkers();
          }
        })
        
        vm.drawingManager.setMap(map);
        google.maps.event.addListener(vm.drawingManager, 'overlaycomplete', function(event) {
          $('[title="Dibujar forma"]').attr("disabled", true);
          vm.drawingManager.setDrawingMode(null);
          if(vm.overlay){
            vm.infowindow.close();
            vm.overlay.setMap(null);
          }
          vm.overlay = event.overlay;
          vm.selectedFormRecords = []
          angular.forEach(vm.markers, function(marker){
            if(google.maps.geometry.poly.containsLocation(marker.getPosition(), vm.overlay)){
              vm.selectedFormRecords.push(marker.get("formRecordId"));
            }
          })
          google.maps.event.addListener(vm.overlay, 'contextmenu', function(ev){
            vm.infowindow.close();
            var content = getActionExecuteTemplate(true);
            var compiledContent = $compile(content)($scope)[0];
            vm.infowindow = new google.maps.InfoWindow({
              content: compiledContent
            });
            vm.infowindow.setPosition(ev.latLng);
            vm.infowindow.open(map);
          })
        });
        addButtonDropShapeMap();
        addEnableEditPosition();
        addButtonDisalbleClustering();

        $(document).on('keypress', "#query", function(e){          
          if(e.key === 'Enter'){
            vm.search();
          }
        });
      });


      vm.showFormRecord = function (formRecordId, form) {
        var modalInstance = ModalHelper.open('app/entities/form-record/modal-form-record-show.html',
          'ModalInstanceCtrl',
          {
            formRecordId: function () {
              return formRecordId;
            },
            form: function(){
              return form;
            },
            permissions: function(){
              return vm.permissions;
            }
          }
        );

        modalInstance.result.then(function (value) {
          if(value == 'deleted' || value == 'ok'){
            $scope.onUpdate();
          }
        }, function (error) {
        });
      };


      vm.editFormRecord = function(formRecordId, newFormRecord, form){      
        var modalInstance = ModalHelper.open(
          'app/entities/form-record/modal-form-record-new.html',
          'ModalNewFormRecordCtrl',
          {
            form: function(){
              return form;
            },
            formRecordId: function(){
              return formRecordId;  
            },
            newFormRecord: function(){
              return newFormRecord;  
            },
            permissions: function(){
              return vm.permissions;
            }
          });

        modalInstance.result.then(function (value) {
          if(value == 'deleted' || value == 'ok'){
            $scope.onUpdate();
          }
        }, function (error) {
        });
      }

      vm.addMapFilter = function(formRecordIds){
        var path = [];
        vm.overlay.getPaths().forEach(function(polygon){
          polygon.forEach(function(latLng){
            path.push({latitude: latLng.lat(), longitude: latLng.lng()})
          })
        })
        $scope.addMapFilter(formRecordIds, { points: path });
      }

      var getActionExecuteTemplate = function(withForms) {
        var formsStr = withForms ? "forms='forms' " : "";
        return "<action-execute "+
        formsStr +
        "form='vm.form' " +
        "selected-form-records='vm.selectedFormRecords' " +
        "actions='actions' " +
        "users='users' " +
        "callback='callbackActions' " +
        "hide-popover='hidePopover' " +
        "popup-in-map='true' " +
        "add-map-filter='vm.addMapFilter' " +
        "permissions='vm.permissions'>" +
        "</action-execute>";
      }
    }])
})();