(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('PropertyUtil', PropertyUtil);

    PropertyUtil.$inject = ['$filter'];

    function PropertyUtil ($filter) {
        

     	function getOptionsAvailableByType(type, properties){
     		var options = $filter("filter")(properties, function(value, index, array){
				switch(type){
                    case "select":
                    	switch(value.name){
                    		case "subtype":
							case "placeholder":
							case "maxlength":
							case "externalURL":
							case "readOnly":
							case "max":
							case "min":
							case "step":
							case "marginTop":
							case "marginBottom":
							case "fileType":
								return false;
							case "style":
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "listTitle":
							case "visible":
							case "editable":
							case "disabled":
								return true;
                    	}
                        break;
                    case "date":
                        switch(value.name){
                    		case "subtype":
                    		case "placeholder":
							case "maxlength":
							case "style":
							case "externalURL":
							case "marginTop":
							case "marginBottom":
							case "readOnly":
							case "max":
							case "min":
							case "step":
							case "fileType":
								return false;
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "listTitle":
							case "visible":
							case "editable":
							case "disabled":
								return true;
                    	}
                        break;
                    case "checkbox":
                        switch(value.name){
                    		case "subtype":
                    		case "placeholder":
							case "maxlength":
							case "style":
							case "externalURL":
							case "readOnly":
							case "max":
							case "min":
							case "step":
							case "marginTop":
							case "marginBottom":
							case "fileType":
								return false;
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "listTitle":
							case "visible":
							case "editable":
							case "disabled":
								return true;
                    	}
                        break;
                    case "map":
                        switch(value.name){
                    		case "subtype":
                    		case "placeholder":
							case "maxlength":
							case "style":
							case "externalURL":
							case "max":
							case "min":
							case "step":
							case "marginTop":
							case "marginBottom":
							case "listTitle":
							case "fileType":
								return false;
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "visible":
							case "editable":
							case "disabled":
                    		case "readOnly":
								return true;
                    	}
                        break;
                    case "image":
					case "drawing":
                        switch(value.name){
                    		case "subtype":
                    		case "placeholder":
							case "maxlength":
							case "style":
							case "externalURL":
							case "readOnly":
							case "max":
							case "min":
							case "step":
							case "marginTop":
							case "marginBottom":
							case "listTitle":
							case "fileType":
								return false;
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "visible":
							case "editable":
							case "disabled":
								return true;
                    	}
                        break;
					case "file":
						switch(value.name){
							case "subtype":
							case "placeholder":
							case "maxlength":
							case "style":
							case "externalURL":
							case "readOnly":
							case "step":
							case "listTitle":
							case "marginTop":
							case "marginBottom":
								return false;
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "visible":
							case "editable":
							case "disabled":
							case "max":
							case "min":
							case "fileType":
								return true;
						}
						break;
                    case "text":
                        switch(value.name){
                    		case "subtype":
                    		case "placeholder":
							case "maxlength":
							case "style":
							case "readOnly":
							case "max":
							case "min":
							case "step":
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "listTitle":
							case "visible":
							case "editable":
							case "disabled":
							case "fileType":
								return true;
							case "marginTop":
							case "marginBottom":
							case "externalURL":
								return false;
                    	}
                        break;
                    case "button":
                        switch(value.name){
							case "placeholder":
							case "maxlength":
							case "style":
							case "externalURL":
							case "readOnly":
							case "max":
							case "min":
							case "step":
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "listTitle":
							case "visible":
							case "editable":
							case "disabled":
							case "marginTop":
							case "marginBottom":
							case "fileType":
								return false;
                    		case "subtype":
                    			return true;
                    	}
                        break;
                    case "header":
                        switch(value.name){
							case "placeholder":
							case "maxlength":
							case "style":
							case "externalURL":
							case "readOnly":
							case "max":
							case "min":
							case "step":
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "listTitle":
							case "editable":
							case "disabled":
							case "fileType":
								return false;
							case "visible":
							case "marginBottom":
							case "marginTop":
							case "subtype":
                    			return true;
                    	}
                        break;
                    case "listCustom":
						return false;
                        break;
                    case "autoincremental":
                        switch(value.name){
                    		case "subtype":
                    		case "placeholder":
							case "maxlength":
							case "style":
							case "externalURL":
							case "readOnly":
							case "max":
							case "min":
							case "step":
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "marginTop":
							case "marginBottom":
							case "fileType":
								return false;
							case "listTitle":
							case "visible":
							case "editable":
							case "disabled":
								return true;
                    	}
                        break;
                    case "textarea":
                        switch(value.name){
							case "placeholder":
							case "maxlength":
							case "style":
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "listTitle":
							case "visible":
							case "editable":
							case "disabled":
							case "fileType":
								return true;
							case "externalURL":
							case "readOnly":
							case "max":
							case "min":
							case "step":
                    		case "subtype":
                    		case "marginTop":
							case "marginBottom":
								return false;
                    	}
                        break;
                    case "address":
                        switch(value.name){
                    		case "subtype":
                    		case "maxlength":
							case "marginTop":
							case "marginBottom":
							case "externalURL":
							case "readOnly":
							case "max":
							case "min":
							case "step":
							case "fileType":
								return false;
							case "valueFromLastFormRecord":
							case "saveAndContinue":
							case "listTitle":
							case "visible":
							case "editable":
							case "disabled":
							case "placeholder":
							case "style":
								return true;

                    	}
                        break;
                }
     		})
			return options;
     	}

     	function getTemplateByItemAndProperty(propertyName, itemType){
   			var id = randomInt(0, 100000);
     		var template;
		    switch(propertyName){
        		case "subtype":
        			if(itemType == "text"){
            			template = "<select id='generatedSelect-"+id+"' ng-model='propertyValue'><option value='number'>Número</option></select>";

	        			template += "<script type='text/javascript'>$('#generatedSelect-"+id+"').select2({width: '100%'})</script>";
        			}
        			if(itemType == "header"){
            			template = "<select id='generatedSelect-" + id + "' ng-model='propertyValue'>" +
            			"<option value='h1'>Tamaño grande</option>" + 
            			"<option value='h2'>Tamaño medio</option>" + 
            			"<option value='h3'>Tamaño pequeño</option>" + 
            			"</select>";
    	    			template += "<script type='text/javascript'>$('#generatedSelect-"+id+"').select2({width: '100%'})</script>";
        			}
        			if(itemType == "button"){
            			template = "<select id='generatedSelect-"+id+"' ng-model='propertyValue'><option value='submit'>Enviar respuesta</option></select>";
    	    			template += "<script type='text/javascript'>$('#generatedSelect-"+id+"').select2({width: '100%'})</script>";
        			}
					break;
				case "placeholder":
					template = "<input class='form-control' type='text' maxlength='255' ng-model='propertyValue'>";
					break;
				case "maxlength":
					template = "<input class='form-control' type='number' maxlength='255' ng-model='propertyValue'>";
					break;
				case "style":
					template = "<input class='form-control' type='text' maxlength='255' ng-model='propertyValue'>";
					break;
				case "readOnly":
					template = "<label>Mapa de solo lectura&nbsp;</label><input type='checkbox' ng-model='propertyValue' ng-init='propertyValue=true'>";
					break;
				case "max":
					var placeholder = '';
					if (itemType == 'file') {
						placeholder = "valor en MB";
					}
					if (itemType == 'text') {
						placeholder = "Máximo número";
					}
					template = "<input class='form-control' type='number' maxlength='255' ng-model='propertyValue'>";
					template = "<input class='form-control' type='number' maxlength='255' ng-model='propertyValue' placeholder='"+placeholder+"'>";
					break;
				case "marginTop":
					template = "<input class='form-control' type='number' maxlength='255' ng-model='propertyValue'>";
					break;
				case "marginBottom":
					template = "<input class='form-control' type='number' maxlength='255' ng-model='propertyValue'>";
					break;
				case "min":
					var placeholder = '';
					if (itemType == 'file') {
						placeholder = "valor en MB";
					}
					if (itemType == 'text') {
						placeholder = "Mínimo número";
					}
					template = "<input class='form-control' type='number' maxlength='255' ng-model='propertyValue' placeholder='"+placeholder+"'>";
					break;
				case "step":
					template = "<input class='form-control' type='number' maxlength='255' ng-model='propertyValue'>";
					break;
				case "valueFromLastFormRecord":
					template = "<label>Recuperar valor de la última respuesta &nbsp;</label><input type='checkbox' ng-model='propertyValue'  ng-init='propertyValue=true'>";
					break;
				case "saveAndContinue":
					template = "<label>Guardar y continuar &nbsp;</label><input type='checkbox' ng-model='propertyValue'  ng-init='propertyValue=true'>";
					break;
				case "listTitle":
					template = "<label>Valor en titulo de listado de aplicación &nbsp;</label><input type='checkbox' ng-model='propertyValue'  ng-init='propertyValue=true'>";
					break;
				case "visible":
            		template = "<select id='generatedSelect-"+id+"' ng-model='propertyValue'>"+
            			"<option value='app'>Solo en aplicación</option>"+
            			"<option value='web'>Solo en página web</option>"+
            			"<option value='print'>Solo en impresión</option>"+
            			"<option value='both'>En todos</option>"+
            			"<option value='none'>En ninguno</option>"+
            			"</select>";
        			template += "<script type='text/javascript'>$('#generatedSelect-"+id+"').select2({width: '100%'})</script>";
					break;
				case "editable":
            		template = "<select id='generatedSelect-"+id+"' ng-model='propertyValue'>"+
            			"<option value='app'>Solo en aplicación</option>"+
            			"<option value='web'>Solo en página web</option>"+
            			"<option value='both'>En ambos</option>"+
            			"<option value='none'>En ninguno</option>"+
            			"</select>";
        			template += "<script type='text/javascript'>$('#generatedSelect-"+id+"').select2({width: '100%'})</script>";
					break;
				case "disabled":
            		template = "<select id='generatedSelect-"+id+"' ng-model='propertyValue'>"+
            			"<option value='app'>Solo en aplicación</option>"+
            			"<option value='web'>Solo en página web</option>"+
            			"<option value='both'>En ambos</option>"+
            			"<option value='none'>En ninguno</option>"+
            			"</select>" ;           			
        			template += "<script type='text/javascript'>$('#generatedSelect-"+id+"').select2({width: '100%'})</script>";
        			break;
				case "fileType":
            		template = "<select id='generatedSelect-"+id+"' ng-model='propertyValue'>"+
            			"<option value='application/pdf'>PDF</option>"+
            			"<option value='application/vnd.ms-excel'>Excel</option>"+
            			"<option value='application/msword'>Word</option>"+
            			"<option value='text/plain'>TXT</option>"+
            			"<option value='application/vnd.ms-powerpoint'>PowerPoint</option>"+
            			"<option value='images/*'>Imágenes</option>"+
            			"<option value='application/vnd.rar,application/x-rar-compressed,application/octet-stream'>RAR</option>"+
            			"<option value='application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip'>ZIP</option>"+
            			"</select>" ;           			
        			template += "<script type='text/javascript'>$('#generatedSelect-"+id+"').select2({width: '100%'})</script>";
        			break;

            }
     		return template;
     	}

     	function randomInt(min, max) {
			return min + Math.floor((max - min) * Math.random());
		}

     	return {
     		getOptionsAvailableByType: getOptionsAvailableByType,
     		getTemplateByItemAndProperty: getTemplateByItemAndProperty
     	}
    }
})();
