(function() {
    'use strict';

    angular
        .module('pickerApp')
        .factory('ModalHelper', ModalHelper);

    ModalHelper.$inject = ['$uibModal'];

    function ModalHelper ($uibModal) {

        function open(templateUrl, controller, resolve){
            return $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: 'vm',
                windowTopClass: 'modal-center',
                bindToControlle: true,
                backdrop: 'static',
                size: 'lg',
                resolve: resolve
            });
        }

        function confirmation(text){
            return $uibModal.open({
            animation: true,
            windowTopClass: 'modal-center',
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: '<div class="modal-body" style="text-align: center" id="modal-body"><div><p>'+text+'</p><button type="button" ng-click="accept()" class="btn btn-theme">Si</button><button type="button" ng-click="abort()" class="btn btn-theme">No</button></div></div>',
            controller: ['$scope','$uibModalInstance', function($scope, $uibModalInstance){
              $scope.accept = function(){
                $uibModalInstance.close(true);
              }
              $scope.abort = function(){
                $uibModalInstance.close(false);
              }

            }],
            controllerAs: 'vm',
            size: 'small',
            resolve: {
            }
          })
        }

        function alert(text){
            return $uibModal.open({
                animation: true,
                windowTopClass: 'modal-center',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: '<div class="modal-body" style="text-align: center" id="modal-body"><div><p>'+text+'</p><button type="button" ng-click="accept()" class="btn btn-theme">Ok</button></div></div>',
                controller: ['$scope','$uibModalInstance', function($scope, $uibModalInstance){
                  $scope.accept = function(){
                    $uibModalInstance.close(true);
                  }
                }],
                controllerAs: 'vm',
                size: 'small',
                resolve: {
                }
            });
        }

        function error(text){
            return $uibModal.open({
                animation: true,
                windowTopClass: 'modal-center',
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: '<div class="modal-body" style="text-align: center" id="modal-body"><div><p>'+text+'</p><button type="button" ng-click="accept()" class="btn btn-theme">Ok</button></div></div>',
                controller: ['$scope','$uibModalInstance', function($scope, $uibModalInstance){
                  $scope.accept = function(){
                    $uibModalInstance.close(true);
                  }
                }],
                controllerAs: 'vm',
                size: 'small',
                resolve: {
                }
            });   
        }

        return {
            open: open,
            confirmation: confirmation,
            alert: alert,
            error: error
        };
    }
})();
