(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('ModalEditPositionFormRecordsCtrl', ModalEditPositionFormRecordsCtrl);

    ModalEditPositionFormRecordsCtrl.$inject = ['$scope', '$q', 'position', '$uibModalInstance'];

    function ModalEditPositionFormRecordsCtrl ($scope, $q, position, $uibModalInstance) {
        var vm = this;
        vm.latLng = position;

        vm.cancel = function () {
          $uibModalInstance.dismiss('canceled');
        };

        vm.ok = function () {
          $uibModalInstance.close(vm.latLng);
        };
    }

})();
