(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('Position', Position);

    Position.$inject = ['$resource', 'DateUtils'];

    function Position ($resource, DateUtils) {
        var resourceUrl =  'api/positions/:id';


        return $resource(resourceUrl, {}, {
            'query': { url: 'api/positions', method: 'GET', isArray: true, transformResponse: function(data){
                    data = angular.fromJson(data);
                    for(var i = 0; i< data.length; i++){
                        data[i] = DateUtils.recurseObject(data[i]);
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdAt = DateUtils.convertDateTimeFromServer(data.createdAt);
                        data.updatedAt = DateUtils.convertDateTimeFromServer(data.updatedAt);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            }

        });
    }
})();