(function() {
    'use strict';

    angular
        .module('pickerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        var deleteKey = function(key, obj) {
            if (Array.isArray(obj)) {
                for (var i = 0; i < obj.length; i++) {
                    deleteKey(key, obj[i]);
                }
            } else if (typeof obj == 'object' && obj !== undefined && obj !== null) {
                delete obj[key];
                var keys = Object.keys(obj);
                for (var i = 0; i < keys.length; i++) {
                    deleteKey(key, obj[keys[i]]);
                }
            }
            return obj;
        }

        $stateProvider.state('forms', {
            parent: 'entity',
            url: '/forms',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/form/forms.html',
                    controller: 'FormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                forms: ['$q','Principal', 'Organization', '$rootScope',function($q, Principal, Organization, $rootScope){
                    var deferred = $q.defer();
                    $rootScope.showLoading("Buscando formularios...")
                    Principal.identity().then(function(account) {
                        Organization.getForms({id: account.organizationId}, function(response){
                            $rootScope.hideLoading();
                            deferred.resolve(response);
                        })
                    })
                    return deferred.promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('form');
                    return $translate.refresh();
                }]
            }
        }).state('forms.new', {
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']  
            },
            params: {form: null},
            views: {
                'content@app': {
                    templateUrl: 'app/entities/form/form-form.html',
                    controller: 'FormFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                form: function(){
                    return undefined;
                },
                properties : ['$q', 'Property', function($q, Property){
                    var deferred = $q.defer();
                    Property.query({}, function(properties){
                        deferred.resolve(properties);
                    })
                    return deferred.promise;
                }]
            }
        }).state('forms.edit', {
            url: '/{formId}',
            data: {
                authorities: ['ROLE_ADMIN']  
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/form/form-form.html',
                    controller: 'FormFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                properties : ['$q', 'Property', function($q, Property){
                    var deferred = $q.defer();
                    Property.query({}, function(properties){
                        deferred.resolve(properties);
                    })
                    return deferred.promise;
                }],
                form: ['properties','$q','Form', '$stateParams', function(properties, $q, Form, $stateParams){
                    var deferred = $q.defer();
                    Form.get({id: $stateParams.formId}, function(form){
                        deferred.resolve(form);
                    })
                    return deferred.promise;
                }],
            }
        }).state('forms.copy', {
            url: '/copy/{formId}',
            data: {
                authorities: ['ROLE_ADMIN']  
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/form/form-form.html',
                    controller: 'FormFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                properties : ['$q', 'Property', function($q, Property){
                    var deferred = $q.defer();
                    Property.query({}, function(properties){
                        deferred.resolve(properties);
                    })
                    return deferred.promise;
                }],
                form: ['properties','$q','Form', '$stateParams', function(properties, $q, Form, $stateParams){
                    var deferred = $q.defer();
                    Form.get({id: $stateParams.formId}, function(form){
                        deleteKey("id", form);
                        delete form.fieldSets[0].formId;
                        delete form.formUsers;
                        delete form.name;
                        delete form.description;
                        deferred.resolve(form);
                    })
                    return deferred.promise;
                }],
            }
        });
    }

})();
