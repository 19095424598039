(function() {
    'use strict';

    angular
        .module('pickerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {                
            	'': {
                templateUrl: 'app/layouts/app.html'
            },
                'navbar@app': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                forms: ['Auth', 'Form', '$rootScope', function(Auth, Form, $rootScope){
                    $rootScope.forms = Form.query({});
                    return $rootScope.forms;    
                }],
                dashboards: ['Auth', 'Principal', 'Dashboard', '$rootScope', function(Auth, Principal, Dashboard, $rootScope){
                    return Principal.identity().then(function(account) {
                        if (account) {
                            $rootScope.dashboards = Dashboard.assigned();
                            return $rootScope.dashboards;
                        }
                    });    
                }],
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        }).state('sign_in', {
            url: '/sign_in',
            templateUrl: 'app/sign_in/sign_in.html',
            controller: 'SignInController',
            controllerAs: 'vm',
            data: {
                authorities: []
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('login');
                    $translatePartialLoader.addPart('global');
                }]
            }
        }).state('public', {
            url: '/public/forms/{link}',
            templateUrl: 'app/entities/form-record/modal-form-record-new.html',
            controller: 'ModalNewFormRecordCtrl',
            controllerAs: 'vm',
            data: {
                authorities: []
            },
            resolve: {
                form: ['PublicService', '$stateParams', '$q', '$rootScope', '$http', function(PublicService, $stateParams, $q, $rootScope, $http) {
                    var deferred = $q.defer();
                    PublicService.getForm({id: $stateParams.link}, function(form) {
                        PublicService.getOrganization({id: form.organizationId}).$promise.then(function(organization){
                            $rootScope.organization = organization;
                            $("script[src*='maps.googleapis.com']").remove();
                            $rootScope.googleUrl = 'https://maps.googleapis.com/maps/api/js?key=' + organization.gmapsApiKey + '&libraries=drawing,places,geometry';
                            var script = document.createElement("script");
                            script.setAttribute("src",$rootScope.googleUrl);
                            document.getElementsByTagName("head")[0].appendChild(script);

                            $rootScope.showLoading('Cargando campos...');
                            var loopPromises = [];
                            angular.forEach(form.fieldSets[0].items, function(item_){
                                var loopDeferred = $q.defer();
                                loopPromises.push(loopDeferred.promise);
                                item_.hidden = false;
                                if(item_.visible && (item_.visible == "app" || item_.visible == "none")) {
                                   return; 
                                }
                                switch(item_.type){
                                  case "select":
                                    if (item_.externalURL != undefined){
                                      $http.get(item_.externalURL, {params: {username: undefined}}).then(function(result){
                                        item_.values = result.data;
                                        loopDeferred.resolve();
                                      }, function(error){
                                        item_.failed = true;
                                        loopDeferred.resolve();
                                      })
                                      item_.disable = false;
                                    } else {
                                      item_.disable = false;
                                      loopDeferred.resolve();
                                    }
                                    break;
                                  default:
                                    loopDeferred.resolve();
                                    break;
                                }
                            });
                            $q.all(loopPromises).then(function() {
                                $rootScope.hideLoading();
                                deferred.resolve(form);
                            })
                        });
                    }, function(error) {
                        deferred.resolve(null);
                    });
                    return deferred.promise;
                }],
                formRecordId: function(){
                    return undefined;
                },
                newFormRecord: function(){
                    return undefined
                },
                permissions: function(){
                    return undefined;
                },
                $uibModalInstance: function() {
                    return null
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('login');
                    $translatePartialLoader.addPart('global');
                }]
            }
        });
    };
})();
