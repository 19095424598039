(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('ChartCalcType', ChartCalcType);

    ChartCalcType.$inject = ['$resource'];

    function ChartCalcType ($resource) {
        var resourceUrl =  'api/chart-calc-types/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
