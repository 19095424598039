(function(){
	'user strict';
	angular.module('pickerApp')
	.controller("GoogleMapsPickerController",
  ['$q', "$rootScope",'$scope', '$timeout', 'NgMap',
    function ($q, $rootScope, $scope, $timeout, NgMap) {       
        var lat = -32.90255;
        var lng = -68.84533;
        var marker;
        $scope.isEditable = true;
        if($scope.position){  
          $scope.currentPosition = {
            lat: $scope.position.lat,
            lng: $scope.position.lng
          };
        }else{
            if(!$scope.ignoreParent && $scope.$parent.$parent.vm.form.properties.defaultMapCenter){
              var defaultMapCenter = $scope.$parent.$parent.vm.form.properties.defaultMapCenter;
              $scope.currentPosition = {lat: parseFloat(defaultMapCenter.split("|")[0]), lng: parseFloat(defaultMapCenter.split("|")[1])};
            }
          if(!$scope.currentPosition){
            $scope.currentPosition = {
              lat: lat,
              lng: lng
            }; 
          }  
        }
        
        angular.extend($scope, {
          position: $scope.currentPosition
        });

        var getFilterBounds = function(text){
          var points = text.split("|");
          var s, n, e, w;
          n = parseFloat(points[1].split(",")[0]);
          s = parseFloat(points[0].split(",")[0]);
          e = parseFloat(points[0].split(",")[1]);
          w = parseFloat(points[1].split(",")[1]);
          if(Number.isNaN(n)|| Number.isNaN(s)|| Number.isNaN(w)|| Number.isNaN(e)){
            throw "Parse exception, wrong rectangle value";
          }else{
            return bound = new google.maps.LatLngBounds({lat: s, lng: w},{lat: n, lng: e});  
          }
        }
        
        $scope.search = function(){
          var request = {
            query: $("#queryNewFormRecord").val(),
            fields: ['name', 'geometry'],
          };
          if($scope.$parent.$parent.vm.form.properties.zoneRectangle){
            request.locationBias = getFilterBounds($scope.$parent.$parent.vm.form.properties.zoneRectangle);
          }
          var service = new google.maps.places.PlacesService($scope.map);
          service.findPlaceFromQuery(request, function(results, status){
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              changePosition(results[0].geometry.location);
              $scope.map.setCenter(results[0].geometry.location);
            }
          })
        }

        NgMap.getMap({id: "form"}).then(function(map){
          $scope.map;
         
          var autocomplete = new google.maps.places.Autocomplete($("#queryNewFormRecord")[0]);
          
          var options = {};
          if(!$scope.ignoreParent && $scope.$parent.$parent.vm.form.properties.zoneRectangle){
            options.bounds = getFilterBounds($scope.$parent.$parent.vm.form.properties.zoneRectangle);
            options.strictBounds = true;
          }

          if(options.bounds){
            var autocomplete = new google.maps.places.Autocomplete($("#queryNewFormRecord")[0], options);
          }else{
            var autocomplete = new google.maps.places.Autocomplete($("#queryNewFormRecord")[0]);
          }
          
          autocomplete.addListener("place_changed", function(){
            if(autocomplete.getPlace().geometry){
              changePosition(autocomplete.getPlace().geometry.location);
              $scope.map.setCenter(autocomplete.getPlace().geometry.location);  
            }          
          })


          if($scope.disabled){
            map.draggable = false;
            map.zoomControl = false;
          }
          var icon = '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><style type="text/css">.st-default{fill:var(--multi);}.st-default2{fill:#FFFFFF;}</style><g><path class="st-default" d="M101,195.4c0,43.5,29,109,83.9,189.4c28.6,41.9,57.5,77.9,73.4,97c15.9-19.2,44.8-55.1,73.4-97    c54.9-80.5,83.9-146,83.9-189.4c0-25.9-4.2-50.2-12.6-72.3c-7.9-20.9-19.3-39.3-33.8-54.7c-28.7-30.5-68.1-47.2-110.9-47.2 S176.1,38,147.4,68.4c-14.5,15.4-25.9,33.8-33.8,54.7C105.3,145.1,101,169.5,101,195.4z M262.5,121.3c36.9,0,66.8,29.9,66.8,66.8 s-29.9,66.8-66.8,66.8s-66.8-29.9-66.8-66.8C195.7,151.3,225.6,121.3,262.5,121.3z"/><circle id="Elipse_29" class="st-default2" cx="262.5" cy="188.2" r="66.8"/><path class="st-default2" d="M258.3,21.1c-42.8,0-82.2,16.8-110.9,47.2c-14.5,15.4-25.9,33.8-33.8,54.7c-8.4,22.1-12.6,46.4-12.6,72.3 c0,43.5,29,109,83.9,189.4c28.6,41.9,57.5,77.9,73.4,97c15.9-19.1,44.8-55.1,73.4-97c54.9-80.5,83.9-146,83.9-189.4 c0-25.9-4.2-50.2-12.6-72.3c-7.9-20.9-19.3-39.3-33.8-54.7C340.5,37.9,301.1,21.1,258.3,21.1 M258.3,4.4c96.1,0,174,76.3,174,190.9 c0,114.7-174,312.2-174,312.2S84.3,310,84.3,195.4C84.3,80.7,162.2,4.4,258.3,4.4z"/></g></svg>'
          map.setCenter($scope.position);
          marker = new google.maps.Marker({
            position: $scope.position,
            draggable: map.zoomControl,
            map: map,
            icon: { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(icon), scaledSize: new google.maps.Size(27, 27) }
          })
          marker.addListener('dragend', function(event){
            changePosition(event.latLng);
          })
          map.addListener('click', function(event){
            changePosition(event.latLng);
          })
          $(document).on('keypress', "#queryNewFormRecord", function(e){
            if(e.key === 'Enter'){
              $scope.search();
            }
          });
          google.maps.event.trigger(map, "resize");
        });
      
      var changePosition = function(newPosition){
        marker.setPosition(newPosition);
        $scope.position = {lat: newPosition.lat(), lng: newPosition.lng()};
        $timeout(function(){$scope.$apply()});
      }

      /* End LeafletMarkerPickerController*/
    }])
})();