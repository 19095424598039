(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['$rootScope', 'Principal', 'Auth', 'JhiLanguageService', '$translate', '$timeout'];

    function SettingsController ($rootScope, Principal, Auth, JhiLanguageService, $translate, $timeout) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
                apiKey: account.apiKey
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
        });

        vm.copyApiKey = function() {
           navigator.clipboard.writeText(vm.settingsAccount.apiKey);
           vm.showCopiedApiKey = true;
           $timeout(function() {
               vm.showCopiedApiKey = false;
           }, 3000)
        }

        function save () {
            Auth.updateAccount(vm.settingsAccount).then(onSuccess).catch(onError);
        }

        function onSuccess() {
            vm.error = null;
            vm.success = 'OK';
            Principal.identity(true).then(function(account) {
                vm.settingsAccount = copyAccount(account);
            });
            JhiLanguageService.getCurrent().then(function(current) {
                if (vm.settingsAccount.langKey !== current) {
                    $translate.use(vm.settingsAccount.langKey);
                }
            });
        }

        function onError() {
            vm.success = null;
            vm.error = 'ERROR';
        }
    }
})();
