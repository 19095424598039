(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('iconSvg',
  [
    '$q','$timeout',
    function ($q, $timeout) {
      return {
        /* Only use as <icon-svg> tag */
        restrict: 'E',

        /* Our template */
        templateUrl: 'app/components/icon-svg/icon-svg.html',
        /* Attributes to set */
        scope: {
        },

        link: function (scope, element, attrs) {
          scope.icon = attrs.icon;
          scope.color = attrs.color;
          scope.classes = attrs.classes;
        }
      }
    }
  ]);
})();