(function() {
    'use strict';
    angular
        .module('pickerApp')
        .controller('OrganizationController', OrganizationController);

    OrganizationController.$inject = ['$rootScope', 'Organization', 'Principal', 'ModalHelper'];

    function OrganizationController($rootScope, Organization, Principal, ModalHelper) {

        var vm = this;

        vm.organization = [];

        vm.themes = {default: "Por defecto", bright: "Brillante",dark: "Oscuro", green: "Verde", hilfiger: "Tommy Hilfiger"};

        load();

        function load() {
            Principal.identity().then(function(user){
                vm.user = user;             
                Organization.get({id: user.organizationId}, function(result) {
                    vm.organization = result;
                });
            })
        }

        vm.openOrganizationForm = function(){
          var modalInstance = ModalHelper.open(
            'app/entities/organization/modal-organization-form.html',
            'ModalOrganizationForm',
            {
              organization: function () {
                return vm.organization;
              },
            });

          modalInstance.result.then(function (result) {
            if(result.status == "saved"){
                vm.organization = result.organization;
                $rootScope.setTheme();
                if (user.organizationId) {
                    $("script[src*='maps.googleapis.com']").remove();
                    $rootScope.googleUrl = 'https://maps.googleapis.com/maps/api/js?key=' + organization.gmapsApiKey + '&libraries=drawing,places,geometry';
                    var script = document.createElement("script");
                    script.setAttribute("src",$rootScope.googleUrl);
                    document.getElementsByTagName("head")[0].appendChild(script);
                }
            }
          }, function () {
            
          });          
        }
    }
})();
