(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('panelMapList',
        ['$q', '$timeout', '$filter', '$compile', '$http', 'ModalHelper', 'DataUtils', 'FormFrontConf', 'Count', 'FormUser', 'CountCreator', 'CountAction', '$rootScope',
        function ($q, $timeout, $filter, $compile, $http, ModalHelper, DataUtils, FormFrontConf, Count, FormUser, CountCreator, CountAction, $rootScope) {
            return {
                /* Only use as <panel-map-list> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/entities/form-record-searcher/panel-map-list/panel-map-list.html',
                    /* Attributes to set */    
                scope: {
                    'collapsedPanel': '=',
                    'permissions': '=',
                    'form': '=',
                    'itemsFilterable': '=',
                    'totalPicks': '=',
                    'searcher': '=',
                    'polygonPath': '=',
                    'filters': '=',
                    'actions': '=',
                    'users': '=',
                    'setterFilters': '=',
                    'updaterCounts': '=',
                    'formUser': '=',
                    'selectedFormRecords': '='
                },
                link: function (scope, element, attrs) {
                    var vm = {};
                    scope.vm = vm;

                    vm.createdDateFrom = undefined;
                    vm.createdDateTo = undefined;
                    vm.updatedDateFrom = undefined;
                    vm.updatedDateTo = undefined;
                    vm.assignedDateFrom = undefined;
                    vm.assignedDateTo = undefined;
                    vm.selectedCreators = []; 
                    vm.filtersSpecific = {};
                    vm.formRecordId = undefined;
                    vm.selectedActions = undefined;
                    vm.toClearFilter = [];
                    vm.selectedPicksColor = {field: undefined, object: undefined, colors: []};
                    vm.selectedPicksIcon = {field: undefined, object: undefined, icons: []};
                    vm.colorsUserState = {};
                    vm.iconsUserState = {};
                    vm.colorsActionState = {};
                    vm.iconsActionState = {};

                    vm.permissions = scope.permissions;

                    vm.isEmpty = DataUtils.isEmpty;
                    
                    vm.collapsePanel = function(){
                      scope.collapsedPanel = !scope.collapsedPanel;
                    }

                    scope.$on('colorpicker-selected', function(event, colorObject){
                      var eventValue = event.targetScope.value;
                      $timeout(function(){
                        switch(vm.field){
                          case "item":
                            addPickColor(vm.field, vm.object, eventValue.value, eventValue.color);
                            break
                          case "user":
                            addPickColor(vm.field, vm.object, eventValue.id, eventValue.color);
                            break 
                          case "action":
                            var action = $filter('filter')(scope.actions, {name: eventValue.name})[0];
                            var actionId = action.name == "Sin acción" ? null : action.id;
                            addPickColor(vm.field, vm.object, actionId, eventValue.color);
                            break 
                        }
                        
                      },100);
                    });
                    

                    /****************Importación/Exportación*****************/
                    vm.downloadModel = function(){
                      $rootScope.showLoading("Descargando modelo de importanción...")
                      $http({
                        url: 'api/form-records/export/excel-model', 
                        method: "GET",
                        params: {formId: scope.form.id},
                        responseType: 'arraybuffer'
                      }).success(function (response) {
                        $rootScope.hideLoading();
                        var file = new Blob([response], {type: 'application/vnd.ms-excel'});
                        var url = window.URL || window.webkitURL;
                        var downloadLink = angular.element('<a></a>');
                        downloadLink.attr('href',url.createObjectURL(file));
                        downloadLink.attr('download', 'importModel.xlsx');
                        downloadLink[0].click();
                      }).error(function(error) {
                          console.log(error);
                          $rootScope.hideLoading();
                          if (error.status !== 500 && error.status !== 400) {
                            ModalHelper.error("Ocurrió un error al descargar el modelo de importación");
                          }
                      });
                    }

                    vm.uploadFormRecords = function(file){
                      console.log("uploadFormRecords");
                      var reader = new FileReader();
                      //For Browsers other than IE.
                      if (reader.readAsBinaryString) {
                          reader.onload = function (e) {
                              vm.showImportModal(e.target.result);
                          };
                          reader.readAsBinaryString(file);
                      } else {
                          //For IE Browser.
                          reader.onload = function (e) {
                              var data = "";
                              var bytes = new Uint8Array(e.target.result);
                              for (var i = 0; i < bytes.byteLength; i++) {
                                  data += String.fromCharCode(bytes[i]);
                              }
                              vm.showImportModal(data);
                          };
                          reader.readAsArrayBuffer(file);
                      }
                    }

                    vm.showImportModal = function(data){
                      $rootScope.showLoading("Abriendo archivo...");
                      var workbook = XLSX.read(data, {type: 'binary', cellDates: true});
                      var first_sheet_name = workbook.SheetNames[0];
                      var first_sheet = workbook.Sheets[first_sheet_name];
                      var rows = XLSX.utils.sheet_to_row_object_array(first_sheet, {defval: ""});
                      var headers = Object.keys(rows[0]);
                      $rootScope.hideLoading();
                      var modalInstance = ModalHelper.open('app/entities/form-record/modal-import-form-records.html',
                        'ModalImportFormRecordsCtrl',{
                          form: function(){
                            return scope.form;
                          },
                          rows: function(){
                            return rows;
                          },
                          headers: function(){
                            return headers;
                          }
                        });

                      modalInstance.result.then(function (value) {
                        if(value == 'ok'){
                          scope.searcher();
                        }
                      }, function (error) {
                      });
                    }
                    /****************Importación/Exportación*****************/


                    /**********************New form record ******************/
                    vm.newFormRecord = function(){
                      var modalInstance = ModalHelper.open('app/entities/form-record/modal-form-record-new.html','ModalNewFormRecordCtrl',{
                          form: function(){
                            return scope.form;
                          },
                          formRecordId: function(){
                            return undefined;
                          },
                          newFormRecord: function(){
                            return undefined
                          },
                          permissions: function(){
                            return scope.permissions;
                          }
                        });

                      modalInstance.result.then(function (value) {
                          if(value == 'ok'){
                            scope.searcher();
                          }
                        }, function (error) {
                        });
                    }
                    /**********************New form record ******************/

                    vm.changeFormRecordIdArrow = function(){
                      angular.element('#formRecordIdArrow').toggleClass('glyphicon-chevron-down');
                      angular.element('#formRecordIdArrow').toggleClass('glyphicon-chevron-up')
                    }

                    vm.filtersChevron = function(){
                      angular.element('#filters-chevron').toggleClass('glyphicon-chevron-down');
                      angular.element('#filters-chevron').toggleClass('glyphicon-chevron-up')
                    }

                    vm.createdDateChevron = function(){
                      angular.element('#created-date-chevron').toggleClass('glyphicon-chevron-down');
                      angular.element('#created-date-chevron').toggleClass('glyphicon-chevron-up')

                    }

                    vm.updatedDateChevron = function(){
                      angular.element('#updated-date-chevron').toggleClass('glyphicon-chevron-down');
                      angular.element('#updated-date-chevron').toggleClass('glyphicon-chevron-up')          
                    }

                    vm.assignedDateChevron = function(){
                      angular.element('#assigned-date-chevron').toggleClass('glyphicon-chevron-down');
                      angular.element('#assigned-date-chevron').toggleClass('glyphicon-chevron-up')          
                    }


                    vm.dropFilters = function(isUpdate){ 
                      cleanColors(vm.selectedPicksColor.field, vm.selectedPicksColor.object);
                      delete vm.colorsUserState.colors;
                      delete vm.colorsActionState.colors;
                      angular.forEach(scope.itemsFilterable, function(item){
                        delete item.colors;
                      });
                      cleanIcons(vm.selectedPicksIcon.field, vm.selectedPicksIcon.object).then(function(){
                        delete vm.iconsUserState.icons;
                        delete vm.iconsActionState.icons;
                        angular.forEach(scope.itemsFilterable, function(item){
                          delete item.icons;
                        })
                      });
                      vm.createdDateFrom = undefined;
                      vm.createdDateTo = undefined;
                      vm.updatedDateFrom = undefined;
                      vm.updatedDateTo = undefined;
                      vm.assignedDateFrom = undefined;
                      vm.assignedDateTo = undefined;
                      vm.selectedCreators = []; 
                      vm.filtersSpecific = {};
                      vm.formRecordId = undefined;
                      vm.selectedActions = undefined;
                      if(scope.selectedFormRecords != undefined){
                        scope.selectedFormRecords = undefined;
                        scope.polygonPath = undefined
                      }
                      angular.forEach(vm.toClearFilter, function(object){
                        switch(object.type){
                          case "item":
                            switch(object.object.type){
                            case "select":
                              angular.forEach(object.object.values, function(value){
                                value.checked = false;
                              })
                              break;
                            case "checkbox":
                              object.object.valueTrue = false;
                              object.object.valueFalse = false;
                              break;
                            case "text":
                              object.object.value = null;
                              break;
                            case "textarea":
                              object.object.value = null;
                              break;
                            case "address":
                              object.object.value = null;
                              break;
                            case "date":
                              object.object.value = null;
                              break;
                          }
                          break;
                          case "user":
                            angular.forEach(object.object, function(user){
                              user.checked = false;
                            })
                            break;
                          case "action":
                            angular.forEach(object.object, function(action){
                              action.checked = false;
                            })
                            break

                        }
                      })
                      vm.toClearFilter = [];
                      if(isUpdate){
                        scope.searcher(); 
                      }
                    }

                    vm.deleteFormFrontConf = function(formFrontConf){
                      var modalInstance = ModalHelper.confirmation("¿Está seguro de eliminar el filtro <b>"+formFrontConf.name+"</b>?");

                      modalInstance.result.then(function (confirmation){
                        if(confirmation){
                          FormFrontConf.delete({id: formFrontConf.id}, {},function(response){
                            getFormFrontConfs();
                          });
                        }
                      })
                    }

                    vm.openModalFormFrontConf = function(formFrontConf){
                      var modalInstance = ModalHelper.open(
                        'app/entities/form-front-conf/modal-form-front-conf.html',
                        'ModalFormFrontConf',
                        {
                          form: function(){
                            return scope.form;
                          },
                          filter: function(){
                            scope.setterFilters();
                            return scope.filters;
                          },
                          path: function(){
                            return scope.polygonPath;
                          },
                          formFrontConf: function(){
                            return formFrontConf;
                          },
                          updateFilters: function(){
                            if(formFrontConf && vm.formFrontConf && formFrontConf.id == vm.formFrontConf.id){
                              return true
                            }
                            return false;
                          }
                        }
                      )

                      modalInstance.result.then(function (value) {
                          if(value == 'ok'){
                            getFormFrontConfs();
                          }
                        }, function (error) {
                      });
                    }

                    vm.setFormFrontConf = function(formFrontConf, setActive){
                      vm.formFrontConf = formFrontConf;
                      if (setActive) {
                        vm.formFrontConf.active = setActive;
                      }
                      angular.forEach(vm.formFrontConfs, function(_formFrontConf){
                        if(_formFrontConf.id != formFrontConf.id){
                          _formFrontConf.active = false;
                        }else{
                          if(vm.formFrontConf.active){
                            vm.applyFormFrontConf(vm.formFrontConf);  
                          }else{
                            vm.dropFilters(false);
                          }
                        }
                      })
                    }

                    vm.setDefaultFilter = function(formFrontConf){
                      var formUser = angular.copy(scope.formUser);
                      formUser.userId = formUser.user.id;
                      delete formUser.user;
                      if(formFrontConf.default){
                        formUser.formFrontConfId = null;
                        FormUser.update({}, formUser, function(response){
                          formFrontConf.default = false;
                        })
                      }else{
                        formUser.formFrontConfId = formFrontConf.id;
                        FormUser.update({}, formUser, function(response){
                          formFrontConf.default = true;
                          angular.forEach(vm.formFrontConfs, function(val){
                            if(val.id != formFrontConf.id){
                              val.default = false;
                            }
                          })
                        })  
                      }
                      
                    }

                    function getFormFrontConfs(){
                      if(scope.form){
                        FormFrontConf.query({formId: scope.form.id},function(response){
                          vm.formFrontConfs = response
                          angular.forEach(vm.formFrontConfs, function(formFrontConf){
                            formFrontConf.default = formFrontConf.id == scope.formUser.formFrontConfId;
                            if (formFrontConf.default) {
                              vm.setFormFrontConf(formFrontConf, true);
                            }
                          })
                        })  
                      }
                    }
                    
                    vm.setTempTypeAndObject = function(field, object){
                      vm.field = field;
                      vm.object = object;
                    }

                    vm.getMarkerIcon = function(icon){
                      return $compile("<icon-svg icon="+icon+"></icon-svg")(scope);
                    }

                    vm.changeColorState = function(object, type){
                      vm.setTempTypeAndObject(type, object);
                      cleanColors(vm.selectedPicksColor.field, vm.selectedPicksColor.object);
                      switch(type){
                        case "item":
                          object.colors = !object.colors;
                          angular.forEach(scope.itemsFilterable, function(item){
                            if(object.id != item.id){
                              delete item.colors;
                            }
                          })
                          delete vm.colorsUserState.colors;
                          delete vm.colorsActionState.colors;
                          if(!object.colors){
                            vm.selectedPicksColor = {field: undefined, object: undefined, colors: []}
                          }
                        break;
                        case "user":
                          object.colors = !object.colors;
                          angular.forEach(scope.itemsFilterable, function(item){
                            delete item.colors;
                          })
                          delete vm.colorsActionState.colors;
                          if(!vm.colorsUserState.colors){
                            vm.selectedPicksColor = {field: undefined, object: undefined, colors: []}
                          }
                        break;
                        case "action":
                          object.colors = !object.colors;
                          angular.forEach(scope.itemsFilterable, function(item){
                            delete item.colors;
                          })
                          delete vm.colorsUserState.colors;
                          if(!vm.colorsActionState.colors){
                            vm.selectedPicksColor = {field: undefined, object: undefined, colors: []}
                          }
                        break;
                      }
                    }

                    vm.changeIconState = function(object, type){
                      var deferred = $q.defer();
                      vm.setTempTypeAndObject(type, object);
                      cleanIcons(vm.selectedPicksIcon.field, vm.selectedPicksIcon.object).then(function(){
                        switch(type){
                          case "item":
                            object.icons = !object.icons;
                            angular.forEach(scope.itemsFilterable, function(item){
                              if(object.id != item.id){
                                delete item.icons;
                              }
                            })
                            delete vm.iconsUserState.icons;
                            delete vm.iconsActionState.icons;
                            if(!object.icons){
                              vm.selectedPicksIcon = {field: undefined, object: undefined, icons: []}
                            }
                            deferred.resolve();
                          break;
                          case "user":
                            object.icons = !object.icons;
                            angular.forEach(scope.itemsFilterable, function(item){
                              delete item.icons;
                            })
                            delete vm.iconsActionState.icons;
                            if(!vm.iconsUserState.icons){
                              vm.selectedPicksIcon = {field: undefined, object: undefined, icons: []}
                            }
                            deferred.resolve();
                          break;
                          case "action":
                            object.icons = !object.icons;
                            angular.forEach(scope.itemsFilterable, function(item){
                              delete item.icons;
                            })
                            delete vm.iconsUserState.icons;
                            if(!vm.iconsActionState.icons){
                              vm.selectedPicksIcon = {field: undefined, object: undefined, icons: []}
                            }
                            deferred.resolve();
                          break;
                        }
                      });
                      return deferred.promise;
                    }

                    vm.applyFormFrontConf = function(formFrontConf){
                      vm.dropFilters(false);
                      $timeout(function() {
                        if(formFrontConf.createdDateTo){
                          vm.createdDateTo = new Date(formFrontConf.createdDateTo);
                        }
                        if(formFrontConf.createdDateFrom){
                          vm.createdDateFrom = new Date(formFrontConf.createdDateFrom);
                        }          
                        if(formFrontConf.updatedDateTo){
                          vm.updatedDateTo = new Date(formFrontConf.updatedDateTo);
                        }
                        if(formFrontConf.updatedDateFrom){
                          vm.updatedDateFrom = new Date(formFrontConf.updatedDateFrom);
                        }
                        if(formFrontConf.assignedDateTo){
                          vm.assignedDateTo = new Date(formFrontConf.assignedDateTo);
                        }
                        if(formFrontConf.assignedDateFrom){
                          vm.assignedDateFrom = new Date(formFrontConf.assignedDateFrom);
                        }
                        if(formFrontConf.sort){
                          scope.filters.sort = formFrontConf.sort;
                        }
                        if(formFrontConf.specific){
                          var itemsValues = formFrontConf.specific.split(";");
                          angular.forEach(itemsValues, function(itemValues){
                            var itemId = itemValues.split("&")[0].replace("itemId:","");
                            var val = itemValues.split("&")[1].replace("value:","");
                            var item = $filter("filter")(scope.itemsFilterable, {id: itemId})[0];
                            vm.toClearFilter.push({object: item, type: "item"})
                            vm.filtersSpecific[itemId] = {
                              value: val == 'null' ? null : val,
                              type: item.type
                            };
                            switch(item.type){
                              case "text":
                                item.value = val;
                                break;
                              case "address":
                                item.value = val;
                                break;
                              case "autoincremental":
                                item.value = val;
                                break;
                              case "date":
                                item.value = new Date(val);
                                break;
                              case "select":
                                var valuesSelect = val.split(",");
                                angular.forEach(valuesSelect, function(val){
                                  if (val == 'null') {
                                    val = null
                                  }
                                  var option = $filter("filter")(item.values, {value: val})[0];
                                  option.checked = true;
                                  filterChildValues(item, val, "add");
                                })
                                break;
                              case "checkbox":
                                var valuesCheckbox = val.split(",");
                                angular.forEach(valuesCheckbox, function(value){
                                  if(value == "true" || value == true){
                                    item.valueTrue = true;
                                  }
                                  if(value == "false" || value == false){
                                    item.valueFalse = true;
                                  }
                                })
                                break;
                            }
                          })
                        }
                        if(formFrontConf.users){
                          vm.toClearFilter.push({object: scope.users, type: "user"})
                          var usersId = formFrontConf.users.split(",");
                          vm.selectedCreators = formFrontConf.users;
                          angular.forEach(usersId, function(userId){
                            var user = $filter('filter')(scope.users, {id: userId})[0];
                            user.checked = true;
                          })
                        }
                        if(formFrontConf.actions){
                          vm.toClearFilter.push({object: scope.actions, type: "action"})
                          var actionsId = formFrontConf.actions.split(",");
                          vm.selectedActions = formFrontConf.actions;
                          angular.forEach(actionsId, function(actionId){
                            if(actionId == "null"){
                              var action = $filter("filter")(scope.actions, {name: "Sin acción"})[0];
                              action.checked = true;
                            }else{
                              var action = $filter("filter")(scope.actions, {id: actionId})[0];
                              action.checked = true;
                            }
                          })
                        }
                        if(formFrontConf.pickColor){
                          populatePicksColors(formFrontConf.pickColor);
                        }
                        if(formFrontConf.pickIcon){
                          populatePicksIcons(formFrontConf.pickIcon);  
                        }
                        if(formFrontConf.path){
                          scope.polygonPath = formFrontConf.path;
                        }else{
                          scope.selectedFormRecords = undefined;
                          scope.polygonPath = undefined;
                        }
                      }, 1000)
                    }

                    scope.setterFilters = function(){
                      scope.filters.sort = "pickerCreatedAt,desc";
                      if(scope.form != undefined){
                        scope.filters.formId = scope.form.id;
                      }else{
                        vm.findError = true;
                        return;
                      }

                      if(vm.createdDateFrom != undefined && vm.createdDateFrom != null){
                        scope.filters.createdDateFrom = vm.createdDateFrom.toISOString();
                      }else{
                        delete scope.filters.createdDateFrom;
                      }

                      if(vm.createdDateTo != undefined && vm.createdDateTo!= null){
                        scope.filters.createdDateTo = vm.createdDateTo.toISOString();
                      }else{
                        delete scope.filters.createdDateTo;
                      }

                      if(vm.updatedDateFrom != undefined && vm.updatedDateFrom != null){
                        scope.filters.updatedDateFrom = vm.updatedDateFrom.toISOString();
                      }else{
                        delete scope.filters.updatedDateFrom;
                      }

                      if(vm.updatedDateTo != undefined && vm.updatedDateTo!= null){
                        scope.filters.updatedDateTo = vm.updatedDateTo.toISOString();
                      }else{
                        delete scope.filters.updatedDateTo;
                      }

                      if(vm.assignedDateFrom != undefined && vm.assignedDateFrom != null){
                        scope.filters.assignedDateFrom = vm.assignedDateFrom.toISOString();
                      }else{
                        delete scope.filters.assignedDateFrom;
                      }

                      if(vm.assignedDateTo != undefined && vm.assignedDateTo!= null){
                        scope.filters.assignedDateTo = vm.assignedDateTo.toISOString();
                      }else{
                        delete scope.filters.assignedDateTo;
                      }

                      if(vm.formRecordId != null && vm.formRecordId != undefined){
                        scope.filters.formRecordId = vm.formRecordId;
                      }else{
                        delete scope.filters.formRecordId;
                      }

                      if(!DataUtils.isEmpty(vm.filtersSpecific)){
                        scope.filters.specific = "";
                        for(var key in vm.filtersSpecific){
                          scope.filters.specific = scope.filters.specific+"itemId:"+key+"&value:"+vm.filtersSpecific[key].value+"&type:"+vm.filtersSpecific[key].type+";";
                        }
                        scope.filters.specific = scope.filters.specific.slice(0,-1);
                      }else{
                        delete scope.filters.specific;
                      }

                      if(vm.selectedCreators.length > 0){
                        scope.filters.usersId = vm.selectedCreators.toString();
                      }else{
                        delete scope.filters.usersId;
                      }

                      if(vm.selectedActions=="" || vm.selectedActions == undefined){
                          delete scope.filters.actions;
                      }else{
                        scope.filters.actions = vm.selectedActions;
                      }

                      if(vm.selectedPicksColor.object != undefined){
                        scope.filters.picksColor = angular.copy(vm.selectedPicksColor);
                        scope.filters.picksColor.fieldId = vm.selectedPicksColor.object.id;
                        scope.filters.picksColor.id = null;
                        scope.filters.picksColor.formId = scope.form.id;
                        delete scope.filters.picksColor.object;
                      }else{
                        delete scope.filters.picksColor;
                      }

                      if(vm.selectedPicksIcon.object != undefined){
                        scope.filters.picksIcon = angular.copy(vm.selectedPicksIcon);
                        scope.filters.picksIcon.fieldId = vm.selectedPicksIcon.object.id;
                        scope.filters.picksIcon.id = null;
                        scope.filters.picksIcon.formId = scope.form.id;
                        delete scope.filters.picksIcon.object;
                      }else{
                        delete scope.filters.picksIcon;
                      }

                      if(scope.selectedFormRecords != undefined && scope.selectedFormRecords != null && scope.selectedFormRecords.length > 0){
                        scope.filters.formRecordIds = scope.selectedFormRecords.toString();
                      }else{
                        delete scope.filters.formRecordIds;
                      }
                    }

                    function addPickColor(field, object, value, color){
                      switch(field){
                        case "item":
                          if(vm.selectedPicksColor.field == "item" && vm.selectedPicksColor.object == object){
                            var lastColor = $filter('filter')(vm.selectedPicksColor.colors, {value: value})[0];
                            if(lastColor){
                              lastColor.color = color;
                            }else{
                              vm.selectedPicksColor.colors.push({value: value, color: color});
                            }
                          }else{
                            vm.selectedPicksColor = {
                              field: field,
                              object: object,
                              colors: [{
                                value: value,
                                color: color
                              }]
                            }
                          }
                          break;
                        case "user":
                          if(vm.selectedPicksColor.field == "user"){
                            var lastColor = $filter('filter')(vm.selectedPicksColor.colors, {value: value})[0];
                            if(lastColor){
                              lastColor.color = color;
                            }else{
                              vm.selectedPicksColor.colors.push({value: value, color: color});
                            }
                          }else{
                            vm.selectedPicksColor = {
                              field: field,
                              object: object,
                              colors: [{
                                value: value,
                                color: color
                              }]
                            }
                          }
                          break;
                        case "action":
                          if(vm.selectedPicksColor.field == "action"){
                            var lastColor = $filter('filter')(vm.selectedPicksColor.colors, {value: value})[0];
                            if(lastColor){
                              lastColor.color = color;
                            }else{
                              vm.selectedPicksColor.colors.push({value: value, color: color});
                            }
                          }else{
                            vm.selectedPicksColor = {
                              field: field,
                              object: object,
                              colors: [{
                                value: value,
                                color: color
                              }]
                            }
                          }
                          break;
                      }
                    }

                    function cleanColors(field, object){
                      if(object != undefined){
                        switch(field){
                          case "item":
                            angular.forEach(object.values, function(value){
                              delete value.color;
                            })
                            break;
                          case "user":
                            angular.forEach(scope.users, function(user){
                              delete user.color;
                            })
                            break;
                          case "user":
                            angular.forEach(scope.actions, function(action){
                              delete action.color;
                            })
                            break;
                        }  
                      }

                      vm.selectedPicksColor = {field: undefined, object: undefined, colors: []}
                    }

                    function addPickIcon(field, object, value, icon){
                      switch(field){
                        case "item":
                          if(vm.selectedPicksIcon.field == "item" && vm.selectedPicksIcon.object == object){
                            var lastIcon = $filter('filter')(vm.selectedPicksIcon.icons, {value: value})[0];
                            if(lastIcon){
                              lastIcon.icon = icon;
                            }else{
                              vm.selectedPicksIcon.icons.push({value: value, icon: icon});
                            }
                          }else{
                            vm.selectedPicksIcon = {
                              field: field,
                              object: object,
                              icons: [{
                                value: value,
                                icon: icon
                              }]
                            }
                          }
                          break;
                        case "user":
                          if(vm.selectedPicksIcon.field == "user"){
                            var lastIcon = $filter('filter')(vm.selectedPicksIcon.icons, {value: value})[0];
                            if(lastIcon){
                              lastIcon.icon = icon;
                            }else{
                              vm.selectedPicksIcon.icons.push({value: value, icon: icon});
                            }
                          }else{
                            vm.selectedPicksIcon = {
                              field: field,
                              object: object,
                              icons: [{
                                value: value,
                                icon: icon
                              }]
                            }
                          }
                          break;
                        case "action":
                          if(vm.selectedPicksIcon.field == "action"){
                            var lastIcon = $filter('filter')(vm.selectedPicksIcon.icons, {value: value})[0];
                            if(lastIcon){
                              lastIcon.icon = icon;
                            }else{
                              vm.selectedPicksIcon.icons.push({value: value, icon: icon});
                            }
                          }else{
                            vm.selectedPicksIcon = {
                              field: field,
                              object: object,
                              icons: [{
                                value: value,
                                icon: icon
                              }]
                            }
                          }
                          break;

                      }
                    }

                    function cleanIcons(field, object){
                      var deferred = $q.defer();
                      if(object != undefined){
                        switch(field){
                          case "item":
                            angular.forEach(object.values, function(value){
                              delete value.icon
                              $("#value-"+value.id).removeClass("no-content");
                            })
                            deferred.resolve();
                            break;
                          case "user":
                            angular.forEach(scope.users, function(user){
                              delete user.icon
                              $("#value-"+user.id).removeClass("no-content");
                            })
                            deferred.resolve();
                            break;
                          case "action":
                            angular.forEach(scope.actions, function(action){
                              delete action.icon
                              $("#value-"+action.name).removeClass("no-content");
                            })
                            deferred.resolve();
                            break;
                        }  
                      }else{
                        deferred.resolve();
                      }    
                      vm.selectedPicksIcon = {field: undefined, object: undefined, icons: []}
                      return deferred.promise;
                    }

                    vm.setIcon = function(value, icon){
                      value.icon = icon; 
                      $(".icons.selected").toggleClass('selected');
                      $("#"+icon).toggleClass('selected');
                      switch(vm.field){
                        case "item":
                          $("#value-"+value.id).html(vm.getMarkerIcon(value.icon));
                          addPickIcon(vm.field, vm.object, value.value, value.icon);
                        break;
                        case "user":
                          $("#value-"+value.id).html(vm.getMarkerIcon(value.icon));
                          addPickIcon(vm.field, vm.object, value.id, icon);
                          break;
                        case "action":
                          var action = $filter('filter')(scope.actions, {name: value.name})[0];
                          var actionId = action.name == "Sin acción" ? null : action.id;
                          var indexOf = scope.actions.indexOf(value);
                          $("#value-"+indexOf).html(vm.getMarkerIcon(value.icon));
                          addPickIcon(vm.field, vm.object, actionId, icon);
                          break;
                      }
                      
                    }

                    vm.changeVisibilityState = function(item){
                      item.hidden = !item.hidden;
                      angular.forEach(scope.form.fieldSets[0].items, function(item_){
                        if(item_.id == item.id){
                          item_.hidden = item.hidden;
                        }
                      })
                    }

                    vm.changeCreatorVisibilityState = function(){
                      scope.form.creatorHidden = !scope.form.creatorHidden;
                    }

                    vm.changeLastActionVisibilityState = function(){
                        scope.form.lastActionHidden = !scope.form.lastActionHidden
                    }

                    vm.changeLastUpdateVisibilityState = function(){
                        scope.form.lastUpdateHidden = !scope.form.lastUpdateHidden
                    }

                    vm.changeAssignedAtVisibilityState = function(){
                        scope.form.assignedAtHidden = !scope.form.assignedAtHidden
                    }
                    
                    vm.changeCreatedAtVisibilityState = function(){
                        scope.form.createdAtHidden = !scope.form.createdAtHidden
                    }

                    vm.changeFormRecordIdVisibilityState = function(){
                        scope.form.formRecordIdHidden = !scope.form.formRecordIdHidden
                    }

                    vm.showUserInMap = function(user){
                      user.showInMap = !user.showInMap;
                      if(vm.createdDateFrom != undefined && vm.createdDateFrom != null){
                        user.showTrackInMapFromDate = vm.createdDateFrom;
                      }else{
                        user.showTrackInMapFromDate = undefined;
                      }
                      if(vm.createdDateTo != undefined && vm.createdDateTo != null){
                        user.showTrackInMapToDate = vm.createdDateTo;
                      }else{
                        user.showTrackInMapToDate = undefined;
                      }
                    }

                    vm.fillCount = function(item) {
                      if(item.type == "select" || item.type == "checkbox"){
                        if(!(angular.isDefined(item.counted) && item.counted)){
                          console.log('get Count');
                          getCount(item.id)
                        }
                      }
                      angular.element('#'+item.name).toggleClass('glyphicon-chevron-down');
                      angular.element('#'+item.name).toggleClass('glyphicon-chevron-up')
                    }

                    vm.fillCountAction = function(){
                      if(!vm.countActions){
                        getCountAction();
                      }
                      angular.element('#last_action').toggleClass('glyphicon-chevron-down');
                      angular.element('#last_action').toggleClass('glyphicon-chevron-up')
                    }
                    
                    vm.fillCountCreator = function(){
                      if(!vm.countCreators){
                        getCountCreator();
                      }
                      angular.element('#creator-chevron').toggleClass('glyphicon-chevron-down');
                      angular.element('#creator-chevron').toggleClass('glyphicon-chevron-up')
                    }

                    function populatePicksIcons(pickIcon){
                      switch(pickIcon.field){
                        case "item":
                          var item = $filter("filter")(scope.itemsFilterable, {id: pickIcon.fieldId})[0];
                          vm.changeIconState(item, "item").then(function(){
                            angular.forEach(pickIcon.icons, function(icon){
                              var value = $filter("filter")(item.values, {value: icon.value})[0];
                              vm.setIcon(value, icon.icon);
                            })
                          })
                        break;
                        case "user":
                          vm.changeIconState(vm.iconsUserState, "user").then(function(){
                            angular.forEach(pickIcon.icons, function(icon){
                              var user = $filter("filter")(scope.users, {id: icon.value})[0]
                              if(user){
                                vm.setTempTypeAndObject("user", user);
                                vm.setIcon(user, icon.icon);  
                              }
                            })
                          })
                        break;
                        case "action":
                          vm.changeIconState(vm.iconsActionState, "action").then(function(){
                            angular.forEach(pickIcon.icons, function(icon){
                              var action;
                              if(icon.value == null){
                                action = $filter('filter')(scope.actions, {name: "Sin acción"})[0]
                              }else{
                                action = $filter('filter')(scope.actions, {id: icon.value})[0];
                              }
                              action.icon = icon.icon;
                                vm.setTempTypeAndObject("action", action);
                                vm.setIcon(action, icon.icon);  
                              })
                          });
                        break;
                      }
                    }

                    function populatePicksColors(pickColor){
                      switch(pickColor.field){
                        case "item":
                          var item = $filter("filter")(scope.itemsFilterable, {id: pickColor.fieldId})[0];
                          vm.changeColorState(item, "item");
                          vm.setTempTypeAndObject("item", item);
                          angular.forEach(pickColor.colors, function(color){
                            var value = $filter("filter")(item.values, {value: color.value})[0];
                            value.color = color.color;
                            addPickColor("item", item, color.value, color.color);
                          })
                        break;
                        case "user":
                          var user = $filter("filter")(scope.users, {id: pickColor.fieldId})[0]
                          vm.changeColorState(vm.colorsUserState, "user");
                          angular.forEach(pickColor.colors, function(color){
                            var user = $filter("filter")(scope.users, {id: color.value})[0];
                            if(user){
                              user.color = color.color;
                              vm.setTempTypeAndObject("user", user);
                              addPickColor("user", user, color.value, color.color);  
                            }
                          })
                        break;
                        case "action":
                          vm.setTempTypeAndObject("action", action);
                          vm.changeColorState(vm.colorsActionState, "action");
                          angular.forEach(pickColor.colors, function(color){
                            var action;
                            if(color.value == null){
                              action = $filter('filter')(scope.actions, {name: "Sin acción"})[0]
                            }else{
                              action = $filter('filter')(scope.actions, {id: color.value})[0];
                            }
                            action.color = color.color;
                            vm.setTempTypeAndObject("action", action);
                            addPickColor("action", action, color.value, color.color);
                          })
                        break;
                      }
                    }

                    vm.addFilterSpecificSelect = function(item, value, checkState){
                      vm.toClearFilter.push({object: item, type: "item"});
                      if(checkState){
                        if(vm.filtersSpecific[item.id]==undefined){
                          vm.filtersSpecific[item.id] = {}
                          vm.filtersSpecific[item.id].value=value+"";
                        }else{
                          vm.filtersSpecific[item.id].value=vm.filtersSpecific[item.id].value+","+value
                        }
                        vm.filtersSpecific[item.id].type = item.subtype ? item.subtype : item.type;
                        filterChildValues(item, value, "add");
                      }else{
                        var toReplace;
                        if(vm.filtersSpecific[item.id].value.search(","+value)>-1){
                          toReplace = vm.filtersSpecific[item.id].value.substr(vm.filtersSpecific[item.id].value.search(","+value),(","+value).length);
                        }
                        if(vm.filtersSpecific[item.id].value.search(value+",")>-1 && toReplace==undefined){
                          toReplace = vm.filtersSpecific[item.id].value.substr(vm.filtersSpecific[item.id].value.search(value+","),(value+",").length);
                        }
                        if(vm.filtersSpecific[item.id].value.search(value)>-1 && toReplace==null){
                          toReplace = vm.filtersSpecific[item.id].value.substr(vm.filtersSpecific[item.id].value.search(value),(""+value).length);
                        }
                        vm.filtersSpecific[item.id].value=vm.filtersSpecific[item.id].value.replace(toReplace,"");
                        if(vm.filtersSpecific[item.id].value==""){
                          delete vm.filtersSpecific[item.id];
                        }
                        filterChildValues(item, value, "remove");
                      }
                    }

                    var filterChildValues = function(item, value, action){
                      if(action == "add"){
                        angular.forEach(scope.form.fieldSets[0].items, function(item_){
                          if(item_.type=="select" && item_.parentItemId == item.name){
                            var index = scope.itemsFilterable.findIndex(function(element){return element.id == item_.id});
                            angular.forEach(item_.values, function(value_){
                              if(value_.parentOptionId == value){
                                scope.itemsFilterable[index].values.push(angular.copy(value_));
                              }
                            });
                            scope.itemsFilterable[index].disable = false;              
                          }
                        });
                      }else{
                        angular.forEach(scope.form.fieldSets[0].items, function(item_){
                          if(item_.type=="select" && item_.parentItemId == item.name){
                            var index = scope.itemsFilterable.findIndex(function(element){return element.id == item_.id});
                            angular.forEach(item_.values, function(value_){
                              if(value_.parentOptionId == value){
                                var i = scope.itemsFilterable[index].values.indexOf(value_);
                                if(i!=-1){
                                  scope.itemsFilterable[index].values.splice(i,1);
                                }
                              }
                            });
                            if(scope.itemsFilterable[index].values.length==0){
                              scope.itemsFilterable[index].disable = true;
                            }
                          }
                        });
                      }
                    }

                    vm.addFilterSpecificInput = function(item, value){
                      if(value=="" || value==null){
                        delete vm.filtersSpecific[item.id];
                        return;
                      }else{
                        vm.filtersSpecific[item.id] = {}
                      }
                      vm.toClearFilter.push({object: item, type: "item"});
                      if(value instanceof Date){
                        vm.filtersSpecific[item.id].value=value.toISOString();
                      }else{
                        vm.filtersSpecific[item.id].value=value;
                      }
                      vm.filtersSpecific[item.id].type = item.subtype ? item.subtype : item.type;
                    }

                    vm.addFilterAction = function(actionName, checkState){
                      var action = $filter('filter')(scope.actions, {name: actionName})[0];
                      var actionId = action.name == "Sin acción" ? null : action.id;
                      vm.toClearFilter.push({object: scope.actions, type: "action"});
                      if(checkState){
                        if(vm.selectedActions==undefined){
                          vm.selectedActions = actionId+"";
                        }else{
                          vm.selectedActions = vm.selectedActions+","+actionId;
                        }
                      }else{
                        var toReplace;
                        if(vm.selectedActions.search(","+actionId)>-1){
                          toReplace = vm.selectedActions.substr(vm.selectedActions.search(","+actionId),(","+actionId).length);
                        }
                        if(vm.selectedActions.search(actionId+",")>-1 && toReplace==undefined){
                          toReplace = vm.selectedActions.substr(vm.selectedActions.search(actionId+","),(actionId+",").length);
                        }
                        if(vm.selectedActions.search(actionId)>-1 && toReplace==null){
                          toReplace = vm.selectedActions.substr(vm.selectedActions.search(actionId),(""+actionId).length);
                        }
                        vm.selectedActions=vm.selectedActions.replace(toReplace,"");
                        if(vm.selectedActions==""){
                          delete vm.selectedActions;
                        }
                      }
                    }

                    vm.addFilterCreator = function(creator, checkState){
                      var indexOf = vm.selectedCreators.indexOf(creator);
                      vm.toClearFilter.push({object: scope.users, type: "user"});
                      if(checkState){
                        if(indexOf == -1){
                          vm.selectedCreators.push(creator);
                        }  
                      }else{
                        if(indexOf != -1){
                          vm.selectedCreators.splice(indexOf, 1);
                        }
                      }
                    }

                    function getCount(itemId){
                      var deferred = $q.defer();
                      var filters_ = angular.copy(scope.filters);
                      filters_.itemId = itemId;
                      Count.query(filters_, function(response){
                        angular.forEach(response, function(count){
                          var i;
                          for (i = 0; i < scope.itemsFilterable.length; i++) { 
                            var j;
                            if(scope.itemsFilterable[i].type == "select" && scope.itemsFilterable[i].id == count.itemId){
                              var found = false;
                              for (j = 0; j < scope.itemsFilterable[i].values.length; j++) {
                                if(count.itemOptionId == scope.itemsFilterable[i].values[j].id){
                                  found = true;
                                  scope.itemsFilterable[i].values[j].count = count.count;
                                  scope.itemsFilterable[i].counted = true;
                                }
                              }
                              if (!found) {
                                for (j = 0; j < scope.itemsFilterable[i].values.length; j++) {
                                  if(count.value == scope.itemsFilterable[i].values[j].value){
                                    found = true;
                                    scope.itemsFilterable[i].values[j].count = count.count;
                                    scope.itemsFilterable[i].counted = true;
                                  }
                                }
                              }
                            } else if(scope.itemsFilterable[i].type == "checkbox" && scope.itemsFilterable[i].id == count.itemId){
                              if(count.value == "false"){
                                scope.itemsFilterable[i].counted = true;
                                scope.itemsFilterable[i].false = count.count;
                              }else{
                                scope.itemsFilterable[i].counted = true;
                                scope.itemsFilterable[i].true = count.count;
                              }
                            }
                          }
                        })
                        deferred.resolve(true);
                      })
                      return deferred.promise;
                    }

                    function getCountAction(){
                      var deferred = $q.defer();
                      CountAction.query(scope.filters, function(response){
                          vm.actionsCounted=true;
                          angular.forEach(response, function(countAction){
                            countAction.value = countAction.value == null ? "Sin acción" : countAction.value;  
                          });
                          angular.forEach(scope.actions, function(action){
                            var countAction = $filter('filter')(response, {value: action.name})[0]
                            if(countAction != undefined){
                              action.count = countAction.count;
                            }else{
                              delete action.count;
                            }
                          })
                        deferred.resolve();
                      });
                      return deferred.promise;
                    }

                    function getCountCreator(){
                      var deferred = $q.defer();
                      CountCreator.query(scope.filters, function(response){
                        angular.forEach(scope.users, function(user){
                          var countCreator = $filter('filter')(response, function(value, index, array){
                            return value.value == user.id;
                          })[0];       
                          vm.creatorsCounted = true;
                          if(countCreator != undefined){
                            user.count = countCreator.count;
                          }else{
                            delete user.count;
                          }
                        })
                        deferred.resolve();
                      });
                      return deferred.promise;
                    }

                    scope.updaterCounts = function(){
                      var i;
                      for(i = 0; i<scope.itemsFilterable.length;i++){
                        if((scope.itemsFilterable[i].type == "select" || scope.itemsFilterable[i].type == "checkbox") && scope.itemsFilterable[i].counted){
                          scope.itemsFilterable[i].counted = false;
                          if(scope.itemsFilterable[i].type == "select"){
                            var j;
                            for(j = 0; j<scope.itemsFilterable[i].values.length; j++){
                              delete scope.itemsFilterable[i].values[j].count;
                            }
                          }else if(scope.itemsFilterable[i].type == "checkbox"){
                            delete scope.itemsFilterable[i].false;
                            delete scope.itemsFilterable[i].true;
                          }
                          vm.fillCount(scope.itemsFilterable[i]);
                        }
                      }
                      if(vm.actionsCounted){
                        getCountAction();
                      }
                      if(vm.creatorsCounted){
                        getCountCreator();
                      }
                    }

                    /*scope.$watch('formUser', function(newVal){
                      if(newVal && scope.formUser.formFrontConfId){
                        angular.forEach(vm.formFrontConfs, function(formFrontConf){
                          formFrontConf.default = formFrontConf.id == scope.formUser.formFrontConfId;
                          if(formFrontConf.default){
                            vm.setFormFrontConf(formFrontConf);
                          }
                        })
                      }
                    }, true)*/

                    getFormFrontConfs();
                }
            };
        }
        ]
    )
    }
)();