(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('tabList',
        [
          '$rootScope',
          '$q',
          '$filter',
          '$http',
          '$compile',
          'FormRecord',
          'FormHelper',
          'ModalHelper',
          'MapMarker'
          ,function (
            $rootScope,
            $q,
            $filter,
            $http,
            $compile,
            FormRecord,
            FormHelper,
            ModalHelper,
            MapMarker) {
            return {
              /* Only use as <tab-list> tag */
              restrict: 'E',

              /* Our template */
              templateUrl: 'app/entities/form-record-searcher/tab-list/tab-list.html',

              /* Attributes to set */    
              scope: {
                 'filters': '=',
                 'form': '=',
                 'forms': '=',
                 'actions': '=',
                 'users': '=',
                 'onUpdate': '=',
                 'total': '=',
                 'picks': '=',
                 'needUpdate': '=',
                 'permissions': '='
              },

              link: function (scope, element, attrs) {
                  var vm = {};

                  scope.isOpen = false;
                  scope.vm = vm;
                  vm.permissions = scope.permissions;
                  
                  scope.selectedFormRecords = []

                  scope.selectedAllFormRecords = false;

                  scope.$watch('form', function(newVal, oldVal){
                      if((newVal) != undefined){
                        if(oldVal != undefined && newVal.id != oldVal.id){
                          scope.selectedFormRecords = [];
                        }else{
                          if(scope.picks){
                            getFormRecordDataTable(newVal);  
                          }
                        }
                      }
                  }, true)

                  scope.$watch('picks', function(newVal){
                    if(newVal != undefined){
                      vm.picks = newVal;
                    }
                  }, true)


                  scope.$watch('needUpdate', function(newVal){
                    if(newVal != undefined){
                      scope.selectedFormRecords = [];
                      getFormRecordDataTable(scope.form);
                    }
                  }, true)

                  scope.$watch('selectedFormRecords', function(newVal){
                    if(newVal.length > 0){
                      if($.fn.DataTable.isDataTable('table.display')){
                        if(vm.permissions.exportFormRecords){
                          $('table.display').DataTable().button(0).enable();  
                        }
                        if(vm.permissions.editFormRecords){
                          $('table.display').DataTable().button(1).enable();  
                        }
                        if(vm.permissions.deleteFormRecords){
                          $('table.display').DataTable().button(3).enable();  
                        }
                        if(vm.permissions.executeActions || vm.permissions.assignFormRecords){
                          $('table.display').DataTable().button(2).enable();  
                        }
                      }
                    }else{
                      if($.fn.DataTable.isDataTable('table.display')){
                        $('table.display').DataTable().buttons().disable();
                      }  
                    }
                  },true)

                  scope.hidePopover = function(){
                    scope.isOpen = false;
                  }

                  vm.filterItemToTable = function (item){
                    if ((item.type == 'select' || item.type == 'text' || item.type == "map" || item.type == "checkbox" || item.type == "date" || item.type == "autoincremental" || item.type == "textarea" || item.type == "address") && !item.hidden){
                      if(item.visible){
                        if(item.visible == "web" || item.visible == "both"){
                          return item; 
                        }
                      }else{
                        return item; 
                      }
                    }
                    return false;       
                  }
                  
                  vm.filterItemRecordToTable = function (form){
                    return function (itemRecord) {
                      var item = $filter('filter')(form.fieldSets[0].items, {id: itemRecord.itemId}, true)[0];
                          if (vm.filterItemToTable(item)){
                              return itemRecord;
                          }
                          return false; 
                      }               
                  }
                          
                  function getFormColumns(form){
                    var columns = [];
                    var columnsLatLng = []
                    columns.push({mData: 'select', sTitle: "<input type='checkbox' class='select-all-form-records ng-empty'>"})
                    columns.push({mData: 'verEditar', sTitle: 'Ver / Editar'})
                    columns.push({mData: 'icon', title: ""});
                    if(!form.creatorHidden){
                      columns.push({mData: 'user', sTitle: 'Usuario asig.'})  
                    }
                    angular.forEach(scope.form.fieldSets[0].items, function(item){
                      item = vm.filterItemToTable(item);
                      if (item){
                        if(item.type == "map"){
                          columnsLatLng.push({mData: "latitud", sTitle: "Latitud"});
                          columnsLatLng.push({mData: "longitud", sTitle: "Longitud"})
                        }else{
                          columns.push({mData: item.name, sTitle: item.label});  
                        }
                      }
                    });
                    if(!form.createdAtHidden){
                      columns.push({mData: 'fecha', sTitle: 'Creación'});
                    }
                    if(!form.lastUpdateHidden){
                      columns.push({mData: 'last_update', sTitle: 'Última modificación'});  
                    }
                    if(!form.assignedAtHidden){
                      columns.push({mData: 'assigned_at', sTitle: 'Fecha de asignación'});
                    }
                    if(!form.lastActionHidden){
                      columns.push({mData: "last_action", sTitle: 'Última acción'});  
                    }
                    columns = columns.concat(columnsLatLng);                   
                    if(!form.formRecordIdHidden){
                      columns.push({mData: 'id', sTitle: 'ID'});
                    }
                    return  columns;
                  }
                          
                  function getFormData(formRecords){
                    var deferred = $q.defer();
                    var data = [];
                    angular.forEach(formRecords, function(formRecord){
                      var formRecordData = {};
                      formRecordData["select"] = "<input type='checkbox' class='select-form-record "+ ($filter('filter')(scope.selectedFormRecords, formRecord.id).length > 0 ? "ng-not-empty" : "ng-empty" )+"' data-form-record-id='"+formRecord.id+"' "+ ($filter('filter')(scope.selectedFormRecords, formRecord.id).length > 0 ? "checked" : "" )+">"
                      formRecordData["verEditar"] = '<div class=""col-md-4 text-center btn-group flex-btn-group-container"> <button class="btn btn-theme btn-sm form-record-action-datatable" data-action="show" data-id="'+formRecord.id+'" data-form-id="'+formRecord.formId+'"><span class="glyphicon glyphicon-'+(vm.permissions.editFormRecord ? 'pencil':'eye-open')+'"></span> </button> </div>';
                      formRecordData["icon"] = '<img width="20px" height="20px" style="margin-top: -6px" src="data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(MapMarker.getMarkerIconAndColor(formRecord.icon, formRecord.color))+'">';
                      formRecordData["id"] = formRecord.id.toString();
                      formRecordData["fecha"] = "<span class='long-text'>"+$filter('date')(formRecord.createdAt, 'dd/MM/yyyy HH:mm:ss')+"</span>";
                      formRecordData["last_update"] =  "<span class='long-text'>"+$filter('date')(formRecord.updatedAt, 'dd/MM/yyyy HH:mm:ss')+"</span>";  
                      formRecordData["assigned_at"] = formRecord.assignedAt ?  "<span class='long-text'>"+$filter('date')(formRecord.assignedAt, 'dd/MM/yyyy HH:mm:ss')+"</span>" : "(Sin valor)";  
                      formRecordData["user"] = formRecord.userName;  
                      angular.forEach(scope.form.fieldSets[0].items, function(item){
                        var itemRecord = $filter('filter')(formRecord.fieldSetRecords[0].itemRecords, {itemId: item.id}, true)[0];
                        if (angular.isDefined(itemRecord) && angular.isDefined(itemRecord.value)){
                          if (vm.filterItemToTable(item)){
                            if(item.type == "map"){
                              if (itemRecord.value && itemRecord.value.indexOf('|') > -1) {
                                formRecordData["latitud"] = itemRecord.value.split("|")[0];
                                formRecordData["longitud"] = itemRecord.value.split("|")[1];
                              } else {
                                formRecordData["latitud"] = "(Sin valor)"
                                formRecordData["longitud"] = "(Sin valor)"
                              }
                            } else if (item.type == "checkbox") {
                              formRecordData[item.name] = itemRecord.value == 'true' ? 'SI' : (itemRecord.value == 'false' ? 'NO' : '(Sin valor)');
                            }else if(item.type=="date"){
                              formRecordData[item.name] =  $filter('date')(itemRecord.value, 'dd/MM/yyyy');
                            }else if(item.type=="textarea"){
                              if(itemRecord.extraItemRecord && itemRecord.extraItemRecord.longValue){
                                formRecordData[item.name] =  "<span class='long-text'>"+itemRecord.extraItemRecord.longValue+"</span>";
                              }else if (itemRecord.labelValue){
                                formRecordData[item.name] =  "<span class='long-text'>"+itemRecord.labelValue+"</span>";
                              } else {
                                formRecordData[item.name] = "(Sin valor)"
                              }
                            }else{
                              formRecordData[item.name] = "<span class='long-text'>"+itemRecord.labelValue;
                              if(item.externalURL && item.externalURL.includes('internal-options') && itemRecord.itemOptionId != undefined){
                                var query = item.externalURL.split('\?')[1];
                                var params = query.split('\&');
                                var formId;
                                angular.forEach(params, function(param){
                                  var key = param.split("=")[0];
                                  var value = param.split("=")[1]
                                  if(key == "formId" ){
                                    formId = value;
                                  }
                                })
                                formRecordData[item.name] = '<span class="long-text"><a class="internal-options-link-datatable" data-form-id="'+formId+'"" data-form-record-id="'+itemRecord.itemOptionId+'"><span class="glyphicon glyphicon-new-window"></span><a> '+itemRecord.labelValue;
                              } 
                              formRecordData[item.name] += '</span>';
                            }
                          }else{
                            formRecordData[item.name] = "";
                          }
                        }else{
                          formRecordData[item.name] = "";
                        }
                      })
                        var action = formRecord.action == null ? "Sin acción" : formRecord.action;
                        var timestampAction = formRecord.timestampAction == null ? "" : formRecord.timestampAction; 
                        formRecordData['last_action'] = "<span class='long-text'>"+action+' '+$filter('date')(timestampAction, 'dd/MM/yyyy HH:mm:ss')+'</span>';
                      
                      data.push(formRecordData);
                    });
                    deferred.resolve(data);
                    return deferred.promise;
                  }
                  
                  $("tab-list").on("click", ".form-record-action-datatable", function(){  
                      var isUpdatable = true;
                      if(scope.form.properties.updatable && scope.form.properties.updatable == "none"){
                          isUpdatable = false;
                      }
                      if(isUpdatable && vm.permissions.editFormRecord){
                        vm.editFormRecord($(this).data("id"), undefined, scope.form);
                      }else{
                        vm.showFormRecord($(this).data("id"), scope.form);  
                      }
                  });

                  $("tab-list").on("click", ".internal-options-link-datatable", function(){ 
                      var formRecordId = $(this).data("form-record-id");
                      var form = $filter('filter')($rootScope.forms, {id: $(this).data("form-id")})[0]
                      FormHelper.getFormWithExternalOptions(form.id).then(
                        function(form) {
                          var isUpdatable = true;
                          if(form.properties.updatable && form.properties.updatable == "none"){
                            isUpdatable = false;
                          }
                          if(isUpdatable){
                            vm.editFormRecord(formRecordId, undefined, form);                          
                          }else{
                            vm.showFormRecord(formRecordId, form);  
                          }
                        }

                      )
                  });
                  
                  $("tab-list").on("click", ".select-form-record", function(){
                    if($(this).is(':checked')){
                      if($filter('filter')(scope.selectedFormRecords, $(this).data("form-record-id")).length == 0){
                        scope.selectedFormRecords.push(Number($(this).data("form-record-id")))
                        scope.$apply();
                        if(scope.selectedFormRecords.length == vm.picks.length){
                          $(".select-all-form-records").prop('checked', true);
                          $(".select-all-form-records").removeClass("ng-empty");
                          $(".select-all-form-records").addClass("ng-not-empty");
                        }
                        $(this).addClass("ng-not-empty");
                        $(this).removeClass("ng-empty");
                      }
                    }else{
                      if($filter('filter')(scope.selectedFormRecords, $(this).data("form-record-id").length > 0)){
                        var index = scope.selectedFormRecords.indexOf(Number($(this).data("form-record-id")))
                        scope.selectedFormRecords.splice(index, 1);
                        scope.$apply();
                        $(".select-all-form-records").prop('checked', false);
                        $(".select-all-form-records").addClass("ng-empty");
                        $(".select-all-form-records").removeClass("ng-not-empty");
                        $(this).removeClass("ng-not-empty");
                        $(this).addClass("ng-empty");
                      }
                    }
                  })

                  $("tab-list").on("click", ".select-all-form-records",function(){
                    console.log("click")
                    if($(this).is(":checked")){
                      scope.selectedAllFormRecords = true;
                      scope.selectedFormRecords = [];
                      angular.forEach(vm.picks, function(pick){
                        scope.selectedFormRecords.push(pick.id);
                        $(".select-form-record[data-form-record-id='"+pick.id+"']").prop('checked', true);
                        $(".select-form-record[data-form-record-id='"+pick.id+"']").removeClass("ng-empty");
                        $(".select-form-record[data-form-record-id='"+pick.id+"']").addClass("ng-not-empty");
                        $(".select-all-form-records").removeClass("ng-empty");
                        $(".select-all-form-records").addClass("ng-not-empty");
                      })
                      scope.$apply();
                    }else{
                      $(".select-all-form-records").addClass("ng-empty");
                      $(".select-all-form-records").removeClass("ng-not-empty");
                      scope.selectedAllFormRecords = false
                      scope.selectedFormRecords = []
                      scope.$apply();
                      $(".select-form-record").prop('checked', false);
                      $(".select-form-record").addClass("ng-empty");
                      $(".select-form-record").removeClass("ng-not-empty")
                    }
                  })

                  function getFormRecords(page, size){
                    console.log("getFormRecords");
                    $rootScope.showLoading("Buscando respuestas...")
                    var deferred = $q.defer();
                    var filters = scope.filters != undefined ? angular.copy(scope.filters) : {};
                    filters.page = page/size;
                    filters.size = size;
                    filters.sort = "pickerCreatedAt,desc";
                    var loopPromises = []
                    FormRecord.query(filters, function(response){
                      angular.forEach(response, function(formRecord){
                        angular.forEach(formRecord.fieldSetRecords[0].itemRecords, function(itemRecord){
                          var loopDeferred = $q.defer();
                          loopPromises.push(loopDeferred.promise)
                          if(angular.isDefined(scope.form)){
                              var item = $filter('filter')(scope.form.fieldSets[0].items, {id: itemRecord.itemId}, true)[0];
                                switch(item.type){
                                case 'select':
                                  if(item.parentItemId){
                                    var itemParent = $filter('filter')(scope.form.fieldSets[0].items, {name: item.parentItemId}, true)[0];
                                    var itemRecordParent = $filter('filter')(formRecord.fieldSetRecords[0].itemRecords, {itemId: itemParent.id},true)[0];
                                    if(itemRecordParent && itemRecordParent.value){
                                      var options = $filter('filter')(item.values, {parentOptionId: itemRecordParent.value},true)  
                                      var selectedValue = $filter('filter')(options, function(option){return option.value == itemRecord.value})[0];
                                      itemRecord.labelValue = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)");
                                    }else{
                                      itemRecord.labelValue = "(Sin valor)";
                                    }
                                    
                                    
                                  }else{
                                    var selectedValue = $filter('filter')(item.values, function(option){return option.value == itemRecord.value})[0];
                                    itemRecord.labelValue = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)")
                                    itemRecord.itemOptionId = angular.isDefined(selectedValue) ? selectedValue.id : undefined;
                                  }
                                  loopDeferred.resolve()
                                  break;
                                default:
                                  itemRecord.labelValue = itemRecord.value ? itemRecord.value : "(Sin valor)";
                                  loopDeferred.resolve();
                                }  
                          }
                        });
                      })
                      $q.all(loopPromises).then(function(){
                        $rootScope.hideLoading();
                        deferred.resolve(response);
                      })
                    })
                    return deferred.promise;
                  }

                  function getFormRecordDataTable(form){
                      if ( $.fn.DataTable.isDataTable( '#datatable' )) {
                        $('#datatable').DataTable().columns().every(function(col){
                          var col = $('#datatable').DataTable().column(col);
                          var columns = getFormColumns(form)
                          var hiddenCol = $filter("filter")(columns, {mData: col.dataSrc()})[0];
                          if(hiddenCol == undefined){
                            col.visible(false);
                          }else{
                            col.visible(true);
                          }
                        });
                        $('#datatable').DataTable().draw(false);
                      }else{
                        $('#datatable').empty(); // empty in case the columns change 
                        var table = $('#datatable').DataTable( {
                          columns: getFormColumns(form),
                          searching: false,
                          info: true,
                          ordering: false,
                          pagingType: 'numbers',
                          pageLength: 20,
                          dom: 'Bfrtip',
                          buttons: [
                              {
                                  text: 'Exportar a Excel',
                                  enabled: scope.selectedFormRecords.length > 0,
                                  action: function ( e, dt, node, config ) {
                                      $rootScope.showLoading("Exportando...")
                                      var filters = angular.copy(scope.filters);
                                      angular.forEach(scope.form.fieldSets[0].items, function(item){
                                          if(item.hidden){
                                          filters.columnsHidden = filters.columnsHidden != undefined ? filters.columnsHidden + ","+item.id : item.id+"";
                                          }
                                      });
                                      filters.formRecordIds = scope.selectedFormRecords;
                                      if (filters.usersId) {
                                        filters.usersId = filters.usersId.split(',');
                                      }
                                      if (filters.actions) {
                                        filters.actions = filters.actions.split(',');
                                      }
                                      $http({
                                          url: 'api/form-records/export/excel', 
                                          method: "POST",
                                          data: filters,
                                          responseType: 'arraybuffer'
                                      }).success(function (response) {
                                          $rootScope.hideLoading();
                                          var file = new Blob([response], {type: 'application/vnd.ms-excel'});
                                          var url = window.URL || window.webkitURL;
                                          var downloadLink = angular.element('<a></a>');
                                          downloadLink.attr('href',url.createObjectURL(file));
                                          downloadLink.attr('download', 'export.xlsx');
                                          downloadLink[0].click();
                                      }).error(function(error) {
                                        console.log(error);
                                        $rootScope.hideLoading();
                                        if (error.status !== 500 && error.status !== 400) {
                                          ModalHelper.error("Ocurrió un error al exportar las respuesas seleccionadas");
                                        }
                                      })
                                  },
                                  className: "btn btn-theme btn-sm"
                              },
                              {
                                text: "Editar",
                                action: function(e, dt, node, config){
                                  scope.isOpen = false;
                                  scope.$apply();
                                  vm.editSelectedFormRecords();
                                },
                                className: "btn btn-theme btn-sm",
                                enabled: scope.selectedFormRecords.length > 0 && vm.permissions.editFormRecords
                              },
                              {
                                text: "Evento",
                                className: "btn btn-theme btn-sm",
                                enabled: scope.selectedFormRecords.length > 0,
                                init: function(dt, node, config){
                                  node.attr('uib-popover-template',"'popoverActionTemplate.html'");
                                  node.attr('popover-placement', 'bottom');
                                  node.attr('popover-enable', "selectedFormRecords.length > 0");
                                  node.attr('popover-is-open', "isOpen");
                                  $compile(node)(scope);
                                }
                              },
                              {
                                text: "Eliminar",
                                action: function(e, dt, node, config){
                                  scope.isOpen = false;
                                  scope.$apply();

                                  var modalInstance = ModalHelper.confirmation('Se van a eliminar '+scope.selectedFormRecords.length+' respuestas del formulario <b>'+scope.form.name+'</b> ¿Confirma la acción?');

                                  modalInstance.result.then(function (confirmation) {
                                    if(confirmation){
                                      $rootScope.showLoading("Eliminando registros...");
                                      FormRecord.deleteByIds(scope.selectedFormRecords,function(response){
                                        scope.selectedFormRecords = []
                                        scope.selectedAllFormRecords = false
                                        $rootScope.hideLoading();
                                        scope.onUpdate();
                                        getFormRecordDataTable(scope.form);
                                      })
                                    }
                                  }, function () {
                                
                                  });
                                },
                                className: "btn btn-danger btn-sm",
                                enabled: scope.selectedFormRecords.length > 0
                              }
                          ],
                          language: {
                              "sProcessing":     "Procesando...",
                              "sLengthMenu":     "Mostrar _MENU_ respuestas",
                              "sZeroRecords":    "No se encontraron resultados",
                              "sEmptyTable":     "Ningún dato disponible en esta tabla",
                              "sInfo":           "Mostrando del _START_ al _END_ del total de _TOTAL_",
                              "sInfoEmpty":      "Mostrando del 0 al 0 del total de 0",
                              "sInfoFiltered":   "(filtrado de _MAX_)",
                              "sInfoPostFix":    "",
                              "sSearch":         "Buscar:",
                              "sUrl":            "",
                              "sInfoThousands":  ",",
                              "sLoadingRecords": "Cargando...",
                              "oPaginate": {
                                  "sFirst":    "Primero",
                                  "sLast":     "Último",
                                  "sNext":     "Siguiente",
                                  "sPrevious": "Anterior"
                              },
                              "oAria": {
                              "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
                              "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                              },
                              buttons: {
                                copy: 'Copiar',
                                  copyTitle: 'Añadido al portapapeles',
                                  copySuccess: {
                                      _: '%d lineas copiadas',
                                      1: '1 linea copiada'
                                  }
                              }
                          },
                          serverSide: true,
                          ajax: function(data, callback, settings){
                              getFormRecords(data.start, data.length).then(function(response){
                                  getFormData(response).then(function(data){
                                    $('.table-responsive').animate({
                                        scrollTop: 0
                                    }, 100);
                                    callback({recordsTotal: scope.total, recordsFiltered: scope.total, data: data});
                                  })
                              })
                          }
                      });
                      $(".dt-buttons").addClass('datatable-header-buttons-sticky');
                      $("#datatable > thead > tr > th").addClass("datatable-header-th-sticky");
                      $("#datatable_info").addClass("table-paging-info");
                      $("#pagination").empty();
                      $("#pagination_info").empty();
                      $("#pagination").append($("#datatable_paginate"));
                      $("#pagination_info").append($("#datatable_info"));
                      $compile(angular.element('#datatable'))(scope)
                      }

                  }

                  vm.showFormRecord = function (formRecordId, form) {
                    var modalInstance = ModalHelper.open('app/entities/form-record/modal-form-record-show.html',
                      'ModalInstanceCtrl',
                      {
                        formRecordId: function () {
                          return formRecordId;
                        },
                        form: function(){
                          return form;
                        },
                        permissions: function(){
                          return vm.permissions;
                        }
                      }
                    );

                    modalInstance.result.then(function (value) {
                      if(value == 'deleted' || value == 'ok'){
                        scope.onUpdate();
                        getFormRecordDataTable(scope.form);
                      }
                    }, function (error) {
                    });
                  };

                  vm.editFormRecord = function(formRecordId, newFormRecord, form){      
                    var modalInstance = ModalHelper.open(
                      'app/entities/form-record/modal-form-record-new.html','ModalNewFormRecordCtrl',
                      {
                        form: function(){
                          return form;
                        },
                        formRecordId: function(){
                          return formRecordId;  
                        },
                        newFormRecord: function(){
                          return newFormRecord;  
                        },
                        permissions: function(){
                          return vm.permissions;
                        }
                      });

                    modalInstance.result.then(function (value) {
                      if(value == 'deleted' || value == 'ok'){
                        scope.onUpdate();
                        getFormRecordDataTable(scope.form);
                      }
                    }, function (error) {
                    });
                  }

                  /**********************Masive edit form record***********/
                  vm.editSelectedFormRecords = function(){
                    if(scope.selectedFormRecords.length > 0){
                      $rootScope.showLoading("Obteniendo respuestas...");
                      var formRecords = [];
                      var loopPromises = [];
                      angular.forEach(scope.selectedFormRecords, function(formRecordId){
                        var loopDeferred = $q.defer();
                        loopPromises.push(loopDeferred.promise);
                        FormRecord.get({id: formRecordId}, function(formRecord){
                          loopDeferred.resolve();
                          formRecords.push(formRecord);
                        })
                      })
                      $q.all(loopPromises).then(function(){
                        $rootScope.hideLoading();
                        var modalInstance = ModalHelper.open('app/entities/form-record/modal-form-record-masive-update.html',
                          'ModalFormRecordMasiveUpdate', {
                            form: function(){
                              return scope.form;
                            },
                            formRecords: function(){
                              return formRecords;  
                            },
                            actionId: function(){
                              return "not_empty_value";
                            }
                          }
                        );

                        modalInstance.result.then(function (value) {
                          if(value == "ok"){
                            getFormRecordDataTable(scope.form);
                          }
                          
                        }, function (error) {});
                      })
                    }
                    
                  }
                  /********************end masive edit form record ********/

                 scope.callbackActions = function(){
                   getFormRecordDataTable(scope.form);
                 }
              }
            }
          }
        ]
      )
    }
  )();
