(function() {
  'use strict';

  angular
      .module('pickerApp')
      .controller('DashboardViewController', DashboardViewController);

  DashboardViewController.$inject = [
      '$rootScope',
      '$state',
      '$scope',
      '$timeout',
      '$filter',
      '$q',
      'dashboard' ,
      'charts',
      'Dashboard',
      'PermissionHelper',
      'Principal',
      'ModalHelper',
      'Chart',
      'ChartSnapshot'
  ];

  function DashboardViewController (
    $rootScope,
    $state,
    $scope,
    $timeout,
    $filter,
    $q,
    dashboard,
    charts,
    Dashboard,
    PermissionHelper,
    Principal,
    ModalHelper,
    Chart,
    ChartSnapshot
  ) {
    var vm = this;

    vm.charts = charts;

    var timeoutToSave;
    var intervalCountdown;
    vm.strCountdown;
    var countdown;

    Principal.identity().then(function(user) {
        PermissionHelper.getDashboardPermissions().then(function(permissions) {
            vm.permissions = {};
            var dashboardUser = $filter('filter')(dashboard.dashboardUsers, function(du) {
                return du.user.id == user.id;
            })[0];
            angular.forEach(permissions, function(p) {
                var havePermission = $filter('filter')(dashboardUser.dashboardUserPermissions, function(dup) {
                    return dup.permissionId == p.id;
                }).length > 0;
                vm.permissions[p.code.replace('Dashboard', 'Chart')] = havePermission;
            });
        });
    });


    vm.addChart = function(){
        var modalInstance = ModalHelper.open('app/entities/chart/modal-chart-form.html',
            'ModalChartFormCtrl', {
              chart: function(){
                return null;
              }
            }
        );

        modalInstance.result.then(function (chart) {
            if(chart){           
                vm.charts.push(chart);   
            }
        });
    }

    vm.deleteChart = function(index){
        var modalInstance = ModalHelper.confirmation('¿Está seguro de eliminar el gráfico ' + vm.charts[index].name + '?');
        modalInstance.result.then(function(confirmation){
            if(confirmation){
                $rootScope.showLoading("Eliminando gráfico...");
                Chart.delete({id: vm.charts[index].id }, {}, function(response){
                    vm.charts.splice(index, 1);
                    $rootScope.hideLoading();
                }, function(error){
                    $rootScope.hideLoading();
                })
            }
        })
    }

    vm.updateAllCharts = function() {
        $rootScope.showLoading('Actualizando todos los gráficos');
        var charts = angular.copy(vm.charts);
        var loopPromises = [];
        angular.forEach(charts, function(chart) {
            var loopDeferred = $q.defer();
            loopPromises.push(loopDeferred.promise);
            ChartSnapshot.save({},{chartId: chart.id}, function(response){
                chart.chartSnapshot = response;
                loopDeferred.resolve();
            }, function(error){
                console.log('ERROR UPDATING CHART SNAPSHOT', error, chart);
                loopDeferred.resolve();
            })
        })
        $timeout(function() {
            $q.all(loopPromises).then(function() {
                vm.charts = charts;
                $rootScope.hideLoading();
            });
        }, 100);
    }

    vm.editChart = function(index){
        var modalInstance = ModalHelper.open('app/entities/chart/modal-chart-form.html',
            'ModalChartFormCtrl', {
              chart: function(){
                return vm.charts[index];
              }
            }
        );

        modalInstance.result.then(function (chart) {
            if(chart){                       
                vm.charts[index] = chart;
            }
        });
    }

    vm.hideChart = function(index){
        vm.charts[index].hidden = true;
    }

    vm.showHiddenCharts = function(){
        angular.forEach(vm.charts, function(chart){
            chart.hidden = false;
        })
    }

    vm.duplicateChart = function(index, chart){
        $rootScope.showLoading('Duplicando gráfico...');
        var newChart = angular.copy(chart);
        delete newChart.id;
        delete newChart.formFrontConf.id;
        delete newChart.chartSnapshot;
        Chart.save({}, newChart, function(response){
          newChart.id = response.id;
          newChart.formFrontConf.id = response.formFrontConf.id;
          newChart.chartSnapshot = response.chartSnapshot;
          vm.charts.splice(index+1, 0, newChart);
          $rootScope.hideLoading();
        }, function(error) {
            $rootScope.hideLoading();
        })
    }

    vm.onMove = function(item, part, index, helper) {
        $('.sv-placeholder').height(helper.element.outerHeight()-16);
    }

    var saveChartOrder = function() {
        vm.strCountdown = 'Guardando cambios...'
        clearInterval(intervalCountdown);
        clearTimeout(timeoutToSave);
        intervalCountdown = undefined;
        timeoutToSave = undefined;
        var loopPromises = [];
        angular.forEach(vm.charts, function(chart, i) {
            var loopDeferred = $q.defer();
            loopPromises.push(loopDeferred.promise);
            Chart.update({}, chart, function(response) {
                loopDeferred.resolve();
            });
        })
        $timeout(function() {
            $q.all(loopPromises).then(function() {
                vm.strCountdown = 'Guardado';
            });
        }, 100);
    }

    vm.onSort = function() {
        angular.forEach(vm.charts, function(chart, i) {
            chart.order = (i + 1);
        });
        if (timeoutToSave) {
            clearTimeout(timeoutToSave);
        }
        if (intervalCountdown) {
            clearInterval(intervalCountdown);
        }
        countdown = 5000;
        timeoutToSave = setTimeout(saveChartOrder, countdown);
        vm.strCountdown = 'Guardado automático en ' + countdown + ' segundos';
        intervalCountdown = setInterval(clock, 1000);
    }

    var clock = function() {
        countdown = countdown - 1000;
        vm.strCountdown = 'Guardado automático en ' + (countdown / 1000) + ' segundos';
        $scope.$apply();
    }
  }
})();