(function() {
  'use strict';

  angular
      .module('pickerApp')
      .factory('MapMarker', MapMarker);

  MapMarker.$inject = ['$rootScope'];

  function MapMarker($rootScope) {

    var service = {
        getMarkerIconAndColor: getMarkerIconAndColor
    };

    return service;

    function getMarkerIconAndColor(icon, color){
      color = color ? color : $rootScope.theme.iconsFiltersList;
      switch(icon){
        case "marker":
          return '<svg height="40px" width="40px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><style type="text/css">.st-marker{fill:'+color+';}.st-marker2{fill:#FFFFFF;}</style><g><path class="st-marker" d="M101,195.4c0,43.5,29,109,83.9,189.4c28.6,41.9,57.5,77.9,73.4,97c15.9-19.2,44.8-55.1,73.4-97    c54.9-80.5,83.9-146,83.9-189.4c0-25.9-4.2-50.2-12.6-72.3c-7.9-20.9-19.3-39.3-33.8-54.7c-28.7-30.5-68.1-47.2-110.9-47.2 S176.1,38,147.4,68.4c-14.5,15.4-25.9,33.8-33.8,54.7C105.3,145.1,101,169.5,101,195.4z M262.5,121.3c36.9,0,66.8,29.9,66.8,66.8 s-29.9,66.8-66.8,66.8s-66.8-29.9-66.8-66.8C195.7,151.3,225.6,121.3,262.5,121.3z"/><circle id="Elipse_29" class="st-marker2" cx="262.5" cy="188.2" r="66.8"/><path class="st-marker2" d="M258.3,21.1c-42.8,0-82.2,16.8-110.9,47.2c-14.5,15.4-25.9,33.8-33.8,54.7c-8.4,22.1-12.6,46.4-12.6,72.3 c0,43.5,29,109,83.9,189.4c28.6,41.9,57.5,77.9,73.4,97c15.9-19.1,44.8-55.1,73.4-97c54.9-80.5,83.9-146,83.9-189.4 c0-25.9-4.2-50.2-12.6-72.3c-7.9-20.9-19.3-39.3-33.8-54.7C340.5,37.9,301.1,21.1,258.3,21.1 M258.3,4.4c96.1,0,174,76.3,174,190.9 c0,114.7-174,312.2-174,312.2S84.3,310,84.3,195.4C84.3,80.7,162.2,4.4,258.3,4.4z"/></g></svg>';
        case "square":
         return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 506.1 506.1" style="enable-background:new 0 0 506.1 506.1;" xml:space="preserve"><style type="text/css">.st-square{fill:#FFFFFF;}.st-square2{fill:'+color+';}</style><g>  <path class="st-square" d="M503.5,253.1c0-138.2-112.4-250.5-250.5-250.5S2.6,114.9,2.6,253.1s112.4,250.5,250.5,250.5    S503.5,391.1,503.5,253.1z M253.1,482.6C126.5,482.6,23.4,379.6,23.4,253S126.5,23.3,253.1,23.3S482.8,126.3,482.8,253    S379.7,482.6,253.1,482.6z"/><path class="st-square2" d="M253.1,23.4c-126.6,0-229.7,103.1-229.7,229.7s103.1,229.7,229.7,229.7s229.7-103.1,229.7-229.7  S379.7,23.4,253.1,23.4z M359.2,357.4H150.4V148.6h208.8V357.4z"/><g><rect id="Rectángulo_255" x="150.4" y="148.6" class="st-square" width="208.8" height="208.8"/></g></g></svg>';
        case "star":
          return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 506.1 506.1" style="enable-background:new 0 0 506.1 506.1;" xml:space="preserve"><style type="text/css">.st-star{fill:#FFFFFF;}.st-star2{fill:'+color+';}</style><g>  <path class="st-star" d="M503.5,253.1C503.5,114.9,391.2,2.5,253,2.5S2.6,115,2.6,253.1s112.4,250.5,250.5,250.5    S503.5,391.2,503.5,253.1z M253,482.8c-126.6,0-229.6-103-229.6-229.6S126.4,23.4,253,23.4s229.6,103,229.6,229.6    S379.6,482.8,253,482.8z"/><path class="st-star2" d="M253,23.4c-126.6,0-229.6,103-229.6,229.6s103,229.7,229.6,229.7s229.6-103,229.6-229.6S379.6,23.4,253,23.4z M330.5,372.2l-77.4-40.7l-77.4,40.7l14.8-86.2l-62.6-61l86.5-12.6l38.7-78.5l38.7,78.5l86.5,12.6l-62.6,61L330.5,372.2z"/><polygon class="st-star" points="253,134 291.7,212.4 378.3,224.9 315.6,286.1 330.5,372.2 253,331.5 175.6,372.2 190.4,286.1 127.8,224.9 214.3,212.4"/></g></svg>';
        case "diamond":
          return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 506.1 506.1" style="enable-background:new 0 0 506.1 506.1;" xml:space="preserve"><style type="text/css">.st-diamond{fill:#FFFFFF;}.st-diamond2{fill:'+color+';}</style><g>  <path class="st-diamond" d="M504.6,253.9c0-138.1-112.4-250.5-250.5-250.5S3.6,115.7,3.6,253.9S116,504.4,254.1,504.4    S504.6,392,504.6,253.9z M254.1,483.5c-126.6,0-229.6-103-229.6-229.6s103-229.6,229.6-229.6s229.6,103,229.6,229.6    S380.7,483.5,254.1,483.5z"/><path class="st-diamond2" d="M254.1,24.2c-126.6,0-229.6,103-229.6,229.6s103,229.6,229.6,229.6s229.6-103,229.6-229.6    S380.7,24.2,254.1,24.2z M253,367.6L135,249.5L253,131.4l118.1,118.1L253,367.6z"/><rect id="Rectángulo_256" x="169.6" y="166" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -102.2904 252.0036)" class="st-diamond" width="167" height="167"/></g></svg>';
        case "circle": 
          return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><style type="text/css">.st-circle{fill:'+color+';}.st-circle2{fill:#FFFFFF;}</style><g><circle class="st-circle" cx="257" cy="256.5" r="229.7"/><path class="st-circle2" d="M507.5,256.5C507.5,118.3,395.2,6,257,6S6.5,118.3,6.5,256.5S118.8,507,257,507S507.5,394.6,507.5,256.5z M257,486.1c-126.6,0-229.7-103-229.7-229.7S130.4,26.8,257,26.8s229.7,103,229.7,229.7S383.6,486.1,257,486.1z"/></g><path class="st-circle2" d="M257.5,506.5c-137.9,0-250-112.1-250-250s112.1-250,250-250s250,112.1,250,250S395.4,506.5,257.5,506.5z M257.5,27.3c-126.3,0-229.2,102.8-229.2,229.2s102.8,229.2,229.2,229.2s229.2-102.8,229.2-229.2S383.8,27.3,257.5,27.3z"/><circle class="st-circle2" cx="257.5" cy="256.5" r="135.4"/></svg>';
        case "happy":
          return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 506.1 506.1" style="enable-background:new 0 0 506.1 506.1;" xml:space="preserve"><style type="text/css">.st-happy{fill:#FFFFFF;}.st-happy2{fill:'+color+';}</style><path class="st-happy" d="M504.2,253.7c0-138.1-112.4-250.5-250.5-250.5S3.2,115.6,3.2,253.7s112.4,250.5,250.5,250.5  S504.2,391.9,504.2,253.7z M253.7,483.4c-126.6,0-229.6-103-229.6-229.6s103-229.6,229.6-229.6s229.6,103,229.6,229.6  S380.3,483.4,253.7,483.4z"/><path class="st-happy2" d="M253.7,24.1c-126.6,0-229.6,103-229.6,229.6s103,229.6,229.6,229.6s229.6-103,229.6-229.6  S380.3,24.1,253.7,24.1z M347.5,131.3c22.2,0,40.2,18,40.2,40.2s-18,40.2-40.2,40.2c-22.2,0-40.2-18-40.2-40.2  S325.3,131.3,347.5,131.3z M160,131.3c22.2,0,40.2,18,40.2,40.2s-18,40.2-40.2,40.2c-22.2,0-40.2-18-40.2-40.2  S137.8,131.3,160,131.3z M388.3,313.9c-1.1,3.4-28.9,83.2-134.6,83.2c-105.7,0-133.5-79.8-134.6-83.2c-3.6-10.9,2.3-22.8,13.2-26.4 c10.9-3.7,22.6,2.2,26.4,13c0.9,2.5,20.4,54.8,95,54.8c74.8,0,94.3-52.6,95.1-54.8c3.8-10.8,15.6-16.5,26.5-12.9 C386.1,291.4,391.9,303,388.3,313.9z"/><g><circle class="st-happy" cx="347.5" cy="171.5" r="40.2"/><circle class="st-happy" cx="160" cy="171.5" r="40.2"/>  <path class="st-happy" d="M253.7,397.1c-105.7,0-133.5-79.8-134.6-83.2c-3.6-10.9,2.3-22.8,13.2-26.4c10.9-3.7,22.6,2.2,26.4,13 c0.9,2.5,20.4,54.8,95,54.8c74.8,0,94.3-52.6,95.1-54.8c3.8-10.8,15.6-16.5,26.5-12.9c10.8,3.7,16.7,15.4,13,26.3 C387.2,317.3,359.4,397.1,253.7,397.1z"/></g></svg>';
        case "fix":
          return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 506.1 506.1" style="enable-background:new 0 0 506.1 506.1;" xml:space="preserve"><style type="text/css">.st-fix{fill:'+color+';}.st-fix2{fill:#FFFFFF;}</style><g>  <circle class="st-fix" cx="254.3" cy="253.7" r="229.6"/><path class="st-fix2" d="M504.8,253.7c0-138.1-112.4-250.5-250.5-250.5S3.8,115.6,3.8,253.7s112.4,250.5,250.5,250.5    S504.8,391.9,504.8,253.7z M254.3,483.3c-126.6,0-229.6-103-229.6-229.6s103-229.6,229.6-229.6s229.6,103,229.6,229.6    S380.9,483.3,254.3,483.3z"/><path class="st-fix2" d="M175.4,99.2c-3.2,0.2-5.8,1.4-7,4.6c-1.3,3.2-0.2,5.8,2,8.1c0.6,0.6,1.2,1.2,1.8,1.8 c11.6,11.6,23.1,23.2,34.7,34.6c2.2,2.1,3,4,1.9,7.1c-4.2,12.4-8,25-11.9,37.5c-0.7,2.3-2,3.5-4.3,4.2 c-12.3,3.8-24.6,7.5-36.8,11.7c-3.4,1.1-5.3,0.6-7.8-1.9c-11.9-12.1-23.9-24-35.9-36c-2.5-2.5-5.4-3.7-8.7-2.3  c-3.3,1.3-4.4,4.2-4.4,7.5c0,2,0,4.1,0,6.1c0,12.9,2.9,25.1,8.7,36.5c18.9,36.9,60.5,54,99.9,41.1c2.3-0.8,3.6-0.4,5.3,1.3 c24.8,26.3,49.6,52.6,74.4,78.9c17.5,18.6,35,37.2,52.6,55.7c11.6,12.2,28.8,15.9,44.4,9.8c14.8-5.8,25.1-20.7,25.2-36.7 c0.1-13.3-5.8-23.7-15.4-32.6c-44.2-40.8-88.2-81.8-132.4-122.6c-2.3-2.1-2.7-3.9-1.7-6.8c5-15.4,5.5-31,1.2-46.6   c-7.5-26.9-24.6-45.4-50.3-55.8C199.6,99.8,187.7,98.4,175.4,99.2z"/></g></svg>';
        case "heart":
          return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 506.1 506.1" style="enable-background:new 0 0 506.1 506.1;" xml:space="preserve"><style type="text/css">.st-heard{fill:#FFFFFF;}.st-heard2{fill:'+color+';}</style><g>  <path class="st-heard" d="M503.5,253.1C503.5,114.9,391.2,2.6,253,2.6S2.6,114.9,2.6,253.1s112.4,250.5,250.6,250.5    S503.5,391.1,503.5,253.1z M253.1,482.6c-126.6,0-229.7-103-229.7-229.6S126.4,23.4,253.1,23.4s229.7,103,229.7,229.6    S379.7,482.6,253.1,482.6z"/><path class="st-heard2" d="M253.1,23.4c-126.6,0-229.7,103-229.7,229.6s103,229.5,229.7,229.5s229.7-103,229.7-229.6    S379.8,23.4,253.1,23.4z M367.2,282.6c-24.3,24.1-48.3,48.5-72.7,72.4c-12.4,12.1-24.8,24.6-37.2,36.7c-3.9,3.8-4.4,3.7-8.2-0.1 c-23.6-23.5-47.1-46.9-70.6-70.4c-13.4-13.3-27-26.4-40.4-39.7c-12.4-12.3-19.4-27.1-21.1-44.7c-3.7-37.7,23.6-71.2,59.8-76.9 c29.8-4.7,59.4,9.3,74.6,37.1c0.5,0.9,1.1,1.9,2.1,3.6c4.5-9.3,10.1-16.9,17.3-23.3c11.1-10,24.2-16,39-17.8 c24.2-2.9,44.6,5,61.2,22.5c10.9,11.4,17.1,25.4,18.6,41.2C391.6,246.6,383.8,266.2,367.2,282.6z"/><path class="st-heard" d="M253.2,200.5c4.5-9.3,10.1-16.9,17.3-23.3c11.1-10,24.2-16,39-17.8c24.2-2.9,44.6,5,61.2,22.5 c10.9,11.4,17.1,25.4,18.6,41.2c2.3,23.5-5.5,43-22.1,59.4c-24.3,24.1-48.3,48.5-72.7,72.4c-12.4,12.1-24.8,24.6-37.2,36.7 c-3.9,3.8-4.4,3.7-8.2-0.1c-23.6-23.5-47.1-46.9-70.6-70.4c-13.4-13.3-27-26.4-40.4-39.7c-12.4-12.3-19.4-27.1-21.1-44.7 c-3.7-37.7,23.6-71.2,59.8-76.9c29.8-4.7,59.4,9.3,74.6,37.1C251.6,197.8,252.2,198.8,253.2,200.5z"/></g></svg>';
        case "no":
          return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 506.1 506.1" style="enable-background:new 0 0 506.1 506.1;" xml:space="preserve"><style type="text/css">.st-no{fill:#FFFFFF;}.st-no2{fill:'+color+';}</style><g>  <path class="st-no" d="M503.5,253.1c0-138.1-112.4-250.5-250.5-250.5S2.6,114.9,2.6,253.1s112.4,250.5,250.5,250.5    S503.5,391.2,503.5,253.1z M253.1,482.7c-126.6,0-229.6-103-229.6-229.6s103-229.6,229.6-229.6s229.6,103,229.6,229.6    S379.7,482.7,253.1,482.7z"/><path class="st-no2" d="M253,23.4c-126.6,0-229.6,103-229.6,229.6s103,229.6,229.6,229.6s229.6-103,229.6-229.6S379.7,23.4,253,23.4z M379.7,350.2l-29.5,29.5L246.7,276.2L143.1,379.7l-29.5-29.5l103.5-103.6L113.6,143.1l29.5-29.5l103.6,103.5l103.5-103.5    l29.5,29.5L276.2,246.6L379.7,350.2z"/><polygon class="st-no" points="379.7,143.1 350.2,113.6 246.7,217.1 143.1,113.6 113.6,143.1 217.1,246.6 113.6,350.2 143.1,379.7 246.7,276.2 350.2,379.7 379.7,350.2 276.2,246.6"/></g></svg>';
        case "sad":
          return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 506.1 506.1" style="enable-background:new 0 0 506.1 506.1;" xml:space="preserve"><style type="text/css">.st-sad{fill:#FFFFFF;}.st-sad2{fill:'+color+';}</style><g><path class="st-sad" d="M503.5,253.1c0-138.1-112.4-250.5-250.5-250.5S2.6,114.9,2.6,253.1s112.4,250.5,250.5,250.5    S503.5,391.2,503.5,253.1z M253.1,482.7c-126.6,0-229.6-103-229.6-229.6s103-229.6,229.6-229.6s229.6,103,229.6,229.6    S379.7,482.7,253.1,482.7z"/><path class="st-sad2" d="M253,23.4c-126.6,0-229.6,103-229.6,229.6s103,229.6,229.6,229.6s229.6-103,229.6-229.6S379.7,23.4,253,23.4z M346.8,120.2c22.2,0,40.2,18,40.2,40.2c0,22.2-18,40.2-40.2,40.2c-22.2,0-40.2-18-40.2-40.2C306.6,138.1,324.6,120.2,346.8,120.2z M159.3,120.2c22.2,0,40.2,18,40.2,40.2c0,22.2-18,40.2-40.2,40.2c-22.2,0-40.2-18-40.2-40.2C119.1,138.1,137.1,120.2,159.3,120.2z M374.5,384.8c-10.9,3.7-22.6-2.2-26.4-13c-0.9-2.5-20.4-54.8-95-54.8c-74.8,0-94.3,52.6-95.1,54.8c-3.8,10.8-15.6,16.5-26.5,12.9 c-10.8-3.7-16.7-15.4-13-26.3c1.1-3.4,28.9-83.2,134.6-83.2c105.7,0,133.5,79.8,134.6,83.2C391.3,369.4,385.4,381.2,374.5,384.8z"/><g><circle class="st-sad" cx="346.8" cy="160.3" r="40.2"/><circle class="st-sad" cx="159.3" cy="160.3" r="40.2"/><path class="st-sad" d="M253.1,275.3c105.7,0,133.5,79.8,134.6,83.2c3.6,10.9-2.3,22.8-13.2,26.4c-10.9,3.7-22.6-2.2-26.4-13 c-0.9-2.5-20.4-54.8-95-54.8c-74.8,0-94.3,52.6-95.1,54.8c-3.8,10.8-15.6,16.5-26.5,12.9c-10.8-3.7-16.7-15.4-13-26.3 C119.6,355,147.4,275.3,253.1,275.3z"/></g></g></svg>';
        case "yes":
          return '<svg height="40px" width="40px" version="1.1" id="icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 506.1 506.1" style="enable-background:new 0 0 506.1 506.1;" xml:space="preserve"><style type="text/css">.st-yes{fill:#FFFFFF;}.st-yes2{fill:'+color+';}</style><path class="st-yes" d="M502.8,253.7c0-138.1-112.4-250.5-250.5-250.5S1.8,115.6,1.8,253.7s112.4,250.5,250.5,250.5  S502.8,391.9,502.8,253.7z M252.3,483.4c-126.6,0-229.6-103-229.6-229.6s103-229.6,229.6-229.6s229.6,103,229.6,229.6  S378.9,483.4,252.3,483.4z"/><path class="st-yes2" d="M252.3,24.1c-126.6,0-229.6,103-229.6,229.6s103,229.6,229.6,229.6s229.6-103,229.6-229.6  S378.9,24.1,252.3,24.1z M191.7,371.5l-85-85l29.5-29.5l55.5,55.5L368.4,136l29.5,29.6L191.7,371.5z"/><polygon class="st-yes" points="191.7,371.5 106.7,286.5 136.3,256.9 191.8,312.4 368.4,136 397.9,165.6 "/></svg>'
        default:
          return '<svg height="40px" width="40px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"   viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><style type="text/css">.st-default{fill:'+color+';}.st-default2{fill:#FFFFFF;}</style><g><path class="st-default" d="M101,195.4c0,43.5,29,109,83.9,189.4c28.6,41.9,57.5,77.9,73.4,97c15.9-19.2,44.8-55.1,73.4-97    c54.9-80.5,83.9-146,83.9-189.4c0-25.9-4.2-50.2-12.6-72.3c-7.9-20.9-19.3-39.3-33.8-54.7c-28.7-30.5-68.1-47.2-110.9-47.2 S176.1,38,147.4,68.4c-14.5,15.4-25.9,33.8-33.8,54.7C105.3,145.1,101,169.5,101,195.4z M262.5,121.3c36.9,0,66.8,29.9,66.8,66.8 s-29.9,66.8-66.8,66.8s-66.8-29.9-66.8-66.8C195.7,151.3,225.6,121.3,262.5,121.3z"/><circle id="Elipse_29" class="st-default2" cx="262.5" cy="188.2" r="66.8"/><path class="st-default2" d="M258.3,21.1c-42.8,0-82.2,16.8-110.9,47.2c-14.5,15.4-25.9,33.8-33.8,54.7c-8.4,22.1-12.6,46.4-12.6,72.3 c0,43.5,29,109,83.9,189.4c28.6,41.9,57.5,77.9,73.4,97c15.9-19.1,44.8-55.1,73.4-97c54.9-80.5,83.9-146,83.9-189.4 c0-25.9-4.2-50.2-12.6-72.3c-7.9-20.9-19.3-39.3-33.8-54.7C340.5,37.9,301.1,21.1,258.3,21.1 M258.3,4.4c96.1,0,174,76.3,174,190.9 c0,114.7-174,312.2-174,312.2S84.3,310,84.3,195.4C84.3,80.7,162.2,4.4,258.3,4.4z"/></g></svg>';
      }
    }
  }
})();
