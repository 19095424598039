(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('ItemType', ItemType);

    ItemType.$inject = ['$resource'];

    function ItemType ($resource) {
        var resourceUrl =  'api/item-types/:id';

        return $resource(resourceUrl, {}, {
            'query': { 
                method: 'GET', 
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    angular.forEach(data, function(itemType){
                        switch(itemType.code){
                            case "select":
                                itemType.name = "Selector de opciones";
                                break;
                            case "date":
                                itemType.name = "Fecha";
                                break;
                            case "checkbox":
                                itemType.name = "Casilla";
                                break;
                            case "map":
                                itemType.name = "Mapa";
                                break;
                            case "image":
                                itemType.name = "Imagen";
                                break;
                            case "text":
                                itemType.name = "Texto corto";
                                break;
                            case "button":
                                itemType.name = "Boton";
                                break;
                            case "header":
                                itemType.name = "Titulo";
                                break;
                            case "listCustom":
                                itemType.name = "Tipo carrito (no disponible)";
                                break;
                            case "autoincremental":
                                itemType.name = "Numeración automática";
                                break;
                            case "textarea":
                                itemType.name = "Texto largo";
                                break;
                            case "address":
                                itemType.name = "Dirección";
                                break;
                            case "drawing":
                                itemType.name = "Dibujo";
                                break;
                            case "file":
                                itemType.name = "Archivo";
                                break;
                        }
                    })
                    return data;
                }
            },
            'get': {method: 'GET'}
        });
    }
})();
