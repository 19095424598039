(function() {
    'use strict';

    angular
        .module('pickerApp')
        .factory('FileHelper', FileHelper);

    FileHelper.$inject = ['Upload', 'ModalHelper'];

    function FileHelper (Upload, ModalHelper) {
        var showError = function(fileTypeAccepted, fileTypeSelected) {
          var msg = 'El archivo seleccionado no coincide con el tipo o el tamaño permitido.'
          ModalHelper.error(msg);
          throw new Error(msg);
        }

        var setFile = function(rawFile, itemId, index, id, files, fileType, invalidFiles) {
          if (invalidFiles && invalidFiles.length > 0) {
            showError();
          }
          if (rawFile) {
            if (fileType) {
              if (fileType == 'image/*' && !rawFile.type.includes('image/')) {
                showError(fileType, rawFile.type);
              } else if (fileType != 'image/*' && !fileType.includes(rawFile.type)) {
                showError(fileType, rawFile.type);
              }
            }
            files[itemId] = files[itemId] == undefined ? [] : files[itemId];
            var file = files[itemId][index] != undefined ? files[itemId][index] : {};
            file.ngFile = rawFile;
            if (file.toDelete) {
              delete file.toDelete;
            }
            if(id!=undefined){
              file.id = id;
            }
            return Upload.base64DataUrl(rawFile).then(function(url){
              if(url){
                file.file = url.split(',')[1];
                file.extension = rawFile.name.slice(rawFile.name.lastIndexOf('.') + 1);
                file.fileContentType = rawFile.type;
                file.fileName = rawFile.name;
                files[itemId][index] = file
                return Object.keys(files[itemId]).length;  
              }else{
                delete files[itemId][index];
                return Object.keys(files[itemId]).length;
              }
            })
          } else {
            return Promise.resolve(Object.keys(files[itemId]).length);
          }
        }; 

        var deleteFile = function(itemId, index, files) {
          if (files[itemId][index] && files[itemId][index].id) {
            files[itemId][index].toDelete = true;
            delete files[itemId][index].ngFile;
          } else {
            delete files[itemId][index]  
          }
          var value = 0;
          var keys = Object.keys(files[itemId]);
          for (var i = 0; i < keys.length; i++ ){
            if (!files[itemId][keys[i]].toDelete) {
              value += 1;
            }
          }
          return value;
        };

        var openFileSelector = function(id) {
          var el = angular.element('#'+id);
          el[0].click();
        }

        return {
            setFile: setFile,
            deleteFile: deleteFile,
            openFileSelector: openFileSelector
        }
    }
})();