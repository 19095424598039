(function () {
    'use strict';

    angular
        .module('pickerApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'getAll': {url: 'api/users/all', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'setActiveStatus': {method: 'PUT', url: 'api/users/set-active-status'},
            'setApiKey': {method: 'PUT', url: 'api/users/set-api-key'},
            'deleteApiKey': {method: 'PUT', url: 'api/users/delete-api-key'}
        });

        return service;
    }
})();
