(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('selectPicker',
        ['$q',function ($q) {
            return {
                /* Only use as <select-picker> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/components/item-template/select-picker/select-picker.html',

                    /* Attributes to set */    
                    scope: {
                        'value': '=', /* Selected value binding is mandatory */
                        'parentValue': '='
                    },

                    link: function (scope, element, attrs) {
                        scope.selected = scope.value;
                        scope.labelText = attrs.labelText || false
                        if(attrs.options != "undefined"){
                            scope.items = JSON.parse(attrs.options);
                        }
                        scope.onLoad = scope.value ? true : false;

                        scope.$watch('selected', function(newVal){
                            scope.value = newVal!=""?newVal: undefined;
                        },true)
                        
                        if(attrs.itemParent != undefined && attrs.itemParent!='undefined' && attrs.disabled != "true"){
                            scope.disabled = true;
                            scope.fullItems = angular.copy(scope.items);
                            scope.$watch('parentValue', function(newVal, oldVal){
                                if(newVal != undefined && newVal != ""){
                                    if(newVal!=oldVal){
                                        scope.selected = "";
                                        scope.itemOptionParentValue = newVal;
                                        
                                        scope.items = [];
                                        angular.forEach(scope.fullItems, function(item){
                                            if(item.parentOptionId == scope.itemOptionParentValue){
                                                scope.items.push(item);
                                            }
                                        })
                                        scope.disabled = false;
                                    }else if(scope.onLoad){
                                        scope.itemOptionParentValue = newVal;
                                        scope.items = [];
                                        angular.forEach(scope.fullItems, function(item){
                                            if(item.parentOptionId == scope.itemOptionParentValue){
                                                scope.items.push(item);
                                            }
                                        })
                                        scope.disabled = false;
                                        scope.onLoad = false;
                                    }
                                }else{
                                    scope.text = scope.defaultText;
                                    scope.value = undefined
                                    scope.selected = "";
                                    scope.disabled = true;
                                }
                            }, true)
                        }else{
                            scope.disabled = false;
                        }

                        if(attrs.disabled == "true"){
                            scope.disabled = true;
                        }
                    }
                };
            }
        ]
    )
    }
)();