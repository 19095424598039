(function() {
  'use strict';

  angular
      .module('pickerApp')
      .controller('ModalFormRecordTrackCtrl', ModalFormRecordTrackCtrl);

  ModalFormRecordTrackCtrl.$inject = ['$scope', 'Principal', 'LoginService', 'Form','Action', 'FormRecord', '$timeout', '$filter','$uibModalInstance', '$q', 'formRecord', 'permissions', '$rootScope'];

  function ModalFormRecordTrackCtrl ($scope, Principal, LoginService, Form, Action, FormRecord,$timeout, $filter, $uibModalInstance, $q, formRecord, permissions, $rootScope) {
      var vm = this;
      vm.tracks = [];
      vm.formRecord = formRecord;
      vm.lastActionId;
      vm.permissions = permissions;

      var createTrack = function(formRecord){
        var isInTrack = $filter('filter')(vm.tracks, function(t) {return t.formRecord.id == formRecord.id}).length > 0;
        if (!isInTrack) {
          Form.get({id: formRecord.formId}, function(form){
            if(vm.lastActionId){
              Action.get({id: vm.lastActionId}, function(action){
                var items = []
                angular.forEach(action.detailActions, function(detailAction){
                  if(detailAction.trackeable){
                    var itemRecord = $filter('filter')(formRecord.fieldSetRecords[0].itemRecords, {itemId: detailAction.itemDestinationId})[0]
                    var item = $filter('filter')(form.fieldSets[0].items, {id: detailAction.itemDestinationId})[0];
                    var val;
                    switch(item.subtype ? item.subtype : item.type){
                    case 'select':
                      if(item.parentItemId){
                        var itemParent = $filter('filter')(form.fieldSets[0].items, {name: item.parentItemId}, true)[0];
                        var itemRecordParent = $filter('filter')(formRecord.fieldSetRecords[0].itemRecords, {itemId: itemParent.id},true)[0];
                        var options = $filter('filter')(item.values, {parentOptionId: itemRecordParent.value},true)
                        var selectedValue = $filter('filter')(options, function(option){return option.value == itemRecord.value})[0];
                        val = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)");
                      }else{
                        var selectedValue = $filter('filter')(item.values, function(option){return option.value == itemRecord.value})[0];
                        val = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)");
                      }
                      break;
                    case "date":
                      val = $filter('date')(itemRecord.value, 'dd/MM/yyyy');
                      break;
                    case "textarea":
                      if(itemRecord.extraItemRecord){
                        val = itemRecord.extraItemRecord.longValue;   
                      }else{
                        val = itemRecord.value;
                      }
                      break;
                    default:
                      val = itemRecord.value;
                      break;
                    }
                    items.push({label: item.label, value: val});
                  }
                })
                vm.tracks.push({formRecord: formRecord, form: form, items: items});
              })
            }else{
              vm.tracks.push({formRecord: formRecord, form: form, items: []});
            }
            if(formRecord.relatedFormRecordId){
              FormRecord.get({id: formRecord.relatedFormRecordId}, function(response){
                vm.lastActionId = formRecord.actionId;
                createTrack(response);
              })
            }  
          })
        }
      }

      vm.exit = function(){
        $uibModalInstance.dismiss();
      }

      vm.print = function(){
        window.print();
      }

      
      vm.forms = $rootScope.forms;
      vm.form = $filter('filter')(vm.forms, {id: formRecord.formId})[0]
      createTrack(formRecord);        
  }

})();