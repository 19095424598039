(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('ModalInstanceCtrl', ModalInstanceCtrl);

    ModalInstanceCtrl.$inject = ['$scope', 'Principal', 'LoginService', 'FormRecord', 'Form', 'ItemRecord', 'DocumentRecord', '$state', '$filter', '$uibModalInstance', 'formRecordId', 'form', 'permissions', 'ModalHelper']

    function ModalInstanceCtrl ($scope, Principal, LoginService, FormRecord, Form, ItemRecord, DocumentRecord, $state, $filter, $uibModalInstance, formRecordId, form, permissions, ModalHelper) {
        var vm = this;
        vm.formRecordId = formRecordId;
        vm.form = form;
        vm.permissions = permissions;
        vm.itemRecords = [];

        function populateFormRecord(){
          FormRecord.get({id: vm.formRecordId}, function(response){
            vm.formRecord = response;
            var itemRecords = [];
            angular.forEach(vm.formRecord.fieldSetRecords, function(fieldSetRecord){
              angular.forEach(fieldSetRecord.itemRecords, function(itemRecord){
                itemRecords.push(itemRecord);
              })
            })
            angular.forEach(vm.form.fieldSets, function(fieldSet){
              angular.forEach(fieldSet.items, function(itemForm){
                if(itemForm.visible && (itemForm.visible == "app" || itemForm.visible == "none")){
                  return
                }
                var itemRecordTemp = $filter('filter')(itemRecords, {itemId: itemForm.id})[0];
                if(angular.isDefined(itemRecordTemp)){
                  itemRecordTemp.name = itemForm.name;
                  itemRecordTemp.label = itemForm.label;
                  itemRecordTemp.type = itemForm.type;
                  switch(itemRecordTemp.type){
                    case 'map':
                      var splitValues = itemRecordTemp.value.split('|');
                      itemRecordTemp.markers = {
                        currentPosition: {
                          lat: parseFloat(splitValues[0]),
                          lng: parseFloat(splitValues[1]),
                          focus: false,
                          draggable: false
                        }
                      }
                      itemRecordTemp.center = {
                        lat: parseFloat(splitValues[0]),
                        lng: parseFloat(splitValues[1]),
                        zoom: 17
                      }
                      vm.itemRecords.push(itemRecordTemp);
                    break;
                    case 'image':
                    case 'drawing':
                    case 'file':
                      vm.itemRecords.push(itemRecordTemp);
                      ItemRecord.getDocumentRecords({id: itemRecordTemp.id}, function(response){
                        itemRecordTemp.documentRecords = [];
                        angular.forEach(response, function(documentRecord){
                          DocumentRecord.get({id: documentRecord.id}, function(response){
                            itemRecordTemp.documentRecords.push(response)
                          }, function(error){
                            console.log(error);
                          })
                        })
                      }, function(error){
                        console.log(error);
                      })
                    break;
                    case 'select':
                      if(itemForm.parentItemId){
                        var itemParent = $filter('filter')(vm.form.fieldSets[0].items, {name: itemForm.parentItemId}, true)[0];
                        var itemRecordParent = $filter('filter')(vm.formRecord.fieldSetRecords[0].itemRecords, {itemId: itemParent.id},true)[0];
                        var options = $filter('filter')(itemForm.values, {parentOptionId: itemRecordParent.value},true)
                        var selectedValue = $filter('filter')(itemForm.values, function(option){return option.value == itemRecordTemp.value})[0];
                        itemRecordTemp.labelValue = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)")
                      }else{
                        var selectedValue = $filter('filter')(itemForm.values, function(option){return option.value == itemRecordTemp.value})[0];
                        itemRecordTemp.labelValue = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)")
                      }
                      vm.itemRecords.push(itemRecordTemp);
                    break;
                    default:
                      vm.itemRecords.push(itemRecordTemp);
                      break;
                  }
                }
              })
            })
          })
        }

        vm.getDataURI = function(documentRecord){
          return "data:" + documentRecord.fileContentType + ";base64," + documentRecord.file;
        }

        populateFormRecord();

        vm.ok = function () {
          $uibModalInstance.close();
        };

        vm.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        vm.track = function(){
          var modalInstance = ModalHelper.open(
            'app/entities/form-record/modal-form-record-track.html',
            'ModalFormRecordTrackCtrl',{
              formRecord: function () {
                return vm.formRecord;
              },
              permissions: function(){
                return vm.permissions;
              }
            }
          );
          
          vm.activePrintClasses = true;
          modalInstance.result.then(function () {        
            vm.activePrintClasses = false;
          }, function () {
            
          });   
        };
        
        vm.print = function(){
          window.print();
        }

        vm.delete = function(){
          var modalInstance = ModalHelper.confirmation('¿Está seguro de eliminar la respuesta?');

          modalInstance.result.then(function (confirmation) {
            if(confirmation){
              FormRecord.delete({id: vm.formRecord.id}, {},function(response){
                $uibModalInstance.close('deleted');
              });
            }
          }, function () {
            
          });
        };
    }
})();