(function() {
    'use strict';

    angular
        .module('pickerApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('organization', {
            parent: 'entity',
            url: '/organization',
            data: {
                authorities: ['ROLE_ADMIN'],
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/organization/organization.html',
                    controller: 'OrganizationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        })

        $stateProvider
        .state('organizations', {
            parent: 'entity',
            url: '/organizations',
            data: {
                authorities: ['ROLE_SUPER_ADMIN'],
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/organization/organizations.html',
                    controller: 'OrganizationsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                organizations: ['$q', 'Organization', '$rootScope',function($q, Organization, $rootScope){
                    var deferred = $q.defer();
                    $rootScope.showLoading("Buscando Organizaciones...")
                    Organization.query({sort: "id,asc"},function(organizations){;
                        $rootScope.hideLoading();
                        deferred.resolve(organizations);
                    })
                    return deferred.promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    return $translate.refresh();
                }]
            }
        });
    }

})();
