(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('drawingPicker', ['$q', 'ModalHelper', 'FileHelper', function ($q, ModalHelper, FileHelper)  {
            return {
                restrict: 'E',
                templateUrl: 'app/components/item-template/drawing-picker/drawing-picker.html',
                scope: {
                    'files': '=',
                    'value': '='
                },

                    link: function (scope, element, attrs) {
                        scope.disabled = attrs.disabled;
                        scope.labelText = attrs.labelText;
                        scope.itemId = attrs.itemId;

                        scope.openModal = function openModal(){
                            var modalInstance = ModalHelper.open(
                                'app/components/item-template/drawing-picker/modal-drawing.html', // Cambia esta línea
                                'ModalDrawing',{
                                    draw: function() {
                                        if (
                                            scope.files[scope.itemId] &&
                                            scope.files[scope.itemId][0] && 
                                            scope.files[scope.itemId][0].ngFile && 
                                            scope.files[scope.itemId][0].ngFile.$ngfBlobUrl
                                        ) {
                                            return scope.files[scope.itemId][0].ngFile.$ngfBlobUrl;
                                        }
                                    }
                                }
                            );

                            modalInstance.result.then(function (result) {
                                var blob = dataURItoBlob(result);
                                var fileName = 'signature.jpg';
                                var file = new File([blob], fileName, { type: 'image/jpeg' });
                                scope.setFile(file, scope.itemId, 0);
                            });
                        }

                        scope.setFile = function(rawFile, itemId, index) {
                          try {
                            FileHelper.setFile(rawFile, itemId, index, null, scope.files, undefined, []).then(function(value) {
                                scope.value = value;
                            });
                          } catch (err) {
                            scope.deleteFile(itemId, index);
                          }
                        }

                        scope.deleteFile = function(itemId, index) {
                          scope.value = FileHelper.deleteFile(itemId, index, scope.files);
                        }

                        scope.zoomInOut = function(event){
                          if(angular.element(event.currentTarget).hasClass("img-large")){
                            angular.element(event.currentTarget).removeClass("img-large");
                          }else{
                            angular.element(event.currentTarget).addClass("img-large");
                          }
                        }
              
                        function dataURItoBlob(dataURI) {
                            var byteString = atob(dataURI.split(',')[1]);
                            var ab = new ArrayBuffer(byteString.length);
                            var ia = new Uint8Array(ab);
                            for (var i = 0; i < byteString.length; i++) {
                              ia[i] = byteString.charCodeAt(i);
                            }
                            return new Blob([ab], { type: 'image/jpeg' });
                          }
                    }
                };
            }
        ]
    )
    }
)();