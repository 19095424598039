(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('DocumentRecord', DocumentRecord);

    DocumentRecord.$inject = ['$resource'];

    function DocumentRecord ($resource) {
        var resourceUrl =  'api/document-records/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'save':  { method: 'POST', id:'@id', document_records: '@params'},
            'update': { method: 'PUT' }
        });
    }
})();