(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('positionPicker',
  [
    '$q','$timeout',
    function ($q, $timeout) {
      return {
        /* Only use as <select-picker> tag */
        restrict: 'E',

        /* Our template */
        templateUrl: 'app/components/item-template/position-picker/position-picker.html',
        controller: 'GoogleMapsPickerController',
        /* Attributes to set */
        scope: {
          'model': '=',
          'disabled': '=',
          'ignoreParent': '='
        },

        link: function (scope, element, attrs) {

          var setCurrentPosition = function (latitude, longitude) {
            scope.position = {lat: latitude,lng: longitude};
            scope.model = latitude+'|'+longitude;
          }

          var getInitialPosition = function(){
            var deferred = $q.defer();
            if(scope.model!=undefined){
              var split = scope.model.split('|');
              deferred.resolve({lat: parseFloat(split[0]), lng: parseFloat(split[1])});
            }else{
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position){
                  var lat = position.coords.latitude;
                  var lng = position.coords.longitude;
                  deferred.resolve({lat: lat, lng: lng});
                });
              }
            }
            return deferred.promise;
          }

          scope.$watch('position', function (newPoint) {
            if (angular.isDefined(newPoint)) {
              setCurrentPosition(newPoint.lat, newPoint.lng);
            }
          }, true)

          /* Inicializo la posición actual */

          getInitialPosition().then(function(position){
            setCurrentPosition(position.lat, position.lng);
          })
      
        }
      };
    }
  ]
);
})();