(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('TypeDetailAction', TypeDetailAction);

    TypeDetailAction.$inject = ['$resource', 'DateUtils'];

    function TypeDetailAction ($resource, DateUtils) {
        var resourceUrl =  'api/type-detail-actions/:id';


        return $resource(resourceUrl, {}, {
            'query': { url: 'api/type-detail-actions', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdAt = DateUtils.convertDateTimeFromServer(data.createdAt);
                        data.updatedAt = DateUtils.convertDateTimeFromServer(data.updatedAt);
                    }
                    return data;
                }
            },
            'save': { method: 'POST', id: '@id', form_records: '@params' },
            'update': { method:'PUT' },
            'delete': { method: 'DELETE', id: '@id'}
        });
    }
})();