(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('Dashboard', Dashboard);

    Dashboard.$inject = ['$resource', 'DateUtils'];

    function Dashboard ($resource, DateUtils) {
        var resourceUrl =  'api/dashboards/:id';

        return $resource(resourceUrl, {}, {
            'query': { url: 'api/dashboards', method: 'GET', isArray: true },
            'assigned': { url: 'api/dashboards/assigned', method: 'GET', isArray: true },
            'get': { method: 'GET' },
            'save': { method: 'POST', id: '@id', dashboards: '@params' },
            'update': { method:'PUT' },
            'delete': { method: 'DELETE', id: '@id'}
        });
    }
})();