(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('imagePicker',
        ['$q', 'Upload', 'FileHelper', function ($q, Upload, FileHelper) {
            return {
                /* Only use as <select-picker> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/components/item-template/image-picker/image-picker.html',

                    /* Attributes to set */    
                    scope: {
                        'value': '=', /* Selected value binding is mandatory */
                        'files': '='
                    },

                    link: function (scope, element, attrs) {
                        scope.labelText = attrs.labelText;
                        scope.disabled = attrs.disabled;
                        scope.itemId = attrs.itemId;
                        scope.amountOfImages = Array.from(Array(parseInt(attrs.imageQuantity)).keys());

                        scope.setFile = function(rawFile, itemId, index, invalidFiles) {
                          try {
                            FileHelper.setFile(rawFile, itemId, index, undefined, scope.files, 'image/*', invalidFiles).then(function(value) {
                                scope.value = value;
                            });
                          } catch (err) {
                            scope.deleteFile(itemId, index);
                          }
                        }

                        scope.deleteFile = function(itemId, index) {
                          scope.value = FileHelper.deleteFile(itemId, index, scope.files);
                        }
              
                        scope.zoomInOut = function(event){
                          if(angular.element(event.currentTarget).hasClass("img-large")){
                            angular.element(event.currentTarget).removeClass("img-large");
                          }else{
                            angular.element(event.currentTarget).addClass("img-large");
                          }
                        }

                        scope.openFileSelector = function(id) {
                          FileHelper.openFileSelector(id);
                        }
                    }
                };
            }
        ]
    )
    }
)();