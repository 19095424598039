(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('itemForm',
        ['$q', '$filter', 'PropertyUtil', 'ModalHelper',function ($q, $filter, PropertyUtil, ModalHelper) {
            return {
                /* Only use as <item-form> tag */
                restrict: 'E',

                /* Our template */
                templateUrl: 'app/entities/item/item-form.html',

                    /* Attributes to set */    
                    scope: {
                        "item": "=",
                        "itemTypes": "=",
                        "properties": "=",
                        "index": "=",
                        "deleteItem": "=",
                        "duplicateItem": "=",
                        "form": "="
                    },

                    link: function (scope, element, attrs) {
                        setTimeout(function(){
                            element.find("select").select2({width: "100%"});
                        },500);

                        scope.availableProperties;
                        scope.$watch("item.type", function(newVal){
                            if(newVal != undefined && scope.properties){
                                scope.availableProperties = PropertyUtil.getOptionsAvailableByType(newVal, scope.properties);
                            }
                        })
                        scope.selectedProperties = [];
                        
                        scope.error = {};

                        scope.item.isValid = function(){
                            var isValid = true;
                            if(!scope.item.label || scope.item.label == undefined || scope.item.label == null || scope.item.label == "" ){
                                scope.error.label = "La etiqueta no puede estar vacío";
                                isValid = false;
                            }else{
                                delete scope.error.label;
                            }
                            if(!scope.item.type || scope.item.type == undefined || scope.item.type == null || scope.item.type == "" ){
                                scope.error.type = "Debe seleccionar un tipo";
                                isValid = false;
                            }else{
                                delete scope.error.type;
                                switch(scope.item.type){
                                    case "image":  
                                        if(!scope.item.value || scope.item.value == undefined || scope.item.value == null || scope.item.value == "" ){
                                            scope.error.value = "Debe ingresar un cantidad de imagenes";
                                            isValid = false;
                                        }else if(scope.item.value < 1 || !Number.isInteger(scope.item.value)){
                                            scope.error.value = "El valor debe ser un entero mayor que 0 y menor o igual que 10";
                                            isValid = false;
                                        }else{
                                            delete scope.error.value;
                                        }  
                                        break;        
                                    case "select":
                                        if(!scope.item.values || scope.item.values == undefined || scope.item.values == null || scope.item.values.length == 0){
                                            if(scope.item.externalURL && scope.item.externalURL != ""){
                                                delete scope.error.values;
                                            }else{
                                                scope.error.values = "Debe revisar la configuración de opciones";
                                                isValid = false;
                                            }                                    
                                        }else{
                                            delete scope.error.values;
                                        }
                                        break;
                                }
                            }
                            scope.item.error = {};

                            angular.forEach(scope.selectedProperties, function(key){
                                if(scope.item[key] === undefined || scope.item[key] === null || scope.item[key] === ""){
                                    scope.item.error[key] = "El valor de la propiedad no puede estar vacío";
                                    isValid = false;
                                }
                            })
                            if(isValid){
                                delete scope.item.error;
                            }
                            return isValid;
                        }

                        angular.forEach(Object.keys(scope.item), function(key){
                            if(isKeyAProperty(key)){
                                scope.selectedProperties.push(key);
                            }
                        })
                        
                        scope.addProperty = function(){
                            scope.selectedProperties.push(undefined);
                        }

                        scope.deleteProperty = function(index){
                            delete scope.item[scope.selectedProperties[index]];
                            scope.selectedProperties.splice(index, 1);
                        }

                        scope.changeSelectedProperty = function(property, index){
                            if(scope.selectedProperties[index] != undefined){
                                delete scope.item[scope.selectedProperties[index]];
                            }
                            scope.selectedProperties[index] = property;
                        }

                        scope.checkTypeAndPrepareForm = function(){
                            if(scope.item.type == "select"){
                                setTimeout(function(){
                                    element.find("select").select2({width: "100%"});
                                },100);
                            }
                        }

                        scope.openOptionsEditor = function(){
                            var modalInstance = ModalHelper.open('app/entities/item-option/item-option-editor.html',
                                'ModalItemOptionEditorCtrl', {
                                  item: function(){
                                    return scope.item;
                                  },
                                  itemsSelect: function(){
                                      return $filter("filter")(scope.form.fieldSets[0].items, {type: "select"});
                                  }
                                }
                            );

                            modalInstance.result.then(function (item) {
                                if(item){
                                  angular.forEach(Object.keys(item), function(key){
                                       scope.item[key] = item[key];  
                                  })
                                  angular.forEach(Object.keys(scope.item), function(key){
                                       if(!item[key]){
                                           delete scope.item[key];
                                       }  
                                  })
                                }
                            });  
                        }

                        function isKeyAProperty(key){
                            switch(key){
                                case "id":
                                case "label":
                                case "name":
                                case "className":
                                case "description":
                                case "required":
                                case "type":
                                case "values":
                                case "parentItemId":
                                case "value":
                                case "$$hashKey":
                                case "externalURL":
                                case "isValid":
                                return false;
                                default:
                                return true;
                            }
                        }
                    }
                };
            }
        ]
    )
    }
)();