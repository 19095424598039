(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('Action', Action);

    Action.$inject = ['$resource', 'DateUtils'];

    function Action ($resource, DateUtils) {
        var resourceUrl =  'api/actions/:id';

        function transformAction(action){
            action = DateUtils.recurseObject(action);
            action.executionType = action.executionType ? action.executionType.split(",") : [];
            action.trigger = action.trigger ? action.trigger.split(",") : [];
            action.type = action.type ? action.type.split(",") : [];
            return action;
        }

        return $resource(resourceUrl, {}, {
            'query': { url: 'api/actions', method: 'GET', isArray: true, transformResponse: function(data){
                data = angular.fromJson(data);
                for(var i = 0; i< data.length; i++){
                    data[i] = transformAction(data[i]);
                }
                return data;
            }},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data = transformAction(data);
                    }
                    return data;
                }
            },
            'save': { method: 'POST', id: '@id', actions: '@params' },
            'executeManual': {url: 'api/execute-manual-action', method: 'POST'},
            'update': { method:'PUT' },
            'delete': { method: 'DELETE', id: '@id'},
            'setEnabled': { method: 'PUT', url: resourceUrl + "/set-enabled" }
        });
    }
})();




(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('ActionHelper', ActionHelper);

    ActionHelper.$inject = ['$q', '$filter', 'Action'];

    function ActionHelper ($q, $filter, Action) {
        return {
            getActionsOfForm: function(formOriginId) {
                var deferred = $q.defer();
                Action.query({formOriginId: formOriginId}, function(response){
                    deferred.resolve(response);
                })
                return deferred.promise;
            },
            getActionsOfFormWithNullAction: function(formOriginId) {
                var deferred = $q.defer();
                Action.query({formOriginId: formOriginId}, function(response){
                    response.push({name: "Sin acción", id: null});
                    deferred.resolve(response);
                })
                return deferred.promise;
            },
            filterActionsManualByUser: function(actions, userId) {
                var deferred = $q.defer();
                deferred.resolve($filter('filter')(actions, function(action) {
                    var user = $filter('filter')(action.actionPermitExecutions, function(actionUser) {
                        return actionUser.user.id == userId;
                    });
                    var executionType = $filter('filter')(action.executionType, function(executionType) {
                        return executionType == 'manual';
                    });
                    return executionType.length > 0 && user.length > 0;
                }));
                return deferred.promise;
            }
        }
    }
})();
