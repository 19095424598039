(function() {
    'use strict';
    angular
        .module('pickerApp')
        .factory('Property', Property);

    Property.$inject = ['$resource'];

    function Property ($resource) {
        var resourceUrl =  'api/properties/:id';

        return $resource(resourceUrl, {}, {
            'query': { 
                method: 'GET', 
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    angular.forEach(data, function(property){
                        switch(property.name){
                            case "subtype":
                                property.translatedName = "Sutitpo";
                                break;
                            case "placeholder":
                                property.translatedName = "Texto de muestra";
                                break;
                            case "maxlength":
                                property.translatedName = "Largo máximo";
                                break;
                            case "style":
                                property.translatedName = "Hoja de estilo";
                                break;
                            case "externalURL":
                                property.translatedName = "Opciones externas";
                                break;
                            case "readOnly":
                                property.translatedName = "Solo lectura";
                                break;
                            case "max":
                                property.translatedName = "Valor máximo (solo si subtipo es Número o Archivo)";
                                break;
                            case "min":
                                property.translatedName = "Valor mínimo (solo si subtipo es Número o Archivo)";
                                break;
                            case "step":
                                property.translatedName = "Valor de paso (solo si subtipo es Número o Archivo)";
                                break;
                            case "valueFromLastFormRecord":
                                property.translatedName = "Valor desde la última respuesta";
                                break;
                            case "saveAndContinue":
                                property.translatedName = "Guardar y continuar";
                                break;
                            case "listTitle":
                                property.translatedName = "Titulo en listado de aplicación";
                                break;
                            case "visible":
                                property.translatedName = "Visible";
                                break;
                            case "editable":
                                property.translatedName = "Editable";
                                break;
                            case "disabled":
                                property.translatedName = "Deshabilitado";
                                break;
                            case "marginTop":
                                property.translatedName = "Margen Superior";
                                break;
                            case "marginBottom":
                                property.translatedName = "Margen Inferior";
                                break;
                            case "fileType":
                                property.translatedName = "Tipo de archivo";
                                break;
                        }
                    })
                    return data;
                }
            },
            'get': {method: 'GET'}
        });
    }
})();