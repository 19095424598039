(function() {
    'use strict';

    angular
        .module('pickerApp')
        .directive('tabCharts',
        [
            '$rootScope',
            '$q',
            '$filter',
            '$timeout',
            '$stateParams',
            'ModalHelper',
            'Chart',
            function (
                $rootScope,
                $q,
                $filter,
                $timeout,
                $stateParams,
                ModalHelper,
                Chart
            ) {
                return {
                    /* Only use as <tap-charts> tag */
                    restrict: 'E',

                    /* Our template */
                    templateUrl: 'app/entities/form-record-searcher/tab-charts/tab-charts.html',

                    /* Attributes to set */    
                    scope: {
                        'permissions': '=',
                        'currentTab': '='
                    },
                    link: function (scope, element, attrs) {
                        var vm = {};
                        scope.vm = vm;

                        scope.$watch('currentTab', function(newVal, oldVal){
                            if(newVal == 'charts') {
                                $rootScope.showLoading('Cargando gráficos...');
                                Chart.query({formId: $stateParams.formId}, function(response){
                                    vm.charts = response;
                                    $rootScope.hideLoading();
                                }, function(error){
                                    $rootScope.hideLoading();
                                })
                            }
                        }, true)

                        vm.addChart = function(){
                            var modalInstance = ModalHelper.open('app/entities/chart/modal-chart-form.html',
                                'ModalChartFormCtrl', {
                                  chart: function(){
                                    return null;
                                  }
                                }
                            );

                            modalInstance.result.then(function (chart) {
                                if(chart){           
                                    vm.charts.push(chart);   
                                }
                            });
                        }

                        vm.deleteChart = function(index){
                            var modalInstance = ModalHelper.confirmation('¿Está seguro de eliminar el gráfico ' + vm.charts[index].name + '?');
                            modalInstance.result.then(function(confirmation){
                                if(confirmation){
                                    $rootScope.showLoading("Eliminando gráfico...");
                                    Chart.delete({id: vm.charts[index].id }, {}, function(response){
                                        vm.charts.splice(index, 1);
                                        $rootScope.hideLoading();
                                    }, function(error){
                                        $rootScope.hideLoading();
                                    })
                                }
                            })
                        }

                        vm.editChart = function(index){
                            var modalInstance = ModalHelper.open('app/entities/chart/modal-chart-form.html',
                                'ModalChartFormCtrl', {
                                  chart: function(){
                                    return vm.charts[index];
                                  }
                                }
                            );

                            modalInstance.result.then(function (chart) {
                                if(chart){                       
                                    vm.charts[index] = chart;
                                }
                            });
                        }

                        vm.hideChart = function(index){
                            vm.charts[index].hidden = true;
                        }

                        vm.showHiddenCharts = function(){
                            angular.forEach(vm.charts, function(chart){
                                chart.hidden = false;
                            })
                        }

                        vm.duplicateChart = function(index, chart){
                            $rootScope.showLoading('Duplicando gráfico...');
                            var newChart = angular.copy(chart);
                            delete newChart.id;
                            delete newChart.formFrontConf.id;
                            delete newChart.chartSnapshot;
                            Chart.save({}, newChart, function(response){
                              newChart.id = response.id;
                              newChart.formFrontConf.id = response.formFrontConf.id;
                              newChart.chartSnapshot = response.chartSnapshot;
                              vm.charts.splice(index+1, 0, newChart);
                              $rootScope.hideLoading();
                            }, function(error) {
                                $rootScope.hideLoading();
                            })
                        }

                        vm.onMove = function(item, part, index, helper) {
                            $('.sv-placeholder').height(helper.element.outerHeight()-16);
                        }
                    }
                };
            }
        ]
    )
    }
)();